.accountability-list-table {
  margin: 0;
  color: rgba(0, 44, 95, 1);

  .workspace-scroll-cover {
    top: 1px;
    width: 92%;
    right: 27px;
    position:absolute;
    height: 36px;
    z-index:1;

  }

  .workspace-list-head {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 44, 95, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin-bottom: 8px;
    font-size: 10px;
    padding-left: 40px;
    position: sticky;
    top:0px;
    z-index:2;

    .workspace-list-head-item {
      font-size:10px;
      cursor: default;
    }

  }

  .workspace-list-item {
      height: 40px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 44, 95, 0.25);
      border-radius: 5px;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      margin-bottom: 8px;
      font-size: 10px;

    &:hover {
        background: #F5F5F5;
        border-color: rgba(0,44,95,0.6);
        cursor: pointer !important;

        .static-gif {
            background:#f1f2f3 !important;
        }
    }

    .workspace-list-item-col  {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .workspace-list-item-priority {
        width: 16px;
        border: 1px solid rgba(0, 44, 95, 0.25);
        height: 40px;
        border-radius: 5px 0px 0px 5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .workspace-list-item-priority-p1 {
        background: #f87a53;
    }
    .workspace-list-item-priority-p2 {
        background: #ffee59;
    }
    .workspace-list-item-priority-p3 {
        background: #05e700;
    }

    .workspace-list-item-priority-lead {
        background: #f8a67b;
    }
    .workspace-list-item-priority-inProcess {
        background: #98c1d9;
    }
    .newList-edit-btn:hover {
      span{
          svg {
            path {
              stroke: #5f81ff !important;
            }
          }
        }
      }

    .newList-add-btn:hover {
      span{
        svg {
          path {
            fill: #5f81ff !important;
          }
        }
      }
    }

    .workspace-list-item-col {

      .workspace-list-item-pill {
          border: 0.5px solid #E74C7D;
          border-radius: 9px;
          height:18px;
          color: #E64C7D;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;
          background: #fff;
          font-size: 10px;

      }
      .workspace-list-item-businessImpact-pill {
          border: 0.5px solid #728BCE;
          border-radius: 9px;
          height:18px;
          color: #fff;
          background: #728BCE;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;

      }
      .workspace-list-item-processImpact-pill {
          border: 0.5px solid #C9F57E;
          border-radius: 9px;
          height:18px;
          color: var(--theme-default-colour)80;
          background: #C9F57E;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;

      }
      .workspace-list-item-notSelected-pill {
          border: 0.5px solid var(--theme-default-colour);
          border-radius: 9px;
          height:18px;
          color: var(--theme-default-colour);
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;
      }
      .workspace-list-item-pill2 {
          border: 0.5px solid var(--theme-default-colour);
          border-radius: 5px;
          width: 21px;
          height: 21px;
          border-radius:50%;
          color: var(--theme-default-colour);
          padding: 0 6px;
          display: flex;
          justify-content: center;
          align-items:center;

      }
    }
  }

  .accountabilityListBody-container {
    font-size: 13px;

    .expand-task{
       left:180px;
       width: 20px;
       height: 20px;
       background-color: #e8eaed;
       display:none;
       align-items: center;
       justify-content: center;
       opacity: 0.5;

       &:hover {
       opacity: 1;
       }
    }
    //Modification: Added class for delegating task
    //By: Devang
    //Date: 14/02/2023
    .delegate-task {
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      //background-color: #e8eaed;
      display:none;
      align-items: center;
      justify-content: center;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
    .always-open-delegate-task.delegate-task {
      opacity: 1;
      display: flex;
    }
    .listcard-td:first-child,td:first-child,td:nth-child(2) {
      text-align: left;
      position: sticky !important;
      left:0;
    }
    td:first-child{
      padding-left: 0 !important;
    }
    td:nth-child(2){
      left:36px;
    }

    .listcard-tr,tr {
        /*
          Modification: Added important on background color on hover due to sticky new add-column
          By: Devang
          Date: 04/04/2023
        */
        &:hover {
          background-color: #f7f8f9 !important;
        td {
          background-color: #f7f8f9 !important;
        }
        .expand-task {
          display:flex;
        }
        //Modification: Added class for delegating task
        //By: Devang
        //Date: 14/02/2023
        .delegate-task {
          display:flex;
        }
        .select-task {
          display:block;
         }
        .select-task-no {
          display:none;
        }
      }

    .listcard-td {
        height: 38px !important;
      }
    .listcard-td,td {
         font-weight: 400 !important;
         color: #3b3b3b;
         text-align: left;
         padding:0 !important;
         background-color: transparent;
         flex-shrink: 0 !important;
         border: none;
         //overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;

         .unSelectable {
           -webkit-user-select: none; /* Safari */
           -moz-user-select: none; /* Firefox */
           -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
            padding: 8px;
         }
         span {
          //overflow: hidden;
           text-overflow: ellipsis;
           cursor: pointer;
           white-space: nowrap;

         }
     }

     td {
       border-left: 1px solid #e1e1e1;
       border-bottom: 1px solid #e1e1e1;
       //display:flex;
       align-items: center;
       padding-left: 8px !important;
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
       cursor : pointer;
       span {
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         cursor: pointer;
       }
       .select-task {
          position: absolute;
          top: -9px;
          bottom: 0px;
          left: 2px;
          right: 0;
          display:none;
       }
       .select-task-no-active{
       display: none;
       }
       .select-task-active{
       position: absolute;
       top: -9px;
       bottom: 0px;
       left: 2px;
       right: 0;
       display:block;

       }
     }

     td:last-child {
       border-right: 1px solid #e1e1e1 !important;
     }

     [type="checkbox"] + label::before{
       top: 3px;
       left: 8px;
       width: 13px;
       height: 13px;

     }

     [type="checkbox"]:checked + label::before {
       width: 9px;
       height: 16px;
       left:4px;
       top:-1px;
     }

    }

    .stickyTableCell {
       position: sticky !important;
       left: 0;
       background-color: #fff !important;
       z-index: 2;
    }

    .stickyTableIcons {
      opacity:0.2;
    }

    span,div {
       font-weight: 400 !important;
       //color: #3b3b3b;
       //line-height: 1.2 !important;
    }
    .listcard1-case {
      font-weight: 500 !important;
    }
    .listcard1-case-table {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #3b3b3b !important;
      text-transform:capitalize;
      font-size: 13px !important;
    }

  }
  .accountabilityList-thead {
    border: none;
    //border-bottom: 2px solid #e1e1e1;
    background: #fff;
    z-index: 1;
    padding-top:0px;
    font-size: 12px;
    position: sticky;
    top:0;
    background-color: white;
    border: 1px solid rgba(0,44,95,0.25);
    z-index:6;

    .select-task-all {
       position: absolute;
       top: -9px;
       bottom: 0px;
       left: -30px;
       right: 0;
       display:none;

    }
    .select-task-all-active {
       position: absolute;
       top: -9px;
       bottom: 0px;
       left: -30px;
       right: 0;
       display:block;
    }
    .select-all-active{
    display:none;
    }

    .listcard-th:first-child,th:first-child, th:nth-child(2) {
      position: sticky;
      left:0;
      height: 30px;
      }
      th:nth-child(2){
        //left:36px;
      }
      th:first-child:hover{
        .select-task-all {
          display:block;
        }
        .select-all {
        display:none;
        }
      }


    .col-head-active {
      background-color: #e8eaed !important;
      border-radius: 3px;

    .hover-icon-1 {
      /*
        Bug: T243 | BG_UI - Sort icon overlapped on column name
        Modification: Handled the positioning view of sort icon | Updated Left, right and bottom
        By: Devang
        Date: 24/05/2024
      */
      //left:-18px;
      right:-18px;
      //bottom:0;
      bottom:3px;
      opacity:1 !important;
      }

    .hover-icon-2{
      right:-18px;
      bottom:0;
      opacity:1 !important;
      }
    }

    .col-head-active-table {
      background-color: #e8eaed !important;
      border-radius: 3px;

    .hover-icon-1 {
      /*
        Bug: T243 | BG_UI - Sort icon overlapped on column name
        Modification: Handled the positioning view of sort icon | Updated Left, right and bottom
        By: Devang
        Date: 24/05/2024
      */
      //left:124px;
      right:-18px;
      //bottom:0;
      bottom:3px;
      opacity:1 !important;
      }

    .hover-icon-2{
      left:142px;
      bottom:0;
      opacity:1 !important;
      }
    }

    .stickyTableHead {
     position: sticky !important;
     left: 0;
     z-index: 7;
     background-color: #fff !important;
    }

    //Modification: Added height for table head
    //By: Manohar
    //Date: 30/08/2023

    .listcard-th:hover {
      background-color: #e8eaed;
      border-radius: 3px;
      //height: 30px;
      gap:1px;


      .hover-icon-1 {
      /*
        Bug: T243 | BG_UI - Sort icon overlapped on column name
        Modification: Handled the positioning view of sort icon | Updated Left, right and bottom
        By: Devang
        Date: 24/05/2024
      */
        //left:-18px;
        right:-18px;
        //bottom:0;
        bottom:3px;
        opacity:1 !important;
      }

      .hover-icon-2{
        right:-18px;
        bottom:0;
        opacity:1 !important;
        }
      }

      th:hover {
        background-color: #e8eaed;
        border-radius: 3px;
        gap:1px;


        .hover-icon-1 {
          /*
            Bug: T243 | BG_UI - Sort icon overlapped on column name
            Modification: Handled the positioning view of sort icon | Updated Left, right and bottom
            By: Devang
            Date: 24/05/2024
          */
          //left:124px;
          right:124px;
          //bottom:0;
          bottom:3px;
          opacity:1 !important;
          }

        .hover-icon-2{
          left:142px;
          bottom:0;
          opacity:1 !important;
          }
        }

    .listcard-th,span,div,th {
      font-weight:500 !important;
      //color: #a0a7b3 !important;
      color: #646464 !important;
      padding-top: 1px;
      padding-bottom: 2px;
      transition: 0.2s background ease;
      background-color: transparent;
      font-size: 13px;
      border: none;
      text-align : center;
      cursor : pointer;
      flex-shrink: 0;
      line-height:1;
      text-transform: uppercase;
    }
      th {
        border : 1px solid #e1e1e1;
        border-right : 0;
        text-align:left;

        [type="checkbox"] + label::before{
          top: 3px;
          left: 8px;
          width: 13px;
          height: 13px;

        }

        [type="checkbox"]:checked + label::before {
          width: 9px;
          height: 16px;
          left:4px;
          }
          top:-1px;

      }
      th:last-child {
       border-right : 1px solid #e1e1e1;
      }
      listcard-th {
    }

      //Modification: Added style to give styl to checkboxes in header-columns also
      //By: Devang
      //Date: 17/02/2023
      .listcard-tr {
        [type="checkbox"] + label::before{
          top: 3px;
          left: 8px;
          width: 13px;
          height: 13px;
        }

        [type="checkbox"]:checked + label::before {
          width: 9px;
          height: 16px;
          left:4px;
          top:-1px;
        }
      }
  }
}

.confirm-filter-btn {
  width: fit-content;
  min-width: fit-content;
  margin: 0 4px;
  background: #CBE1E4;
  border-radius: 2px;
  padding: 4px 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
}
