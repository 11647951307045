.shadow, .shadow-0 {
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.rounded-15 {
    border-radius: 1.5rem;
}
.rounded-20 {
    border-radius: 2rem;
}
.muiltr-1kiq63u {
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    border-radius: 16px;
    overflow: hidden;
}
.p-20em {
    padding: 2rem;
}
.pb-0 {
    padding-bottom: 0;
}
.muiltr-ehddlr {
    margin: 0px;
    font-size: 1.3rem;
    font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
}
.font-500, .font-medium {
    font-weight: 500;
}
.items-center {
    align-items: center;
}
.flex-row {
    flex-direction: row;
}
.flex {
    display: flex;
}
.mt-12em {
    margin-top: 1.2rem;
}
.flex-wrap {
    flex-wrap: wrap;
}
.tracking-tighter {
    letter-spacing: -.05em;
}
.leading-none {
    line-height: 1;
}
.font-semibold {
    font-weight:600;
}
.text-48 {
    font-size: 4.8rem;
}
.mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
}
.flex-col {
    flex-direction: column;
}
.text-20 {
    font-size: 2rem;
}
.muiltr-1cpc5a8 {
    user-select: none;
    width: 1em;
    height: 1em;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    flex-shrink: 0;
    font-size: 2.22857rem;
}
.muiltr-1nr4w8u {
    margin: 0px;
    font-size: 1.3rem;
    font-family: "Inter var", Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(107, 114, 128);
}
.mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}
.truncate, .whitespace-nowrap {
    white-space: nowrap;
}
.text-green {
    color: green;
}
.text-black {
    color: #000;
}
.text-red{
    color:red;
}
.px-06 {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
.rounded-201 {
    border: 1px solid #dcd9d9;
    border-radius: 1rem;
}
.bookmarkRibbon {
    width: 72px;
    height: 0;
    border-bottom: 6px solid #5da24b;
    border-top: 6px solid #5da24b;
    border-right: 14px solid transparent;
    font-size: 9px;
    position: relative;
    }
