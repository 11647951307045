.simple-table {
  table {
    border: 0
    border-collapse: collapse;
  }
  th, td {
    background: #fbfbfb;
    font-size: 13px !important;
    font-weight: 400 !important;
    border-color: #efeef0;
    padding: 0px 0px 0px 10px;
    vertical-align: middle;
    height: 37px;
    span.simple-table-data {
      //margin: 9px 0 !important;
    }
    width: 100px !important;
    //border: 1px solid #e9ebf0;

    //border-right: 1px solid #e9ebf0;
    //border-top: 1px solid #e9ebf0;
    border-top: transparent;
    border-bottom: transparent;
		//border: 1px solid #e9ebf0 !important;
  }
  td:nth-child(even) {
    border-right: 1px solid #e9ebf0;
    //border-right: 1px solid #bfbfbf;
    border-left: transparent;
    border-bottom: 1px solid #efeef0;
  }
  td:nth-child(odd) {
    border-left: 1px solid #e9ebf0;
    border-right: 1px solid #e9ebf0;
    //border-right: 1px solid #bfbfbf;
    border-bottom: 1px solid #efeef0;
    //border-bottom: 1px solid #bfbfbf;
  }
  tr:first-child {
    //border-top: 1px solid #efeef0;
    border-top: 1px solid #bfbfbf;
  }
  //th {
  //  color: #292d34;
  //}
  tr {
    td {
      color: #292d34;
      background: inherit;
      &:not(:first-child) {
        color: #2a2e34;
    	}
    }
  }
  td .td-img {
    width: 14px;
    height: 14px;
    line-height: 16px;
    text-align: center;
    margin-right: 6px;
    flex-shrink: 0;
    margin-top: 2px;
  }

}
.simple-table:after {
  content: attr(data-label);
  position: absolute;
  //top: -7.5px;
  top: 0px;
  left: 24px;
  background: #fff;
  color: #696d76;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}
/*
  Modification: Add class for information table
  By: Komal Wable
  Date: 01/03/2023
*/
.case-information-table {
  background: #fff !important;
  //background: #e5dcd3 !important;
  .tr:hover{
    background: #fff !important;
    //background: #e5dcd3 !important;
  }
}
