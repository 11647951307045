.header-tab {
  height:50px !important;
  background: #fbfbfb00
  //width: 65px;
  //text-align: center;
  position: relative;
  //padding-top: 10px;

  .header-tab-container {
    width: 120px;
    padding: 10px 7px 7px 7px;
    border-bottom: 1px solid rgba(0,0,0,0.051);
  }
  .companyName {
    padding: 11px 5px 5px 10px;
    font-size: 1.2rem;
    color: #fff;
  }
}
.task-floating-button, .view-deal-button, .view-timeline-button {
	position: absolute;
	box-shadow: 0px 2px 5px #666;
	cursor: pointer;
	width: max-content;
	height: 32px;
	padding: 6px;
	background: #408b8b;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
}
.view-deal-button {
  left: 1000px;
  top: 10px;
	bottom: 20px;
	//right: 119px;
	z-index: 3;
}
.view-timeline-button {
  left: 900px;
  top: 10px;
	bottom: 20px;
	//right: 205px;
	z-index: 4;
}
.arrow-icon{
  margin-left: 194px;
  margin-top: 8px;
}

@media screen and (max-width: 425px) {

   .mobile-header1{
    display:flex;
    align-items:center;
    padding: 1rem 0 0 1rem !important;
    font-size: 1rem !important;
    font-weight:500;
    letter-spacing:1px;
    }
    .mobile-headerTitle{
    letter-spacing: 1.5px;
    font-weight: 200 !important;
    }

}
