.newWorkSpaceCard {
  //background: var(--card-primary-color);
  border-radius: 4px;
  min-height: 40px;
  height: 33px;
  border: 1px solid #ccc;
  //color: #000;
  font-weight: 500;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  .newWorkSpaceCard-count {
    //color: var(--card-primary-count);
    color: #808080;
    font-size: 16px;
  }
  .newWorkSpaceCard-header {
    //color: var(--card-primary-header);
    // Modification: Replaced color with theme color and commented the previous color
    // BY: Devang
    // Date: 22/02/2023
    //color: #fff;
    color: var(--theme-default-colour);
    font-size: 12px;
    margin-top: 4px
  }
  .div-back {
    transform: rotateY(180deg);
  }
  .div-front, .div-back {
    //position: absolute;
    //width: 100%;
    //height: 100%;
    backface-visibility: hidden;
  }
}
.newWorkSpaceCard-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  svg {
    height: 24px;
    width: 24px;
  }
}

.newWorkSpaceCard:after {
  content: attr(data-label);
  position: absolute;
  top: -7.5px;
  left: 5px;
  // Modification: Replaced background and text color with theme color and commented the previous colors
  // BY: Devang
  // Date: 22/02/2023
  //background: var(--theme-default-colour);
  //color: #fff;
  background: #eee;
  color: var(--theme-default-colour);
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}
.subfilterCard {
  //box-shadow: rgb(0 0 0 / 5%) 0.5px 0.5px 0.5px 0.5px;
  border-radius: 4px;
  background: var(--card-secondary-color);
  .subfilterCard-count {
    color: var(--card-secondary-count);
  }
  .subfilterCard-header {
    color: var(--card-secondary-header);
  }
}
.subfilterCardActive {
  box-shadow: 2px 2px 5px 0px #baabba;
}
.Overviewworkshop {
  height: calc(100vh - 130px);
  position: relative;
  padding-bottom: 40px;
  padding-left: 2px;
}
.semi-detail-summary {
  padding-top: 13px;
}
.semi-detail-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
}



@media (min-width:320px) and (max-width:425px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none !important;
  }
  .is-hide-mobile {
    display:none
  }
  .panelActive-width {
    width: 100% !important;
  }
  .full-width {
    width: 100% !important
  }
  .modal {
    .modal-content {
      &.is-medium {
        width: 50% !important
      }
    }
  }

  .headerWorkspaceHeadText {
    font-size:14px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

@media (min-width:426px) and (max-width:768px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none !important;
  }
  .is-hide-mobile {
    display:none
  }
  .panelActive-width {
    width: 100% !important;
  }
  .full-width {
    width: 100% !important
  }
  .modal {
    .modal-content {
      &.is-medium {
        width: 50% !important
      }
    }
  }
  .headerWorkspaceHeadText {
    font-size:16px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

/*
      modified by Vipin
      modified on 15/12/2022
      modification : Added Outer1, custom1, search, search-transition,globalModal-transtion Classes
      to the dayPlanWorkspace and ListCard1 component fors new task view.
*/

.outer1{
  display: flex;
  position: relative;
  margin-top: 0px !important;
  flex-direction: column;
  padding: 10px;
  padding-top: 0px;
  height: auto;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.outer1{
   .excel-list-table table {
    border-collapse:separate !important;
    border-spacing: 0 !important;
    table-layout:fixed;
    width: 100px;
    border: none;
   }
}


.search-transition-on {
  transform: scaleY(1);
  transform-origin: top;
  transition: 0.2s transform ease-out !important;
  background-color: #fff !important;
  padding: 0 !important;

  div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    //height: 25vh;
    overflow: auto;
  }

  li:hover {
    background-color: #e7e9ec;
  }
  li.selected {
    background-color: #e7e9ec;
  }
}

.search-transition-off {
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.1s transform ease-in !important;
  background-color: #fff !important;
  padding-top: 0 !important;

  div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    //height: 25vh;
    overflow: auto;
  }

  li:hover {
    background-color: #e7e9ec;
  }
}

.globalmodal-transition-on {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position:absolute;
  top:-6%;
  left:-8px;
  transform: scaleY(1);
  transform-origin:top;
  transition: 0.2s transform ease-in;
}

.globalmodal-transition-off {
  height: 100%;
  opacity:0;
  width: 100%;
  z-index:1000;
  position: absolute;
  top:-6%;
  transform: scaleY(0);
}

.search-content {
  min-width: 20rem;
  position: relative;
  margin-right:auto;
}

.search {
  --size: 30px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  font-size: 12px;
  position: relative;
  width: var(--size);
  height: var(--size);
  transition: width 350ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  padding: 4px;
  width: 100%;
  //border:1px solid #e1e1e1;

  button {
  min-width:22px;
  padding:3px;
  opacity:0;

  }
}

#search__input {
  //Modification:- Made the border=0 to make the search box border visible
  //Name:- Rajkumar Pradhan
  //Date:- 21-08-2023
  //border: 0;
  //padding: .25em 1em !important;
  padding-top:6px
  flex-grow: 1;
  outline: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  color:black !important;
  opacity: 1;
  z-index: initial;
  cursor: initial;
  width: calc(100% - var(--size));
  text-transform:capitalize;
  font-size:12px;
}


.search__submit {
  position:relative;
  font-size: 12px;
  margin-left: auto;
  margin-right:12px;
  background: 0;
  border: 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background 200ms ease-out;
  width: calc(var(--size) - 10px);
  height: calc(var(--size) - 10px);
  background: #e5dcd3;
  background: #f7f7f7;
  color: black;
  box-shadow: 0 0 10px rgba(0,0,0,.15);
  svg{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:16px;
    width:18px;
  }

}

.search:focus-within {
  //background-color: #f7f8f9;

  ::placeholder {
  font-size:12px;
  }
  #search__input {
  color: black !important;
  }
  .search__submit {
    //border: 1px solid #0f3868;
    opacity:0;
  }
  .search__submit:hover,
  .search__submit:focus {
    outline: 0;
  }
  *:focus{
  outline:0;
  }
}

.workspace-filter{
  //border: 1px solid #e1e1e15e;
  border-radius: 4px;
  min-width: 20rem;
  align-items: center;
  background-color: white;
  border-bottom-right-radius:0px;
  border-bottom-left-radius:0px;

 .filter-button {
    margin:0 4px;
    border: none;
    background: transparent;

    svg {
      fill:#7c828d !important;
    }
    path {
      fill:#7c828d !important;
    }

  &:hover{
  background: transparent !important;

    path {
      fill:#000 !important;
      }
    }
  }
}


.task-col1{
  top:24px;
  right: -190px;
}

.task-col2{
  top:24px;
  right: -190px;
}

.task-col3{
  top:24px;
  right: -190px !important;
}

.task-col4{
  top:24px;
  right: -190px;
}

.task-col5{
  top:24px;
   right: -190px;
}

.task-col6{
  top:24px;
   left: -100px;
}

.addColumn {
left: -150px;
top: 24px;
height: auto !important;
}

.addColumns {
 width: 24px;
 height: 25px;
 /*
  Modification: Commented right 8px and added right of 0
  By: Devang
  Date: 24/02/2023
 */
 //right: 8px;
 right: 0;
 top: 0px;
 position: absolute;
 z-index: 8;
 background-color: #d6d8db;
 padding-top: 1px;
 border-radius: 2px;

   [type="checkbox"] + label::before{
   top: 3px;
   left: 0;
   width: 0px;
   height: 0px;
   border: 0px solid #5a5a5a;
   font-size: 12px !important;
   }

   [type="checkbox"]:checked + label::before {
   top: 0px;
   left: -5px;
   width: 0px;
   height: 0px;
   border-right: 0px solid #5a5a5a !important;
   border-bottom: 0px solid #5a5a5a !important;
   font-size: 11px !important;
   }


    input[type=checkbox]{
    	height: 0;
    	width: 0;
    	visibility: hidden;
    }

    label {
    	cursor: pointer;
    	text-indent: -9999px;
    	width: 38px;
    	height: 18px !important;
    	background: grey;
    	display: block;
    	border-radius: 40px;
    	position: relative;
    }

    label:after {
    content: '' !important;
     position: absolute !important;
     left: 3px !important;
     width: 14px !important;
     height: 14px !important;
     background: #fff;
     border-radius: 16px !important;
     transition: 0.2s !important;
     transform:scale(1) !important;
    }

   input:checked + label {
    background-color: #bada55;
   }

   input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(135%) !important;

   }

   label:active:after {
    width: 20px;
   }
}

/*
  Modification: Added for excel and nonExcelListTable
  By: Devang
  Date: 04/04/2023
*/
.addColumnsList {
  width: 24px;
  height: 25px;
  z-index: 8;
  background-color: #d6d8db;
  padding-top: 1px;
  border-radius: 2px;

  [type="checkbox"] + label::before{
   top: 3px;
   left: 0;
   width: 0px;
   height: 0px;
   border: 0px solid #5a5a5a;
   font-size: 12px !important;
  }

  [type="checkbox"]:checked + label::before {
   top: 0px;
   left: -5px;
   width: 0px;
   height: 0px;
   border-right: 0px solid #5a5a5a !important;
   border-bottom: 0px solid #5a5a5a !important;
   font-size: 11px !important;
  }

  input[type=checkbox]{
  	height: 0;
  	width: 0;
  	visibility: hidden;
  }

  label {
  	cursor: pointer;
  	text-indent: -9999px;
  	width: 38px;
  	height: 18px !important;
  	background: grey;
  	display: block;
  	border-radius: 40px;
  	position: relative;
  }

  label:after {
  content: '' !important;
   position: absolute !important;
   left: 3px !important;
   width: 14px !important;
   height: 14px !important;
   background: #fff;
   border-radius: 16px !important;
   transition: 0.2s !important;
   transform:scale(1) !important;
  }

 input:checked + label {
  background-color: #bada55;
 }

 input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(135%) !important;

 }

 label:active:after {
  width: 20px;
 }
}

.custom1 {
  position: absolute;
  width: 180px;
  height: 298px;
  overflow: hidden;
  background: #f7f7f7;
  font-size: 11px !important;
  font-weight: 400;
  color: #3b3b3b;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  label,input {
    font-size: 11px !important;
    color: #3b3b3b !important;
    font-weight: 400 !important;
  }

  [type="checkbox"] + label::before{
    top: 3px;
    left: 8px;
    width: 13px;
    height: 13px;
  }

  [type="checkbox"]:checked + label::before {
    width: 9px;
    height: 16px;
    left:4px;
    top:-1px;
  }

  custom1:hover {
    background-color: #e8eaed;
  }


  .apply-filter-btn {
    width:105%;
    padding: 16px 0;
    margin-top:-8px;
    border-radius: 0px;
  }

  #custom-input {
    outline: none;
    border: none;
    font-size: 13px !important;
    width: auto !important;
  }

  .custom-container {
    height: calc(100% - 54px);
  }
  .custom-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
}

.custom-list-dropdown {
  left: -150px;
  top: 24px;
  position: absolute;
  width: 180px;
  height: auto;
  max-height: 298px;
  overflow-y: auto !important;
  overflow: hidden;
  background: #f7f7f7;
  font-size: 11px !important;
  font-weight: 400;
  color: #3b3b3b;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

  .custom-list-dropdown-header {
    padding: 8px;
    font-weight: 500;
    border-bottom: 2px solid #dfdfdf;
    color: #3b3b3b;
    font-size: 13px;
    text-transform: none !important;
  }

  label,input {
    font-size: 11px !important;
    color: #3b3b3b !important;
    font-weight: 400 !important;
  }

  [type="checkbox"] + label::before{
    top: 3px;
    left: 8px;
    width: 13px;
    height: 13px;
  }

  [type="checkbox"]:checked + label::before {
    width: 9px;
    height: 16px;
    left:4px;
    top:-1px;
  }

  &:hover {
    background-color: #e8eaed;
  }


  .apply-filter-btn {
    width:105%;
    padding: 16px 0;
    margin-top:-8px;
    border-radius: 0px;
  }

  #custom-input {
    outline: none;
    border: none;
    font-size: 13px !important;
    width: auto !important;
  }

  .custom-container {
    height: calc(100% - 54px);
  }
  .custom-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
  /*
    Modification: Added for excel and nonExcelListTable
    By: Devang
    Date: 04/04/2023
  */
  .custom-list-dropdown-item {
    border-bottom: 1px solid color-quinary !important;
    padding: 8px 8px 8px 20px !important;
    span {
      text-transform: none !important;
      font-family: var(--roboto-font),sans-serif
      line-height: 18px !important;
      flex-shrink: unset;
      text-align: initial;
    }
  }
}

.listOption {
  position: absolute;
  width: 180px;
  max-height: 250px;
  overflow: auto;
  background: #f7f7f7;
  font-size: 11px !important;
  font-weight: 400;
  color: #3b3b3b;
  z-index: 9999;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  left: 0;

  label,input {
    font-size: 11px !important;
    color: #3b3b3b !important;
    font-weight: 400 !important;
  }

  [type="checkbox"] + label::before{
  top: 3px;
  left: 8px;
  width: 13px;
  height: 13px;

  }

  [type="checkbox"]:checked + label::before {
  width: 9px;
  height: 16px;
  left:4px;
  top:-1px;
  }

  listOption:hover {
  background-color: #e8eaed;
  }


  .apply-filter-btn {
    width:100%;
    padding: 10px 0;
    //margin-top:-8px;
    border-radius: 0px;
  }

  #custom-input {
    outline: none;
    border: none;
    font-size: 13px !important;
    width: auto !important;
  }

  .custom-container {
    height: calc(100% - 54px);
  }
  .custom-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
}

.transition-delay {
  transition-delay: 0.2s !important;
}

#month__task, #month__task-label {
  //filter: invert(1);
  color: #808080 !important;
  font-size: 12px;
  margin-top : 4px !important;
  border: none;
}
#month__task-label{
  font-size: 12px;
  margin-top: 3px !important;
  margin-left: 4px !important;
  font-weight: 400;
}

.task-right-border {
 border-right: 1px solid #e1e1e14a;

}

.task-filter-card-container
{
  display: flex;
  margin-right : 16px;
  //padding-left: 8px;
  border: 1px solid #dbdee3;
  border-radius : 2px;
  height: 24px;
  color:#808080 !important;
}

/*modified by Vipin
   modifield on 30/03/2023
   modification: added task-filter-card-container-v2 class for listview
*/

.task-filter-card-container-v2 {
  display: flex;
  margin-right : 8px;
  border: 1px solid #dbdee3;
  border-radius : 2px;
  height: 24px;
  color:#808080 !important;
}

.task-filter-card-v2 {
  border-radius: 0;
  //margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 8px;
    &:hover {
      //border: 1px solid #e1e1e1ab;
      background-color: #80808038;
      //color: white;
  }
}


.task-filter-card {
  border-radius: 0;
  //margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
    &:hover {
      //border: 1px solid #e1e1e1ab;
      background-color: #80808038;
      //color: white;
  }
}

.task-filter-card-active {
  //background-color: #ddf0f3;
  background-color: #f2f2f2;
  border-radius: 2px;
  //margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  //border-radius: 4px;
  color: black !important;
}

 .task-filter-card-active:hover {
   .task-filter-card-close{
     display: flex;
     }
  }

.task-filter-card-close {
  position: absolute;
  right: 1px;
  background-color: #fff;
  color: black;
  text-align: right;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  display: none;
  }

.task-filter-card-assignees {
  position: absolute;
  top: 21px;
  left: 0;
  right:0;
  z-index: 7;
  background-color: #fff !important;
  padding-top: 4px !important;
  height: 26vh;
  border: 1px solid #e1e1e1;

  ul {
    li {
    font-size: 12px;
    text-align : center;
    }
  }
}

.custom-select {
  position: absolute;
  width: 233px;
  border-radius: 12px;
  height: 45px;
  overflow: hidden;
  padding-left: 15px;
  top:152px;
  left:966px;
  font-size: 16px;
  font-weight: 400;
  color: #2a2e34;
  background-color: #E5DCD3;
  animation: 0.25s ease-out 0s 1 growOutCustomSearchSelect;

  #custom-select-input {
    outline: none;
    border: none;
    font-size: 14px;
    width: auto !important;
  }

  .custom-select-container {
    height: calc(100% - 54px);
  }
  .custom-select-item {
    background: #fff;
    cursor: pointer;
    &:hover {
      background: #f7f8f9;
    }
  }
}

@media (min-width:1024px) {
	/* big landscape tablets, laptops, and desktops */
  .isMobile {
      display:none !important;
  }
  .is-hide-Desktop {
      display:none !important
  }

}

/*modified by Vihang
   modifield on 16/05/2022
   modification: display car container added  for display car checklist
*/
.display-car-container {
  height: calc(100vh - 200px);
}
