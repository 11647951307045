.stock-dashboard{

    .background-theme {
      background-color: rgba(240, 248, 255,1) !important;
  }
  .background-theme-secondary {
      background-color: #bcedfc;
  }
  .background-table-header {
      background-color: #f0f8ff !important;
  }
  .background-transparent {
    background-color: transparent !important;
  }
  .background-bfe1ff {
    background-color: #bfe1ff !important;
  }

  .background-white {
    background-color: #fff !important;
  }
  .background-grey {
    background-color: #ececec;
  }
  .background-lightgrey {
    background-color: #cacad4 !important;
  }
  .background-titanGrey {
    background-color: #52576c !important;
  }
  .background-polarWhite {
    background-color: #eef4f4 !important;
  }
  .background-taigaBrown {
    background-color: #8c804c !important;
  }
  .background-denimBlue {
    background-color: #2243b6 !important;
  }
  .background-lightblue-header {
    background-color: #7bdaf7;
  }
  .background-custom-header {
    background-color: #bcedfc;
  }
  .background-lightblue-count {
    background-color: #64b2d1;
  }
  .background-custom-count {
    background-color: #69ACAC;
  }
  .background-blue-header {
    background-color: #7cb5ec;
  }
  .background-blue-count {
    background-color: #4e779e;
  }
  .background-grey-header {
    background-color: #7f7f84;
  }
  .background-grey-count {
    background-color: #434348;
  }
  .background-green-header {
    background-color: #66a25b;
  }
  .background-green-count {
    background-color: #8de77d;
  }
  .background-orange-header {
    background-color: rgb(212 160 107);
  }
  .background-orange-count {
    background-color: rgb(218 107 54);
  }
  .background-red-header {
    background-color: #d45d58;
  }
  .background-red-count {
    background-color: #c11d1d;
  }
  .bg-theme-blue {
    background-color: #002c5f;
  }

  //Modification: Added focused-pill class for pill in hmil allocation to show focused status
  //By: Devang
  //Date: 14/02/2023
  .focused-pill {
    font-size: 10px;
    border-radius: 9px;
    height:18px;
    background: #ef7f68;
    color: #fff;
    width: fit-content;
    padding: 3px 8px;
    line-height: 1;
  }
}

  .stock-text-dark * {
  //color: black !important;
  }

  .outer2{
    display: flex;
    position: relative;
    margin-top: 0px !important;
    flex-direction: column;
    padding: 10px;
    padding-top: 0px;
    height: auto;
    border-radius: 4px;
  }

  .accordion {
    width: 100%;
    color: white;
    overflow: hidden;
    margin-bottom: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
    &-label {
      display: flex;
      justify-content: space-between;
      padding: 0.5em;
      font-weight: bold;
      cursor: pointer;
      /* Icon */
      &:hover {
        background: #CDE472;
      }
      &::after {
        content: "\276F";
        width: 1em;
        height: 1em;
        text-align: center;
        transition: all .35s;
      }
    }
    &-content {
      max-height: 0;
      //padding: 0 1em;
      color: #000;
      background: white;
      transition: all .35s;
      & > div.row {
        border-bottom: 1px solid #EAEAAE;
      }
      p {
        padding: 5px;
      }
    }
    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: red;
      cursor: pointer;
      &:hover {
        background: #EAEAAE;
      }
    }
  }

.acc-container {
margin: 0 0 10px;
max-width: 100%;
overflow: hidden;
}

.acc-title {
max-width: 100%;
background: #fff;;
border: 1px solid #434348;
color: #000;
border-radius: 4px;
padding: 14px 20px;
font-weight: normal;
position: relative;
transition: all .3s ease-in-out;
}

.acc-active .acc-title,
.acc-title:hover {
  //background: #dcdced;
  background: #f0f8ff;
  cursor: pointer;
  border: 1px solid #98C1D9 !important;
}


.acc-active .acc-title {
  border: 1px solid #98C1D9 !important;
}

.acc-active .acc-title-stock {
  border: 1px solid #EF7F68 !important;
}

.acc-active .acc-title-stock,
.acc-title-stock:hover {
  //background: #dcdced;
  background: #f0f8ff;
  cursor: pointer;
  border-color: #EF7F68 !important;
}

.acc-title:after {
content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3Cline y1='6' x1='12.5' y2='19' x2='12.5' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Plus sign*/
position: absolute;
right: 20px;
top: 20px;
margin-top: -12.5px;
width: 25px;
height: 25px;
display: none;
}

.acc-title {
.acc-title-arrow  {
  span {
    svg  {
      transform: rotate(270deg);
      transition: 0.2s transform ease;
    }
  }
}
}

.acc-active {
.acc-title {
  .acc-title-arrow  {
    span {
      svg {
        transform: rotate(360deg);
        transition: 0.2s transform ease;
      }
    }
  }
}
}

.acc-active .acc-title:after {
content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewbox='0 0 25 25'%3E%3Cline x1='6' y1='12.5' x2='19' y2='12.5' fill='none' stroke='%23000000' stroke-width='2' stroke-linecap='round' vector-effect='non-scaling-stroke' /%3E%3C/svg%3E"); /*Minus sign*/
}

.acc-content {
	width: 100%;
	overflow: hidden;
	height: 0;
	opacity: 0;
	padding: 0 20px;
	transition: all 0.2s ease-in-out;
	border: 1px solid #434348;
	table tbody tr td:not(.stock) {
		cursor: pointer
		&:hover {
			//background-color: #dcdced;
			background-color: #f0f8ff;
		}
	}

}


.acc-active .acc-content {
	padding: 20px;
  height: auto !important;
	overflow-y: auto;
	opacity: 1;
}


.has-text-center {
text-align: center;
}

.f-600 {
font-weight: 600 !important;
}

.min-h-80 {
min-height:	80px;
}


.chart{
min-width: 310px;
max-width: 100%;
}



.task-details-sidecard-status {
//background: #eef4f5;
background: darken(#f0f8ff, 10%);
padding: 7px 15px 8px;
position: relative;
min-height: 32px;
}
.task-detail-sidecard-status-content {
  position: absolute;
  //background: #eef4f5;
  background: darken(#f0f8ff, 10%);
  left: 0;
  right: 0;
  top: 50%;
  padding: 0 15px;
  font-weight: bold;
  color: #837c8b;
}
.task-detail-sidecard-status-date {
  font-weight: normal;
  white-space: nowrap;
}

.task-details-sidecard-other {
background: #fff;
padding: 0px 15px 0px;
position: relative;
min-height: 32px;
margin-top: 10px;
}
.task-detail-sidecard-item {
  padding: 15px 0 17px;
  border-bottom: 1px solid #eef2f4;
  overflow: hidden;
}
.task-detail-sidecard-title {
  font-size: 13px;
  color: #858c96;
  width: 98px;
  float: left;
  word-wrap: break-word;
}
.task-detail-sidecard-item-value {
  color: #000;
  margin-left: 100px;
  padding-left: 5px;
  span {
      border-bottom: 1px dashed;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
  }
}



.analytics-left-nav{
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-left:-10px;
}
.analytics-left-nav span{
  font-size:1.2rem;
  height: 36px;
  font: 15px/36px 'PTSerif', sans-serif ;
  color: #fff;
  text-decoration: none;
  transition: color .17s;
  overflow: hidden;
  cursor: pointer;
  padding:0 10px 0 10px;
  margin:3px 0px;

}
.analytics-left-nav span:hover{
  background-color: #e3e8eb;
  color: #505a68;
  text-decoration: none;
  border-radius: 18px;
  width: max-content;
}

.analytics-right-section{
  background-color:white;
  min-height:700px;

}
.analytics-right-half-section{
  background-color:white;
  min-height:250px;

}
.active-link{
  border-radius: 18px;
  background-color: rgba(94,102,117,.76);
  color:white !important;

  text-overflow:ellipsis !important;
  width:max-content;
}
.active-link:hover{
  border-radius: 18px !important;
  background-color: rgba(94,102,117,.76) !important;
  color:white !important;
}

.title{
  padding:20px;
}

.title span{
  color:gray;
  font-size:1.2rem;
}
.counterCardHover:hover {
box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.35);
-ms-transform: translateY(-1px);
transform: translateY(-1px);
}

// ModifiedBy: Vihang Kale
// Date: 24/12/2021
// Modification: On line 70, added classes for card blue hover and minimal card effect hover

.counterCardHoverBlue {
  transition: transform .5s ease;
  &:hover {
    background: #7bdaf780 !important;
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2);
  }
}
.counterCardHoverLineGrey {
   &:hover {
    border-bottom: 2px solid grey !important;
  }
}
.counterCardHoverLineGreyworkspace {
   &:hover {
    border-bottom: 0.5px solid grey !important;
  }
}
.counterCardHoverBluePad {
   &:hover {
    background: #7bdaf780 !important;
    padding-left:1em;
    width:96%;
  }
}

.counterCardHoverPress {
   &:hover {
      box-shadow: 0 5px 10px rgba(184, 184, 255, 0.4);
    transform: translateY(1px);
  }
}

.counterCardActive {
box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.35);
-ms-transform: translateY(-1px);
transform: translateY(-1px);
}
.counterCard{
  border: none;
  height: 180px;
  margin-top:5px;
  cursor: pointer;
}
.counterCard-half{
  border: none;
  margin-top:5px;
  cursor: pointer;
}
.counterCard.active{
  border: 2px solid #202124;
}
.card-title{
  padding:20px 20px 15px 20px;
  color:white;
  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
}
.card-title path{
  fill:#fff
}
.card-body{
 width:100%;
 min-height:120px;
 //border-bottom-left-radius: 5px;
 //border-bottom-right-radius: 5px;
}
.card-notification{
min-height: 135px !important;
height: 135px !important;
//border-radius: 5px;
}
.card-docket{
 min-height: 105px !important;
 height: 105px !important;
 //border-top-left-radius: 5px;
 //border-top-right-radius: 5px;
 border-bottom-left-radius: 0;
 border-bottom-right-radius: 0;
}
.card-body h1, h2{
  text-align:center;
  color:white;
}
.rank-section{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
.rank-section span{
  font-size:44px;;
  color:white;
  font-weight: bold;
  line-height: 35px;
  margin-top:10px;

}
.rank-section label{
  background-color: #0f93a3;
  color: #fff;
  padding: 5px;
  font-size: 18px;
  line-height: 29px;
  display: block;
  text-align: center;
  margin-top: 10px;
  border-radius: 2px;
  width: 200px;
}


#month__stock, #month__stock-label {
//filter: invert(1);
color: #000 !important;
font-size: 12px;
margin-top : 4px !important;
border: none;
}
#month__stock-label{
font-size: 12px;
margin-top: 3px !important;
margin-left: 4px !important;
font-weight: 400;
}


.search-stock {
--size: 30px;
display: flex;
border-radius: 4px;
overflow: hidden;
font-size: 12px;
position: relative;
width: var(--size);
height: var(--size);
transition: width 350ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
padding: 4px;
width: 100%;
//border:1px solid #e1e1e1;

.search__submit-stock {
min-width:22px;
padding:3px;
opacity:0;
margin-left:0 !important;
position:relative;
font-size: 12px;
margin-left: auto;
margin-right:12px;
border: 0;
cursor: pointer;
border-radius: 50%;
transition: background 200ms ease-out;
width: calc(var(--size) - 10px);
height: calc(var(--size) - 10px);
background: #e5dcd3;
background: #f7f7f7;
color: black;
box-shadow: 0 0 10px rgba(0,0,0,.15);
svg{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height:16px;
  width:18px;
}


}
}


.search-stock:focus-within {
//background-color: #f7f8f9;
::placeholder {
font-size:12px;
}
#search__input {
color: black !important;
}
.search__submit {
  //border: 1px solid #0f3868;
  opacity:0;
}
.search__submit:hover,
.search__submit:focus {
  outline: 0;
}
*:focus{
outline:0;
}
}



#search__input-stock {
border: 0;
//padding: .25em 1em !important;
padding-top:6px
flex-grow: 1;
outline: 0;
position: absolute;
color: #fff !important;
bottom: 0;
left: 0;
width: 100%;
background: transparent;
//color:black !important;
opacity: 1;
z-index: initial;
cursor: initial;
width: calc(100% - var(--size));
text-transform:capitalize;
font-size:12px;
}

.search-popup-main-body-stock {
position: absolute;
top: 48px;
z-index: 1012;
padding: 0px;
display: flex !important;
animation-duration: 200ms;
animation-timing-function: ease;
animation-delay: 0s;
animation-iteration-count: 1;
animation-direction: normal;
animation-fill-mode: both;
animation-play-state: running;
animation-name: popupShowAnimation;
background-color: #fff;
box-shadow: 0 7px 21px rgba(83,92,105,.12), 0 -1px 6px 0 rgba(83,92,105,.06);
justify-content: stretch;
flex-direction: column;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-font-smoothing: antialiased;

.search-popup-outer {
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: block;

  .search-popup-inner {
    overflow: hidden;
    height: 100%;
    color: #535c69;
    display: block;

    .search-popup-inner-padding {
      position: relative;
      margin: 0;
      padding-bottom: 100px;
      min-height: 100%;
      width: 780px;
      background: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      .search-popup-inner-filter-container {
        position: relative;
        float: left;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 20px 0 20px;
        width: 215px;
        height: 100%;
        border-right: 1px solid #e7eaec;
        background: #f8fafb;
        text-transform: uppercase;

        .filter-header {
          border-bottom: 1px solid #e7eaec;
          text-align: center;

          h5 {
            margin: 12px 0 10px;
            color: #a0a5ab;
            display: block;
            margin-block-start: 1.67em;
            margin-block-end: 1.67em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
        }

        .filter-content {
          &::after {
            position: absolute;
            left: 0;
            width: 214px;
            height: 10000px;
            border-right: 1px solid #e7eaec;
            background: #f8fafb;
            content: '';
          }

          .filter-content-item {
            position: relative;
            z-index: 1;
            padding: 0 0 0 3px;
            max-width: 174px;
            height: 39px;
            border-bottom: 1px solid #e7eaec;
            cursor: pointer;
            background: #f8fafb;
            -webkit-transform: translate3d(0px,0px,0px);
            -ms-transform: translate3d(0px,0px,0px);
            transform: translate3d(0px,0px,0px);

            .filter-item-container {
              display: block;
              max-height: 39px;

              .filter-item-text {
                max-width: calc(172px - 40px);
                position: relative;
                display: inline-block;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                white-space: nowrap;
                -webkit-transition: color .3s ease;
                -o-transition: color .3s ease;
                transition: color .3s ease;
                font-weight: bold;
              }
            }

            .filter-item-icons {
              position: absolute;
              right: 0;
              top: 0;
              width: 20px;
              height: 100%;
              -webkit-transition: opacity .3s ease;
              transition: opacity .3s ease;
              opacity: .3;
              cursor: pointer;
            }

            .filter-icon-drag {
              left: -17px;
              background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%3E%3Cg%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%224%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%228%22%20rx%3D%221%22/%3E%3C/g%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              cursor: move;
            }

            .filter-icon-pin {
              right: 20px;
              background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2211%22%20viewBox%3D%220%200%2012%2011%22%3E%3Cpath%20fill%3D%22%23535C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M11.466%202.964L8.73.23C8.535.03%208.25-.046%207.98.025c-.267.07-.476.28-.55.547-.07.267.004.553.2.75l.56.558L3.8%205.157l-.55-.55c-.303-.304-.794-.306-1.098-.004-.304.302-.306.793-.004%201.097l1.677%201.676-3.092%203.3c-.076.077-.076.2%200%20.277.076.076.2.076.276%200l3.3-3.102%201.674%201.675c.304.304.797.304%201.1%200%20.305-.304.305-.797%200-1.1l-.55-.55%203.274-4.39.565.563c.303.28.772.27%201.065-.02.293-.29.305-.76.028-1.064z%22/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              opacity: .3;
            }

            .filter-icon-edit {
              right: 0;
              background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23525C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M.667%207.415L0%2010l2.444-.765c-.152-.417-.376-.797-.66-1.12-.318-.325-.7-.563-1.117-.7zm5.407-5.963l-4.78%205.213c.397.208.765.474%201.094.792.27.343.496.725.67%201.135l4.777-5.21c-.185-.447-.437-.857-.746-1.213-.296-.306-.64-.548-1.018-.715l.002-.003zM8.807.395c-.242-.26-.57-.403-.91-.395-.338.008-.66.165-.892.435l-.287.313c.382.177.732.428%201.033.74.304.348.55.75.732%201.187l.288-.314c.248-.252.39-.604.398-.974.006-.37-.125-.728-.364-.99h.003z%22/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
            }

            .filter-icon-delete {
              right: -17px;
              background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
            }

            .filter-item-settings {
              position: absolute;
              display: none;
              height: 38px;
              //border: 1px solid #e7eaec;
              //border-right: 0;
              //background: #fff;
              top: -1px;
              right: -21px;
              left: -17px;
              z-index: -1;
            }

            .filter-item-settings-selected {
              display: block;
            }
          }

          .filter-selected-item {
            color: #0fa7d7;
            cursor: default;
          }
        }
      }

      .filter-create-container {
        position: relative;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 25px 0 25px;
        min-height: 200px;
        height: 100%;
        background: #fff;
      }

      .field-container {
        padding-top: 20px;
        padding-bottom: 15px;
        margin-bottom: 0;

        &:hover {
          .field-drag-icon {
            opacity: .4;
          }
          .field-delete-icon {
            opacity: .4;
          }
        }
      }

      .field-title-position {
        position: relative;
        padding-top: 20px;
        padding-bottom: 15px;
        margin-bottom: 0;
        //margin: 0 0 17px;
        cursor: pointer;
        -webkit-transform: translate3d(0px,0px,0px);
        -ms-transform: translate3d(0px,0px,0px);
        transform: translate3d(0px,0px,0px);

        .field-title {
          position: absolute;
          top: -1px;
          left: 2px;
          color: #414346;;
          display: inline-block;
          width: 100%;
          opacity: .7;
          cursor: default;
          white-space: nowrap;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        .input-field {
          position: relative;
          padding: 0 10px;
          height: 38px;
          width: 100%;
          border: 1px solid rgba(83,92,105,.2);
          border-radius: 2px;
          background: #fff;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          -webkit-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          outline: 0;

          &::focus {
            border-color: #216bb6;
          }
        }

        .clear-input-field-container {
          top: 21px;
          height: 36px;
          width: 30px;
          position: absolute;
          right: 1px;
          background: #fff;
          .clear-input-icon {
            &::after {
              content: '';
              position: absolute;
              top: 9px;
              right: 0;
              bottom: 0;
              left: 0;
              display: inline-block;
              width: 18px;
              height: 18px;
              //background: url("/assets/images/main-ui-control.svg") 0 -276px no-repeat;
              -webkit-transition: opacity .3s;
              transition: opacity .3s;
              opacity: 1;
            }
          }
        }

        .field-select-div {
          display: table;
          padding-bottom: 0;
          padding: 3px 32px 3px 3px;
          height: auto;
          min-height: 38px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI1IDE2Ij4gIDxwb2x5Z29uIGZpbGw9IiM1MzVDNjkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIzLjU0NiA3LjQyNSAzLjU0NiAxLjYwNyAxLjYwNyAxLjYwNyAxLjYwNyA4LjM5NCAxLjYwNyA5LjM2NCA5LjM2NCA5LjM2NCA5LjM2NCA3LjQyNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDkuOSAxLjY1NykiLz48L3N2Zz4=");
          background-repeat: no-repeat;
          background-position: right center;
          color: #535c69;
          box-sizing: border-box;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;

        }

        .field-style {
          position: relative;
          width: 100%;
          border: 1px solid rgba(83,92,105,.2);
          border-radius: 2px;
          transition: all .3s ease-in-out;
          outline: 0;
        }

        //.field-delete-container {
        //	opacity: 1;
        //	-webkit-transition: opacity .3s;
        //	-o-transition: opacity .3s;
        //	transition: opacity .3s;

          .field-delete-icon {
            position: absolute;
            right: -20px;
            width: 20px;
            height: 100%;
            -webkit-transition: opacity .3s ease;
            transition: opacity .3s ease;
            background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            cursor: move;
            top: 2px;
            //left: -22px;
            opacity: 0;
          }
        //}

        .field-drag-icon {
          position: absolute;
          right: 0;
          width: 20px;
          height: 100%;
          -webkit-transition: opacity .3s ease;
          transition: opacity .3s ease;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%3E%3Cg%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%224%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%228%22%20rx%3D%221%22/%3E%3C/g%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          cursor: move;
          top: 2px;
          left: -22px;
          opacity: 0;
        }
      }

      .popup-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 780px;
        width: 100%;
        height: 100px;

        .save-filter {
          position: absolute;
          bottom: 21px;
          left: 20px;
          width: 182px;
          height: 25px;

          .title {
            display: inline-block;
            overflow: hidden;
            width: 155px;
            color: #212121;
            vertical-align: middle;
            text-transform: uppercase;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            opacity: .6;
            cursor: pointer;
            -webkit-transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            transition: opacity .3s ease;

            &:hover {
              color: #212121;
              opacity: 1;
            }

            &::before {
              display: inline-block;
              margin: 0 7px 3px 0;
              width: 11px;
              height: 11px;
              background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23212121%22%20fill-rule%3D%22evenodd%22%20d%3D%22M6%204V0H4v4H0v2h4v4h2V6h4V4H6z%22/%3E%3C/svg%3E");
              background-position: center;
              background-repeat: no-repeat;
              content: '';
              vertical-align: middle;
            }
          }

          .gear {
            display: inline-block;
            float: right;
            width: 25px;
            height: 25px;
            background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%3Cpath%20fill%3D%22%23212121%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10.238%205.754v-1.27H8.885c-.085-.592-.34-1.1-.677-1.607l.76-.762-.845-.846-.846.76c-.423-.338-.93-.592-1.523-.676V0h-1.27v1.354c-.592.084-1.1.338-1.607.677l-.762-.76-.846.845.76.762c-.338.508-.592%201.015-.676%201.608H0v1.27h1.354c.084.59.338%201.1.677%201.522l-.76.846.93.93.762-.76c.423.338%201.015.592%201.607.676v1.353h1.268V8.97c.593-.085%201.1-.34%201.608-.678l.762.762.93-.93-.93-.847c.338-.423.507-1.015.677-1.608h1.353v.084zm-7.446-.592c0-1.27%201.016-2.37%202.37-2.37%201.27%200%202.37%201.016%202.37%202.37%200%201.27-1.017%202.37-2.37%202.37-1.354-.086-2.37-1.1-2.37-2.37z%22/%3E%3C/svg%3E");
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            opacity: .6;
            cursor: pointer;
            -webkit-transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            transition: opacity .3s ease;

            &:hover {
              color: #212121;
              opacity: 1;
            }
          }

          .reset-filter {
            position: absolute;
            top: -1px;
            left: 0;
            width: 160px;
            pointer-events: auto;
            color: #80868e;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            -webkit-transition: opacity .3s ease;
            -o-transition: opacity .3s ease;
            transition: opacity .3s ease;
            overflow: hidden;
            white-space: nowrap;
            opacity: 0;
          }
        }

        .buttons-container {
          position: absolute;
          right: 23px;
          bottom: 15px;
          width: 516px;
          border-top: 1px solid #f2f3f4;
          text-align: center;

          .buttons-inner {
            position: relative;
            margin: 15px 0 0 0;

            .search-button{
              padding-right: 20px;
              border-radius: 2px 0 0 2px;
              max-width: 100%;
              position: relative;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -ms-flex-pack: center;
              -webkit-box-pack: center;
              justify-content: center;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              padding: 0 12px;
              color: #fff;
              vertical-align: middle;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              white-space: nowrap;
              font-size: 12px/37px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              box-sizing: border-box;
              margin: 0;
              height: 39px;
              outline: none;
              border: 1px solid #868d95;
              border-color: #64b2d1;
              background-color: #64b2d1;
              cursor: pointer;
              transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;

              //&::before {
                //background: url('/assets/images/search.svg');
                //padding-left: 44px;
              //}

              &:hover {
                background: #3eddff;
              }

              img {
                padding-left: 0px;
                padding-right: 6px;
              }

            }
            .reset-button{
              padding-right: 20px;
              border-radius: 2px 0 0 2px;
              max-width: 100%;
              position: relative;
              display: -webkit-inline-box;
              display: -ms-inline-flexbox;
              display: inline-flex;
              -ms-flex-pack: center;
              -webkit-box-pack: center;
              justify-content: center;
              -ms-flex-align: center;
              -webkit-box-align: center;
              align-items: center;
              padding: 0 20px;
              vertical-align: middle;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              white-space: nowrap;
              font-size: 12px/37px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              box-sizing: border-box;
              margin: 0;
              height: 39px;
              outline: none;
              border: 1px solid #868d95;
              border-color: c6cdd3;
              background-color: transparent;
              cursor: pointer;
              transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
              margin-left: 12px;

              &:hover {
                background: #cfd4d8;
                border-color: #c6cdd3;
                color: #535b69;
              }

              &:active {
                background: #dde2e5;
                border-color: #9fa4ab;
                color: #535b69;
              }
            }
          }
        }
      }
    }
  }
}
}

.order-card-lg  {
    //background:#98C1D9;
    border-radius: 5px;
    //width: 147px;
    height: 72px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 40px;
    cursor: pointer;
    padding: 16px;
    border:1px solid transparent;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}

.stock-card-lg  {
    //background:#98C1D9;
    border-radius: 5px;
    //width: 147px;
    height: 72px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    cursor: pointer;
    padding: 16px;
    border:1px solid transparent;
    gap: 16px;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}

.order-card-md  {
    //background:#C08990;
    border-radius: 5px;
    //width: 147px;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 40px;
    cursor: pointer;
    padding: 16px;
    border:1px solid transparent;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}

.order-line-break {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 16px;
}

.order-back-btn {
    width: 20px;
    height: 20px;
    left: 24px;
    //Modification: Changed top to 24px from 21px to fix position of order-back-button on header
    //By: Devang
    //Date: 19/02/2023
    //top: 24px;
    top:8px !important;
    background: #EEEEEE;
    border-radius: 18px;
    position: absolute;
    cursor: pointer;
}

.table-order-container  {
thead {
  th {
    color:#6F727C !important;
  }
}
tbody {
    tr {
        td {
          color:#6F727C !important;
        }
      }
  }
}
.product-color-tag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
    display: inline-block;
}

.order-selected {
    transform: scale(1.1);
    transition: 0.2s transform ease;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: transparent;
    border: 1px solid #002c5f !important;
    color: #002c5f !important;
}
.order-selected-off {
    transform: scale(1);
    transition: 0.2s transform ease;
}
.order-selected-md {
    transform: scale(0.9);
    transition: 0.2s transform ease;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: transparent;
    border: 1px solid #002c5f !important;
    color: #002c5f !important;
}
.order-selected-md-off {
    transform: scale(1);
    transition: 0.2s transform ease;

}

.order-number-card {
    min-width: 24px;
    height: 24px;
    border-radius: 5px;
    background: #fff;
    width: fit-content;

}

.booking-wise-table-container {
  table {
      tr  {
        td:nth-child(2) {
            color: #002C5F !important;
        }
        td:nth-child(7) {
            color: #002C5F !important;
        }
      }
      tr:first-child {
        th {
            background-color: #E5EFF5 !important;
        }
        th:first-child {
            border-top-left-radius: 5px;
        }
        th:last-child {
            border-top-right-radius: 5px;
        }
      }
      tr:nth-child(2) {
        th {
            background-color: #D1E6F2 !important;
        }
      }
  }
}

.stock-card-lg  {
    //background:#98C1D9;
    border-radius: 5px;
    //width: 147px;
    height: 72px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    cursor: pointer;
    padding: 16px;
    border:1px solid transparent;
    gap: 16px;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}

.stock-card-sm  {
    background:#98C1D9;
    border-radius: 5px;
    //width: 147px;
    min-height: 84px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    cursor: pointer;
    padding: 8px;
    transform: scale(1);
    transition: 0.2s transform ease;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}

.stock-card-sm-2  {
    background:#DC8F99;
    border-radius: 5px;
    //width: 147px;
    min-height: 84px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    cursor: pointer;
    padding: 8px;
    border:1px solid transparent;
    transform: scale(1);
    transition: 0.2s transform ease;

    &:hover {
      background:transparent;
      border:1px solid #002c5f !important;
      color:#002c5f !important;
    }
}
.stock-card-sm-selected {
    background:transparent !important;
    border:1px solid #002c5f !important;
    color:#002c5f !important;
    transform: scale(1.1);
    transition: 0.2s transform ease;
}

.stock-line-break {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 16px;
}
