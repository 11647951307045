.small-loader {
  .circle-4-loader {
  	height: 32px;
  	width: 32px;
  	-webkit-animation: circle-4-loader-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-1 {
  	0%   { -webkit-transform: rotate(0deg); }
  	100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes circle-4-loader-1 {
  	0%   { transform: rotate(0deg); }
  	100% { transform: rotate(360deg); }
  }
  .circle-4-loader::before {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: 0; right: auto;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-2 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(24px, 0, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-2 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader::after {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: auto;
  	bottom: 0; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-3 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(-24px, 0, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-3 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader span {
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: 0; right: 0;
  	margin: auto;
  	height: 32px;
  	width: 32px;
  }
  .circle-4-loader span::before {
  	content: "";
  	display: block;
  	position: absolute;
  	top: 0; left: 0;
  	bottom: auto; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-4 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(0, 24px, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-4 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .circle-4-loader span::after {
  	content: "";
  	display: block;
  	position: absolute;
  	top: auto; left: 0;
  	bottom: 0; right: 0;
  	margin: auto;
  	width: 8px;
  	height: 8px;
  	background: #FFF;
  	border-radius: 50%;
  	-webkit-animation: circle-4-loader-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  	        animation: circle-4-loader-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  }
  @-webkit-keyframes circle-4-loader-5 {
  	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  	50%  { -webkit-transform: translate3d(0, -24px, 0) scale(.5); }
  	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); }
  }
  @keyframes circle-4-loader-5 {
  	0%   { transform: translate3d(0, 0, 0) scale(1); }
  	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
  	100% { transform: translate3d(0, 0, 0) scale(1); }
  }
  .small-loader-center {
    display: block;
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    margin: auto;
  }


  .dots-wave {
    width: 12px;
    height: 12px;
    border: 2px dotted #F29339;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
