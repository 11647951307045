.flip-card-inner {
  position: relative;
  perspective: 1000px;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeAnimationText {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 1s ease-in-out 0s forwards;
  z-index: 7;

  // then define the animation delay in each class
  &.first {
    animation-delay: 0.5s;
  }
  &.second {
    animation-delay: 1s;
  }
  &.third {
    animation-delay: 1.5s;
  }
  &.fourth {
    animation-delay: 2s;
  }
  &.fifth {
    animation-delay: 2.5s;
  }
  &.sixth {
    animation-delay: 3s;
  }
   &.seventh {
    animation-delay: 4s;
  }
  &.eighth {
    animation-delay: 4.5s;
  }
 }

.fadeMoveRightAnimation {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInRight 1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeMoveLeftAnimation {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInLeft 1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

//Modification: Made new class for caseDetailViewV3
//By: Devang
//Date:20/09/2022
.fadeMoveUpAnimationNotForwards {
  // first we make all instances of this transparent.
  opacity: 1;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 0.5s ease-in-out 0s;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

.fadeMoveUpAnimation {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 0.5s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}
.fadeMoveUpAnimation1 {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 0.7s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}
.fadeMoveUpAnimation2 {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 0.9s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}
.fadeMoveUpAnimation3 {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 1.1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}
.fadeMoveUpAnimation4 {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInUp 1.3s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


.fadeMoveDownAnimation {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: fadeInDown 1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.zoomOutAnimationNumber {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: zoomNumber 1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes zoomNumber {
  from {
    opacity: 0;
    -webkit-transform: scale(5);
    transform: scale(5);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}



.zoomOutAnimation {
  // first we make all instances of this transparent.
  opacity: 0;
  // as we're using the same animation for each instance, call it here so we're not repeating it
  animation: zoom 1s ease-in-out 0s forwards;
 &.delay500ms {
    animation-delay: 0.5s;
  }
  &.delay1s {
    animation-delay: 1s;
  }
}

@keyframes zoom {
  from {
    opacity: 0;
    -webkit-transform: scale(100);
    transform: scale(100);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.PopFadeTextAnimation {
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }
  span:nth-child(1) {
  animation: fade-in 2s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(2) {
  animation: fade-in 2s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(3) {
  animation: fade-in 2s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }

  span:nth-child(4) {
  animation: fade-in 2s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}
@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

// modified by Vihang
// modified at 25 May 2022
// modification : bounce animation

.bounceAnimation {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-5px);
  }
}
