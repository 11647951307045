.workspace-coupon-details {
  .redeem-modal {
    .redeem-information {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;

      color: var(--theme-default-colour);
    }
    .redeem-text {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #5ED700;
    }
    .redeem-input {
      border: 1px solid var(--theme-default-colour);
      outline: none;
      border-radius: 18px;
      height: 36px;
      width: 160px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #BFBFBF;

      &:hover, &:active, &:focus {
        outline: none;
        border: 1px solid var(--theme-default-colour);
      }
    }
    .redeem-button {
      background: #87C15A;
      border-radius: 5px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #FFFFFF;
    }
    .redeem-button:hover {
      background-color: #00b300;
    }
  }
}
