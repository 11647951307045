.font-family-poppins * {
  font-family: 'Poppins', sans-serif;
}

.font-family-karla * {
  font-family: 'Karla', sans-serif;
}

.font-family-roboto * {
  font-family: 'Roboto';
}

// Modification: Added Poppins, Karla and Roboto font family classes.
// BY: Vipin
// Date: 28/03/2023


.hot-bg-fade {
   background: rgba(255, 0, 0, 0.25);
}
.hot-bg {
   background: rgb(255, 0, 0,0.5);
}
.hot-text {
   color: #FF0000;
   text-transform: uppercase;
}
.cold-text {
   color:rgba(0, 44, 95,1);
   text-transform: uppercase;
}
.warm-text {
   color: #FF7A00;
   text-transform: uppercase;
}

.warm-bg-fade {
   background: rgba(255, 122, 0, 0.25);
}

.warm-bg {
   background: rgba(255, 122, 0, 0.5);
}

.cold-bg-fade {
   background: rgba(0, 44, 95, 0.25);
}

.cold-bg {
   background: rgba(0, 44, 95, 0.5);
}

.caseview-btn-active {
   position: absolute;
   height: 36px;
   //background: #FFFFFF;
   //border: 1px solid #FF7A00;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   border-radius: 16px;
   color:#FF7A00;
   top:-18px;
   padding: 10px;
}

.caseview-btn {
   position: absolute;
   height: 36px;
   //background: #FFFFFF;
   //border: 1px solid #002C5F;
   border-radius: 16px;
   color:var(--theme-default-colour);
   top:-18px;
   padding: 10px;
}

/*
		modified by Arun Singh
		modified at 28/12/2023
		modification : Add new botton css with position absolute for mobile view;
*/

.caseview-btn-for-mobile {
   justify-content: space-between;
   position: absolute;
   right: 0;
   margin: 0;
   padding: 0 8px;
   top: -18px;
   left: 0;
}

.progressbar-new {
  border-radius: 10px;
  justify-content: flex-start;
  border-radius: 5px;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: 0px;
  display: flex;
  height: 32px;
  width: 100%;

  .progressbar1-value {
     transition: width 0.5s linear;
     box-shadow: 0 10px 40px -10px #fff;
     border-radius: 5px;
     height: 32px;
     width: 60%;
     height: 32px;
     border-radius: 5px;
     position: relative;
 }

 .progressbar1-percentage {
   position: absolute;
   right: 10px;
   font-weight: 600;
   font-size: 11px;
   color: var(--text-white);
   top:8px;

 }

}


.workspace-disk-new {
   width: 32px;
   height: 32px;
   border-radius: 100%;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   margin-top:10px;
   margin-left:14px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

   .workspace-disk-count {
     font-size: 16px !important;
     text-align: center;
     color: var(--theme-default-colour) !important;
   }

}

.workspace-ring {
   width:120px;
   height:120px;
   border-radius:100%;
   background-color:rgba(255, 0, 0, 0.32);
   border: 3px solid #E02C2C;
   display: flex;
   justify-content: center;
   align-items: center;


   .workspace-ring-inner {
     width: 94.5px;
     height: 94.5px;
     border-radius:100%;
     background: #F1EFEF;
     border: 3px solid #FF0000;
     display: flex;
     justify-content: center;
     align-items: center;

     .workspace-ring-count {
       font-size: 24px;
       color: #E02C2C;
     }
 }
}

.workspace-header-text {
   color:#888A8C!important;
   //text-transform: uppercase;
   font-size: 16px;
   cursor: pointer;
}

.workspace-pill {
   height: 24px;
   background: #FF5F9;
   background: #FFF;
   border: 1px solid rgba(0, 44, 95, 0.5);
   border-radius: 14px;
   font-size: 12px;
   //width: 68px;
   padding: 4px 8px;
   text-align: center;
}

.workspace-target-count-compare{
   width: 66px;
   height: 81px;
   background: #F1F5F9;
   border-radius: 5px;
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
   margin-bottom: 8px;

   .workspace-target-line {
     border-top: 1px solid var(--theme-default-colour);
     width: 32px;
     height: 0px;
   }
}

.text-green-light {
  color:#96E21A;
  border-color: #96E21A;
}

.text-green-normal {
   color:#47A200;
   border-color:#47A200;
}

.text-blue-light {
   color:#03AEAE;
   border-color:#03AEAE;
}
.text-blue-dark {
   color:#000AFF;
   border-color:#000AFF;

}
.bg-blue-dark {
   background-color:#000AFF;
}


.text-pink-normal {
   color:#F65A9C;
   border-color:#F65A9C;
}

.bg-pink-normal {
   background-color:#F65A9C;
}

.text-teal-normal {
   color:#00DADA;
   border-color:#00DADA;
}

.bg-teal-normal {
   background-color:#00DADA;
}

.line-below-green-light::after {
   content:"";
   position:absolute;
   width:2px;
   bottom:-32px;
   left:15px;
   height:32px;
   background:#96E21A;
   background: linear-gradient(to bottom, #96E21A 0%, #47A200 100%);
}

.line-below-green::after {
   content:"";
   position:absolute;
   width:2px;
   bottom:-32px;
   left:15px;
   height:32px;
   background:#47A200;
   background: linear-gradient(to bottom, #47A200 0%, #03AEAE 100%);
}



.workspace-bookings-card {
   position:relative;
   display:flex;

   .workspace-bookings-disk {
     width: 32px;
     height: 32px;
     border: 2px solid;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 50%;

     .workspace-bookings-count{
       font-size:16px;

     }
 }

 .workspace-bookings-subcard {
     height: 32px;
     border-radius: 5px;
     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
     padding:8px;
     padding-top:4px;
     font-size:16px;
     margin-left:8px;
     margin-top:-1px;
 }

}

.bg-retail-green {
   background-color: #95D934 !important;
}

.text-retail-green {
   color: #95D934 !important;
}

.text-retail-yellow {
   color: #FBC505 !important;
}

.text-retail-blue {
   color: #649FFD !important;
}
.bg-retail-yellow {
   background-color: #FBC505 !important;
}
.bg-retail-blue {
   background-color: #649FFD !important;
}

.workspace-retail-count {
   width: 71px;
   text-align: center;
   margin-bottom:8px;
}

.workspace-retail-divider {
   width: 208px;
   height: 1px;
   background:linear-gradient(to right, #95D934 0%, #FBC505 54%, #649FFD 100% );

}

.workspace-retail-card {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   height: 68%;
   align-items: center;
}

.workspace-retail-card-bar {
   width: 80%;
   height: 53px;
   border-radius: 5px 5px 0px 0px;
   transition: height 0.5s linear;
   //transform: matrix(0, 1, 1, 0, 0, 0);
}

.workspace-retail-bar-container {
   display: flex;
   flex-direction: column;
   justify-content: end;
   height: 140px;

}

.workspace-retail-tag {
   width:71px;
}

.bg-retail-blue {
   background-color: #649FFD !important;
}

.workspace-retail-count {
   width: 71px;
   text-align: center;
   margin-bottom:8px;
}

.workspace-retail-divider {
   width: 208px;
   height: 1px;
   background:linear-gradient(to right, #95D934 0%, #FBC505 54%, #649FFD 100% );

}

.workspace-retail-card {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   height: 68%;
   align-items: center;
}

.workspace-retail-card-bar {
   width: 80%;
   height: 53px;
   border-radius: 5px 5px 0px 0px;
   transition: height 0.5s linear;
   //transform: matrix(0, 1, 1, 0, 0, 0);
}

.workspace-retail-bar-container {
   display: flex;
   flex-direction: column;
   justify-content: end;
   height: 140px;

}

.workspace-retail-tag {
   width:71px;
}

.workspace-delivery-card {
   max-width: 220px;
   height: 36px;
   border: 1px solid rgba(0, 0, 0, 0.25);
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   border-radius: 17px 5px 5px 17px;
   font-size: 16px;
   display: flex;
   gap:8px;

   .workspace-delivery-disk {
     width: 36px;
     height: 36px;
     border-radius:50%;
     margin-top:-1px;
     margin-left:-1px;
     display: flex;
     justify-content: center;
     align-items: center;

     .workspace-delivery-disk-count {
       color: #fff;
     }
 }

 .worskpace-delivery-text {
   padding:4px 0px 8px 0px;
 }

 .workspace-delivery-icon {
   padding:4px 0px 8px 0px;
 }
}

.upcomingEvents-modal-on {
   width: 292.2%;
   height: 330px;
   transition: 0.2s height ease;
   position: absolute;
   top: 102px;
   background: #fff;
   margin-right: 24px;
   border-radius: 5px;
   z-index: 1;

}

.upcomingEvents-modal-off {
   width: 292.2%;
   height: 0px;
   transition: 0.2s height ease;
   position: absolute;
   top: 102px;
   background: #fff;
   margin-right: 24px;
   border-radius: 5px;
   z-index: 1;
}

.workspace-target-card-1 {
   background:#EFEAFD;
   border-radius: 5px;
   width: 106px;
   height: 68px;
   color: #8D65FD;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

}


.workspace-target-card-2 {
   background: #E0F8EB;
   border-radius: 5px;
   width: 106px;
   height: 68px;
   color: #2CDF7E;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

}

.workspace-funnel {
   width: 495px;
   height: 199px;
   border: 1px solid rgba(255, 0, 0, 0.25);
}

.workspace-funnel-border {

}

.checklist-container {
   background: white;
   padding-left: 16px;
   border-radius: 4px;
}

.checklist-Overviewworkshop {
   background: white !important;
   margin: 0 16px !important;
   border-radius: 4px !important;
   padding: 0 16px !important;
}

.checklist-task-list-tags {
   border-radius: 15px !important;
   border: 1px solid var(--theme-default-colour) !important;
   background: transparent !important;
   margin-left: auto !important;
}

.dayplan-r-header {
   background-color: #ececec !important;
}

.newWorkSpaceCard1 {
   border-radius: 4px;
   min-height: 40px;
   height: 33px;
   border: 1px solid var(--theme-default-colour);
   color: var(--theme-default-colour) !important;
   font-weight: 500;
   transition: transform 0.8s;
   transform-style: preserve-3d;

   .listCardCount:hover {
     color: #ececec!important;
   }
}

.workspace-target-card-2 {
   background: #E0F8EB;
   border-radius: 5px;
   width: 106px;
   height: 68px;
   color: #2CDF7E;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

}

.workspace-funnel {
   width: 495px;
   height: 199px;
   border: 1px solid rgba(255, 0, 0, 0.25);
}

.workspace-funnel-border {

}

.checklist-container {
   background: white;
   padding-left: 16px;
   border-radius: 4px;
}

.checklist-Overviewworkshop {
   background: white !important;
   margin: 0 16px !important;
   border-radius: 4px !important;
   padding: 0 16px !important;
}

.checklist-task-list-tags {
   border-radius: 15px !important;
   border: 1px solid var(--theme-default-colour) !important;
   background: transparent !important;
   margin-left: auto !important;
}

.dayplan-r-header {
   background-color: #ececec !important;
}

.newWorkSpaceCard1 {
   border-radius: 4px;
   min-height: 40px;
   height: 33px;
   border: 1px solid var(--theme-default-colour);
   color: var(--theme-default-colour) !important;
   font-weight: 500;
   transition: transform 0.8s;
   transform-style: preserve-3d;

   .listCardCount:hover {
     color: #ececec!important;
   }
}


.newWorkSpaceCard1::after {
   content: attr(data-label);
   position: absolute;
   top: -7.5px;
   left: 5px;
   background: #ececec;
   color: var(--theme-default-colour);
   font-size: 10px;
   padding-right: 5px;
   padding-left: 2px;
}


.innerscrollworkspace {
   height: 100vh;
   overflow-y: auto;
   background: var(--theme-default-colour);
   padding-bottom: 1rem;
}
.Workspacehead {
   letter-spacing: 1px;
   position: sticky;
   z-index: 20;
   padding-top: 20px;
   display: flex;
   top: 0.1px;
   //background: var(--theme-default-colour);
   color: #fff;
   font-weight: 400;
}
.workspace-containerwidth {
   width: calc(100vw - 65px);
}
.Profileworkshop {
   display: flex;
   position: relative;
   letter-spacing: 1px;
}
//By:Yash
//Date:21st June 2022
//Modification: Border color Changed
.workspace-cards, .workspace-cards-hover {
 display: flex;
 //position: relative;
 //border: 1px solid #003468;
 //box-shadow: 0px 1px 3px 1px #e5e5e5;
 border-radius: 5px;
 //margin-top: 20px;
 flex-direction: column;
 background: #fff;
 padding: 10px;
 span {
   font-size: 13px !important;
   font-weight: 400 !important;
   color: #292d34;
 }
}

.workspace-cards-hover {
 cursor: pointer;
 &:hover {
   box-shadow: -2px 7px 3px 1px #e5e5e5;
 }
}

.teamscroll {
 height: 60vh;
 overflow-y: auto;
}

.filterteamborder {
 background: var(--button-primary);
 border: 1px solid #dfebf0;
 border-radius: 12px;
 padding: 4px;
 color: var(--text-white);
 &:hover {
   background-color: var(--button-primary-hover);
 }
}

.round_iconteams {
 display: flex;
 width: 25px;
 border-radius: 50%;
 height: 25px;
 background: var(--button-primary);
 cursor: pointer;
 justify-content: center;
 padding-top: 3px;
 p {
   color: var(--text-white);
 }
}
//By:Yash
//Date:21st June 2022
//Modification: Added New Class
.cardvalue-companyblue {
 color: var(--theme-default-colour) !important;
}


//By:Yash
//Date:21st June 2022
//Modification: Added New Custom Button
.custombutton-nextprev {
   min-width: 75px !important;
   border-radius: 4px !important;
   padding: 0px !important;
   background:none !important;
   height: fit-content !important;
   cursor: pointer !important;
   font-weight: 500 !important;
   border: none !important;
}
.uploadPackagPhotoField, .uploadHandOverPhotoField {
 width: 122px;
 display: flex;
 flex-direction: column;
 align-items: center;
}

//By:Komal
//Date:2 Sep 2022
//Modification: Added New Cards and Box
.boxes{
 display: flex;
 margin-top: 28px;
}
.box1{
 margin-top: 28px;
 margin-left: 0px !important;
 margin-right: 0px !important;
}
.cards, .cards-hover {
 display: flex;
 position: relative;
 border-radius: 5px;
 margin-top: 10px;
 margin-bottom: 10px;
 flex-direction: column;
 background: #fff;
 padding: 10px;
 height: 67vh;
 border-radius: 25px;
 span {
   font-weight:600 !important;
 }
}
.cards-hover {
 cursor: pointer;
 &:hover {
   box-shadow: -2px 7px 3px 1px #e5e5e5;
 }
}
//By:Komal
//Date:30 Sep 2022
//Modification: Added New class outer and custom

.outer, .outer-hover {
 display: flex;
 position: relative;
 margin-top: 10px;
 margin-bottom: 10px;
 flex-direction: column;
 background: #E5DCD3;
 padding: 10px;
 height: auto;
 border-radius: 25px;
 span {
   font-weight:600 !important;
 }
}
.outer-hover {
 cursor: pointer;
 &:hover {
   box-shadow: -2px 7px 3px 1px #e5e5e5;
 }
}

.outer1{
display: flex;
position: relative;
margin-top: 10px;
margin-bottom: 10px;
flex-direction: column;
background: #E5DCD3;
padding: 10px;
height: auto;
border-radius: 4px;
&:focus { outline: none; }
}
.search-transition-on {
 transform: scaleY(1);
 transform-origin: top;
 transition: 0.2s transform ease-out !important;
 background-color: #fff !important;
 padding: 0 !important;

 div {
 padding-top: 0 !important;
 padding-bottom: 0 !important;
 }

 li:hover {
 background-color: #e7e9ec;
 }
}

.search-transition-off {
 transform: scaleY(0);
 transform-origin: top;
 transition: 0.1s transform ease-in !important;
 padding-top: 0 !important;
}
.search-transition-on {
 transform: scaleY(1);
 transform-origin: top;
 transition: 0.2s transform ease-out !important;
 background-color: #fff !important;
 padding: 0 !important;

 div {
 padding-top: 0 !important;
 padding-bottom: 0 !important;
 }

 li:hover {
 background-color: #e7e9ec;
 }
}

.search-transition-off {
 transform: scaleY(0);
 transform-origin: top;
 transition: 0.1s transform ease-in !important;
 padding-top: 0 !important;
}




.task-col1{
 top:24px;
 right: -190px;
}

.task-col2{
 top:24px;
 right: -190px;
}

.task-col3{
 top:24px;
 right: -190px;
}

.task-col4{
 top:24px;
 right: -190px;
}

.task-col5{
 top:24px;
  right: -190px;
}

.task-col6{
 top:24px;
  left: -100px;
}

.addColumn {
left: -164px;
top: 24px;
}

.addColumns {
width: 24px;
height: 39px;
right: 8px;
top: 0px;
position: absolute;
z-index: 2;
background-color: #d6d8db;
padding-top: 8px;
}

.custom {
 position: absolute;
 width: 180px;
 height: 298px;
 //top:187px;
 //padding:16px;
 overflow: hidden;
 background: #f9f9f9;
 //left:966px;
 //right: -80px
 font-size: 13px !important;
 font-weight: 400;
 color: #3b3b3b;
 z-index: 9999;
 //border: 1px solid #3b3b3b;
 border-radius: 4px;
 box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 //animation: 0.1s ease-out 0s 1 growOutCustomSearchSelect;

 label,input {
   font-size: 12px !important;
   color: #3b3b3b !important;
   font-weight: 400 !important;

 }

 [type="checkbox"] + label::before{

 top: 3px;
 left: 0;
 width: 14px;
 height: 14px;
 border: 2px solid #5a5a5a;
 font-size: 12px !important;
 }

 [type="checkbox"]:checked + label::before {
 top: 0px;
 left: -5px;
 width: 9px;
 height: 16px;
 border-right: 2px solid #5a5a5a !important;
 border-bottom: 2px solid #5a5a5a !important;
 font-size: 12px !important;
 }
 input {
   //color: blue;
 }

 .apply-filter-btn {
   width:105%;
   padding: 13px 0;
   margin-top:-8px;
   border-radius: 0px;
 }

 #custom-input {
   outline: none;
   border: none;
   font-size: 13px !important;
   width: auto !important;
 }

 .custom-container {
   height: calc(100% - 54px);
 }
 .custom-item {
   background: #fff;
   cursor: pointer;
   &:hover {
     background: #f7f8f9;
   }
 }
}

.custom-select {
 position: absolute;
 width: 233px;
 border-radius: 12px;
 height: 45px;
 overflow: hidden;
 padding-left: 15px;
 top:152px;
 left:966px;
 font-size: 16px;
 font-weight: 400;
 color: #2a2e34;
 background-color: #E5DCD3;
 animation: 0.25s ease-out 0s 1 growOutCustomSearchSelect;

 #custom-select-input {
   outline: none;
   border: none;
   font-size: 14px;
   width: auto !important;
 }

 .custom-select-container {
   height: calc(100% - 54px);
 }
 .custom-select-item {
   background: #fff;
   cursor: pointer;
   &:hover {
     background: #f7f8f9;
   }
 }
}
.listCardCount {
  color: #808080;
  font-size: 12px;
  margin-top: 4px;
  &:hover {
    // Modification: Replaced color with theme color and commented the previous color
    // BY: Devang
    // Date: 22/02/2023
    // color: #fff;
    color: var(--theme-default-colour);
  }
}

.task-modal-transition-on {

 //opacity: 1;
 height: 100%;
 width: 100%;
 z-index: 1000;
 position:absolute;
 top:-6%;
 transform: scaleY(1);
 transform-origin:top;
 transition: 0.2s transform ease-in;
}

.task-modal-transition-off {

 height: 100%;
 opacity:0;
 width: 100%;
 z-index:1000;
 position: absolute;
 top:-6%;
 transform: scaleY(0);
 //transition: 0.2s transform ease-in;
 //transform-origin:bottom right;
}

.workspace-stage-card{
 display: flex;
 width:100%;
 gap: 1rem;
}

.workspace-filter{
//border: 1px solid #e1e1e1;
//background-color: #f7f7f7;
border-radius: 4px;
min-width:20rem;
flex-basis: auto;
}



/* [Media Queries]
--------------------------------------------------------------------------------
*/

/*
     modified by Vipin
     modified at 10/11/2022
     modification : Added mobile specific classes to the task container, to change width and add padding for mobile view.
*/

@media (max-width:425px){
 /* smartphones, iPhone, portrait 480x320 phones */


 *{
   cursor: default !important;
 }
 .innerscrollworkspace {
    height: calc(100vh - 55px);
  }
 mobile-bottom-nav  * {
   cursor: default !important;
 }

   .task-container{
     width:100%;
     padding-left:0;

   }
   .mobile-top-24{
     top:24px !important;
   }
   .mobile-p-l-0{
     padding-left:0 !important;
   }
   .mobile-p-b-4{
     padding-bottom : 4px !important;
   }
   .mobile-p-b-10{
     padding-bottom: 10px !important;
   }
   .mobile-p-t-30{
     padding-top: 30px !important;
   }
   .mobile-p-l-8{
     padding-left: 8px !important;
   }
   .mobile-p-l-2{
     padding-left: 2px !important;
   }
   .mobile-p-b-50 {
     padding-bottom: 50px !important;
   }
   .mobile-p-l-16{
     padding-left:1rem !important;
   }
   .mobile-p-r-16{
     padding-right:1rem !important;
   }
   .mobile-m-t-0{
     margin-top: 0 !important;
   }
   .mobile-m-t-46 {
     margin-top: 46px;
   }
   .mobile-m-r-4 {
     margin-right: 4px !important;
   }
   .mobile-m-b-neg-60 {
     margin-bottom: -60px;
   }

   .mobile-header2{
     font-size: 14px !important;
     font-weight: 600 !important;
   }
   .mobile-workspaceCard{
     min-height: 50px !important;
   }
   .mobile-f-w-100{
   font-weight:100 !important;
   }
   .mobile-fs-14{
   font-size:14px !important;
   }
   .mobile-fs-12{
   font-size:12px !important;
   }
   .mobile-fs-11{
   font-size:11px !important;
   }
   .mobile-fs-10{
   font-size:10px !important;
   }
   .mobile-fs-9{
   font-size:9px !important;
   }
   .mobile-h-12vh{
   height: 12vh !important;
   }

   .mobile-p-t-24rem{
   padding-top: 3rem !important;
   }

   .mobile-pos-rel {
   position: relative !important;
   z-index:101;
   }
   .mobile-bg-1{
     background: var(--theme-default-colour) !important;
   }

   .mobile-workspacecard-transition1 {
     transform: translate3d(0,-200%,0);
     transition: transform 0.2s ease-in;
     position: fixed;
     width: 96%;
     top: 73px;
     z-index: 100;
   }

   .mobile-workspacecard-transition {
     position: fixed;
     width: 96%;
     top: 73px;
     transform: translate3d(0,0,0);
     background: var(--theme-default-colour);
     z-index: 100;
     padding-bottom: 1rem;
     padding-top:1rem;
     transition: transform 0.2s ease-in;
   }
   .mobile-task::after{
     content:"";
     position:absolute;
     bottom:-16px;
     left:0;
     right:0;
     height:2px;
     width:100%;
     background:#6e747b;
     opacity:0.8;
     transform:scaleY(0);
     transform-origin:bottom;
     transition: transform 0.2s ease-in;
   }
   .mobile-task1::after  {
     content:"";
     position:absolute;
     bottom:-6px;
     left:0;
     right:0;
     height:2px;
     width:100%;
     background:#6e747b;
     opacity:0.8;
     transform:scaleY(100%);
     transition: transform 0.2s ease-in;
     transition-delay:0.2s;
     transform-origin: top;
   }
   .mobile-filter-button{
     position:relative;
     min-width: 20px !important;
     min-height: 20px !important;
     width: 20px !important;
     height: 20px !important;
   }
   .mobile-filter-option{
     position:absolute;
     top:50%;
     left:50%;
     transform:translate(-50%,-50%);
   }
   .mobile-listcard-border{
     border-bottom-left-radius: 0 !important;
     border-bottom-right-radius: 0 !important;
   }
 .mobile-workspacecard-transition-off {
   transform: translate3d(0,-250%,0);
   transition: transform 0.2s ease-in;
   position: fixed;
   width: 96%;
   top: 73px;
   z-index: 100;
 }

 .mobile-workspacecard-transition-on {
   position: fixed;
   width: 96%;
   top: 73px;
   transform: translate3d(0,0,0);
   background: var(--theme-default-colour);
   z-index: 100;
   padding-bottom: 1rem;
   padding-top:1rem;
   transition: transform 0.2s ease-in;
 }
 .mobile-task::after{
   content:"";
   position:absolute;
   bottom:-16px;
   left:0;
   right:0;
   height:2px;
   width:100%;
   background:#6e747b;
   //opacity:0.8;
   opacity:0;
   transform:scaleY(0);
   transform-origin:bottom;
   transition: transform 0.2s ease-in;
 }
 .mobile-task1::after  {
   content:"";
   position:absolute;
   bottom:-6px;
   left:0;
   right:0;
   height:2px;
   width:100%;
   background:#6e747b;
   //opacity:0.8;
   opacity:0;
   transform:scaleY(100%);
   transition: transform 0.2s ease-in;
   transition-delay:0.2s;
   transform-origin: top;
 }
 .mobile-filter-button{
   position:relative;
   min-width: 20px !important;
   min-height: 20px !important;
   width: 20px !important;
   height: 20px !important;
 }
 .mobile-filter-option{
   position:absolute;
   top:50%;
   left:50%;
   transform:translate(-50%,-50%);
 }
 .mobile-listcard-border{
   border-bottom-right-radius: 0 !important;
   border-bottom-left-radius: 0 !important;
 }
 .mobile-task-container {
   padding:0 !important;
   margin:0 !important;
 }
 .mobile-width-500vw {
   width: 500vw !important;
 }
 .mobile-left-neg-7{
   left: -7px;
 }
 .mobile-menu-popup-off{
     position:fixed;
     left:8px;
     right:8px;
     top:30%;
     height: 240px;
     background-color:#f5f5f5;
     color: black;
     font-size: 16px;
     font-weight: 500;
     border-radius: 25px;
     z-index:104;
     display:none;
   li {
     padding:8px 4px;
   }
 }
 .mobile-menu-popup-on{
     position: fixed;
     left: 16px;
     right: 16px;
     top: 30%;
     height: 240px;
     background-color: #1d1e22;
     color: #fff !important;
     font-weight: 200 !important;
     font-size: 14px !important;
     border-radius: 25px;
     z-index: 104;
     box-shadow: 0px 0px 8px rgb(0, 0, 0);

   span {
     color: #fff !important;
     font-weight: 200 !important;
   }

   li {
     padding:8px 4px;
   }
 }
 .mobile-overlay{
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background-color: rgba(0,0,0,0.4);
   z-index: 103;
 }
}

.workspace-target-card-lg {
  //background:#fff;
  border-radius: 5px;
  //width: 147px;
  height: 81px;
  color: #8D65FD;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  cursor: pointer;

  &:hover {
    //background:#f1f2f3;
    //border-color: var(--theme-default-colour) !important;
    //color:white !important;
  }
}

.workspace-new-header-1 {
  position: absolute;
  top: -12px;
  left: 40px;
  background: #ececec;
  padding: 0 8px;
  z-index:2;
}

.workspace-list-container {
  margin:36px 16px 32px 16px;
  color: rgba(0, 44, 95, 0.5);

  .workspace-scroll-cover {
    top: 1px;
    width: 92%;
    right: 27px;
    position:absolute;
    height: 36px;
    z-index:1;

  }

  .workspace-list-head {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 44, 95, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    margin-bottom: 8px;
    font-size: 10px;
    padding-left: 40px;
    position: sticky;
    top:12px;
    z-index:2;

    .workspace-list-head-item {
      font-size:10px;
      cursor: default;
    }

  }
  {/*
    Modification: add hover
    Date: 03/02/2023
    By: Komal
    */}
  .workspace-list-item {
      height: 40px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 44, 95, 0.25);
      border-radius: 5px;
      display: flex;
      align-items: center;
      //justify-content: space-between;
      margin-bottom: 8px;
      font-size: 10px;

    &:hover {
        background: #F5F5F5;
        border-color: rgba(0,44,95,0.6);
        cursor: pointer !important;

        .static-gif {
            background:#f1f2f3 !important;
        }
    }

    .workspace-list-item-col  {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .workspace-list-item-priority {
        width: 16px;
        border: 1px solid rgba(0, 44, 95, 0.25);
        height: 40px;
        border-radius: 5px 0px 0px 5px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .workspace-list-item-priority-p1 {
        background: #f87a53;
    }
    .workspace-list-item-priority-p2 {
        background: #ffee59;
    }
    .workspace-list-item-priority-p3 {
        background: #05e700;
    }

    .workspace-list-item-priority-lead {
        background: #f8a67b;
    }
    .workspace-list-item-priority-inProcess {
        background: #98c1d9;
    }
    .newList-edit-btn:hover {
      span{
          svg {
            path {
              stroke: #5f81ff !important;
            }
          }
        }
      }

    .newList-add-btn:hover {
      span{
        svg {
          path {
            fill: #5f81ff !important;
          }
        }
      }
    }

    .workspace-list-item-col {

      .workspace-list-item-pill {
          border: 0.5px solid #E74C7D;
          border-radius: 9px;
          height:18px;
          color: #E64C7D;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;

      }
      .workspace-list-item-businessImpact-pill {
          border: 0.5px solid #728BCE;
          border-radius: 9px;
          height:18px;
          color: #fff;
          background: #728BCE;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;

      }
      .workspace-list-item-processImpact-pill {
          border: 0.5px solid #C9F57E;
          border-radius: 9px;
          height:18px;
          color: var(--theme-default-colour)80;
          background: #C9F57E;
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;

      }
      .workspace-list-item-notSelected-pill {
          border: 0.5px solid var(--theme-default-colour);
          border-radius: 9px;
          height:18px;
          color: var(--theme-default-colour);
          width: fit-content;
          padding: 3px 8px;
          line-height: 1;
      }
      .workspace-list-item-pill2 {
          border: 0.5px solid var(--theme-default-colour);
          border-radius: 5px;
          width: 21px;
          height: 21px;
          border-radius:50%;
          color: var(--theme-default-colour);
          padding: 0 6px;
          display: flex;
          justify-content: center;
          align-items:center;

      }
    }
  }
}

.workspace-btn-93A4B5-active {
   position: absolute;
   height: 32px;
   background: #93A4B5;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #93A4B5;
   }
}

.workspace-btn-93A4B5 {
   position: absolute;
   height: 32px;
   background: #FFFFFF;
   color: #93A4B5;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:300;
   z-index:2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      color: #fff;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      background: #93A4B5;
   }
}

.workspace-btn-EC6042-active {
   position: absolute;
   height: 32px;
   background: #EC6042;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #EC6042;
   }
}

.workspace-btn-EC6042 {
   position: absolute;
   height: 32px;
   background: #fff;
   color: #EC6042;
   border-radius: 16px;
   top: -24px;
   padding-right: 40px;
   padding-left: 16px;
   font-weight: 300;
   z-index: 2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      color: #fff;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      background: #EC6042;
   }
}


.workspace-btn-526F95-active {
   position: absolute;
   height: 32px;
   background: #526F95;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight: 500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #fff;
      position: absolute;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #526F95;
   }

}

.workspace-btn-526F95 {
   position: absolute;
   height: 32px;
   background: #FFFFFF;
   color: #526F95;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   font-weight:300;
   padding-left: 16px;
   z-index:2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size:12px;
      color: #fff;
      position: absolute;
      z-index:2;
      background: #526F95;
   }
}

.newlist-table {
  table-layout: fixed;
  width: 100px !important;
  border: none !important;
  border-spacing: 0 !important;
  border-collapse: separate !important;
}


/*
  Modification: Added for nonExcelListTable
  By: Devang
  Date: 04/04/2023
*/
.sticky-add-table-columns {
  position: sticky;
  top: 0 !important;
  right: 0;
  background: rgba(255,255,255,0.839) !important;
}
.addColumnsTable {
  width: 43px;
  height: 24px;
  position: relative;
  z-index: 8;
  background-color: #fff;
  padding: 6px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;

  [type="checkbox"] + label::before{
    top: 3px;
    left: 0;
    width: 0px;
    height: 0px;
    border: 0px solid #5a5a5a;
    font-size: 12px !important;
  }

  [type="checkbox"]:checked + label::before {
    top: 0px;
    left: -5px;
    width: 0px;
    height: 0px;
    border-right: 0px solid #5a5a5a !important;
    border-bottom: 0px solid #5a5a5a !important;
    font-size: 11px !important;
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 38px;
    height: 18px !important;
    background: grey;
    display: block;
    border-radius: 40px;
    position: relative;
  }

  label:after {
    content: '' !important;
    position: absolute !important;
    left: 3px !important;
    width: 14px !important;
    height: 14px !important;
    background: #fff;
    border-radius: 16px !important;
    transition: 0.2s !important;
    transform:scale(1) !important;
  }

  input:checked + label {
    background-color: #bada55;
  }

  input:checked + label:after {
   left: calc(100% - 5px);
   transform: translateX(135%) !important;
  }

  label:active:after {
   width: 20px;
  }

  .addColumn-container{
    width: 18px;
    height: 18px;
    border-radius: 18px;
    padding-top: 3px;
    border: 1px solid gray;
  }

}

.addColumnsTable-2{
  width: 43px;
  height: 24px;
  //Modification: Commented right and top and set both as 0 as Added pos-relative class just on parent element of this class in listTableComponent
  //By: Devang
  //Date: 23/02/2023
  //right: 26px;
  //top: 45px;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  padding: 6px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;

  [type="checkbox"] + label::before{
    top: 3px;
    left: 0;
    width: 0px;
    height: 0px;
    border: 0px solid #5a5a5a;
    font-size: 12px !important;
  }

  [type="checkbox"]:checked + label::before {
    top: 0px;
    left: -5px;
    width: 0px;
    height: 0px;
    border-right: 0px solid #5a5a5a !important;
    border-bottom: 0px solid #5a5a5a !important;
    font-size: 11px !important;
  }

   input[type=checkbox]{
     height: 0;
     width: 0;
     visibility: hidden;
   }

   label {
     cursor: pointer;
     text-indent: -9999px;
     width: 38px;
     height: 18px !important;
     background: grey;
     display: block;
     border-radius: 40px;
     position: relative;
   }

   label:after {
      content: '' !important;
      position: absolute !important;
      left: 3px !important;
      width: 14px !important;
      height: 14px !important;
      background: #fff;
      border-radius: 16px !important;
      transition: 0.2s !important;
      transform:scale(1) !important;
   }

  input:checked + label {
    background-color: #bada55;
  }

  input:checked + label:after {
   left: calc(100% - 5px);
   transform: translateX(135%) !important;
  }

  label:active:after {
   width: 20px;
  }

  .addColumn-container{
    width: 18px;
    height: 18px;
    border-radius: 18px;
    padding-top: 3px;
    border: 1px solid gray;
  }
}


.workspace-list-expand {
  position: absolute;
  position: absolute;
  bottom: 4px;
  right: 35px;
  cursor: pointer;
}

.accountableList-pagination {
  .pagination-block {
    background: #fff;
    border-top: 2px solid #e1e1e1;
    padding: 0 16px;
    left: 25px;
    right: 25px;
    bottom: 1px;
    z-index: 999;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: none;
  }
}

.workspace-main {
  display: inline-block;
  vertical-align: top;
}

.workspace-sidebar {
  display: inline-block;
  vertical-align: top;

}

.nudge-btn {
  width: 24px;
  height: 24px;
  background: #D9D9D9;
  opacity: 0.5;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;

}

.workspace-list-businessImpact {
  width: 14px;
  height: 40px;
  background:  #728BCE;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-left:none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workspace-list-processImpact {
  width: 14px;
  height: 40px;
  background: #C9F57E;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-left:none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.workspace-list-item-priority:hover, .workspace-list-processImpact:hover, .workspace-list-businessImpact:hover {
  .list-edit-btn {
    display: flex !important;
  }
  .priority-stuck {
    display: none !important;
  }
}

.list-priority-slider-off {
    top:-1px;
    left: 15px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index:  1;
    position: absolute;
    border-radius: 0px 5px 5px 0px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(0);
    text-align: center;
}

.list-priority-slider-on {
    top:-1px;
    left: 15px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index:  1;
    position: absolute;
    border-radius: 0px 5px 5px 0px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(1);
    text-align: center;
}

.list-impact-slider-off {
    top:-1px;
    margin-left: -20px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index:  1;
    position: absolute;
    border-radius: 5px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(0);
    text-align: center;
    padding:4px 2px;
    color:var(--theme-default-colour);
}

.list-impact-slider-on {
    top:-1px;
    margin-left:-20px;
    width: 129px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    z-index:  1;
    position: absolute;
    border-radius: 5px;
    transition: 0.2s transform ease;
    transform-origin:left;
    transform: scaleX(1);
    text-align: center;
    padding:4px 2px;
    color:var(--theme-default-colour);
}

.list-priority-pill {
    width: 30px;
    height: 8px;
    background: #FF0000;
}
.list-pill-bor-start {
    border-radius: 4px 0px 0px 4px;
}

.list-pill-bor-end {
    border-radius:0px 4px 4px 0px !important;
}

.list-impact-pill-1 {
    position: absolute;
    width: 32px;
    height: 12px;
    background: #728BCE;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.list-impact-pill-2 {
    position: absolute;
    width: 32px;
    height: 12px;
    background: #C9F57E;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}
/*
    modified by Komal
    modified at 06/02/2023
    modification : Add class for dropdown
*/
.rcorners1 {
    box-sizing: border-box;
    position: absolute;
    width: 255px;
    height: 60px;
    padding:12px
    //left: 982px;
    background: #FFFFFF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: auto;
    position: relative;

}
.rcornersV1 {
    box-sizing: border-box;
    position: absolute;
    width: 236px;
    height: 57px;
    padding:12px
    //left: 982px;
    background: #FFFFFF;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: auto;
    position: relative;
    margin-right: 16px;

}
.rcornersV2 {
    box-sizing: border-box;
    position: absolute;
    width: 236px;
    height: 214px;
    padding:12px;
    left: -1px;
    top: 55px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


.rcorners2 {
    box-sizing: border-box;
    position: relative;
    width: 191px;
    height:214px;
    padding:12px;
    left: 0px;
    right:0px;
    top: -3px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.workspace-notification-show {
    width: 337px;
    height: 68px;
    background: #526F95;
    //border: 1px solid #002C5F;
    border-radius: 34px 0px 0px 34px;
    transform: scaleX(1);
    transform-origin: right;
    transition: 0.2s transform ease;
    right:-1px;
    bottom:2%;
    position:fixed;
    padding-left: 32px;
    z-index:4;
    opacity: 1;
}

.workspace-notification-hide {
    width: 337px;
    height: 68px;
    background: #526F95;
    //border: 1px solid #002C5F;
    border-radius: 34px 0px 0px 34px;
    transform: scaleX(0);
    transform-origin: right;
    transition: 0.2s transform ease;
    right:-1px;
    bottom:2%;
    position: fixed;
    padding-left:32px;
    z-index:4;
    opacity: 1;
}

.workspace-notification-disk {
  width: 289px;
  height: 50px;
  border-radius: 18px;
  background: #fff;
  /* border-radius: 50%; */
}

.info-header-show {
    transform: scaleY(1);
    transform-origin: top;
    transition: 0.2s transform ease;
}

.info-header-hide {
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s transform ease;
}

.impact-container:hover {
    background-color: #ececec;
}

// Modification: Added below Team View classes for teamlead dashobard
// BY: Vipin
// Date: 28/03/2023

.teamViewSelect {
  font-size: 12px;
  font-weight: 400;
  border-radius: 12px;
  border: 1px solid #2E8994;
  background: linear-gradient(180deg, #2E8994 12.5%, #002C5F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  height: 24px;
  padding-left: 16px !important;

  &:focus {
    border: 1px solid #2E8994;
  }
}

.teamViewDropDown {
  box-sizing: border-box;
  position: absolute;
  width: 154px !important;
  height: auto;
  left: 5px;
  top: 27px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.25);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-text-fill-color: #242424;
  text-fill-color: #242424;
}

.teamViewDropDown-mobile-view {
  box-sizing: border-box;
  position: absolute;
  width: auto;
  height: auto;
  left: 98px;
  top: 96px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.25);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-text-fill-color: var(--three-dots-text-fill-color);
  text-fill-color: var(--three-dots-text-fill-color);
}

/*
		modified by Arun Singh
		modified at 28/07/2023
		modification : Add CSS to Drop-down for mobile view
*/

.dropDown-mobile-view-three-dots {
  box-sizing: border-box;
  position: absolute;
  width: auto;
  height: auto;
  right: 23px;
  top: 43px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 5px;
  -webkit-text-fill-color: var(--three-dots-text-fill-color);
  text-fill-color: var(--three-dots-text-fill-color);
}
.dropDown-mobile-view-three-dots-account-view {
  box-sizing: border-box;
  position: absolute;
  width: auto;
  height: auto;
  right: 15px;
  top: 76px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 5px;
  -webkit-text-fill-color: var(--three-dots-text-fill-color);
  text-fill-color: var(--three-dots-text-fill-color);
}

.workspace-rotate-on {
  transform: rotate(360deg);
  transition: 0.2s transform linear;
}

.workspace-rotate-off {
  transform: rotate(270deg);
  transition: 0.2s transform linear;
}
.workspace-btn-93A4B5-active {
   position: absolute;
   height: 32px;
   background: #93A4B5;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #93A4B5;
   }
}

.workspace-btn-93A4B5 {
   position: absolute;
   height: 32px;
   background: #FFFFFF;
   color: #93A4B5;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:300;
   z-index:2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      color: #fff;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      background: #93A4B5;
   }
}

.workspace-btn-EC6042-active {
   position: absolute;
   height: 32px;
   background: #EC6042;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight:500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #EC6042;
   }
}

.workspace-btn-EC6042 {
   position: absolute;
   height: 32px;
   background: #fff;
   color: #EC6042;
   border-radius: 16px;
   top: -24px;
   padding-right: 40px;
   padding-left: 16px;
   font-weight: 300;
   z-index: 2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      color: #fff;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      z-index:2;
      background: #EC6042;
   }
}


.workspace-btn-526F95-active {
   position: absolute;
   height: 32px;
   background: #526F95;
   color: #fff;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   padding-left: 16px;
   font-weight: 500;
   z-index:2;

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #fff;
      position: absolute;
      z-index:2;
      box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25);
      background: #526F95;
   }

}

.workspace-btn-526F95 {
   position: absolute;
   height: 32px;
   background: #FFFFFF;
   color: #526F95;
   border-radius: 16px;
   top:-24px;
   padding-right:40px;
   font-weight:300;
   padding-left: 16px;
   z-index:2;

   &:hover {
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   }

   .workspace-btn-count {
      font-size: 12px;
      position: absolute;
      right: -1px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      top: 0px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size:12px;
      color: #fff;
      position: absolute;
      z-index:2;
      background: #526F95;
   }
}

.order-back-btnV2 {
    width: 24px;
    height: 24px;
    left: 24px;
    top: 11px;
    background: #eee;
    border-radius: 18px;
    position: absolute;
    cursor: pointer;
}

/*
		modified by Arun Singh
		modified at 28/12/2023
		modification : Add new back botton without position absolute;
*/

.order-back-btnV3 {
    width: 24px;
    height: 24px;
    background: #eee;
    border-radius: 18px;
    cursor: pointer;
}

/*
		modified by Arun Singh
		modified at 20/08/2023
		modification : Add background hover effect class
*/

.hover-bg-grey:hover {
   background-color: #eee !important;
   border-radius: 5px;

}
