.workspace-list-container-v2 {
  margin:36px 16px 32px 16px;
  color: rgba(0, 44, 95, 0.5);

  .workspace-list-head {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 44, 95, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    //padding-left: 32px;
    position: sticky;
    top: 0;
    z-index: 1;

    .workspace-list-head-item {
      font-size:10px;
    }

  }

  .workspace-list-row {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 44, 95, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;

    &:hover {
      background: #F5F5F5;
      border-color: rgba(0,44,95,0.6);
    }

    .workspace-list-row-priority {
      width: 16px;
      border: 1px solid rgba(0, 44, 95, 0.25);
      height: 40px;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .workspace-list-row-priority-p1 {
      background: #FFE500;

    }
    .workspace-list-row-priority-p2 {
      background: #00C2FF;

    }
    .workspace-list-row-priority-p3 {
      background: #EE683E;

    }
    .workspace-list-row-col {
      overflow: hidden;
      cursor: default;

      .workspace-list-row-pill {
        border: 0.5px solid #E74C7D;
        border-radius: 9px;
        height:18px;
        color: #E64C7D;
        width: fit-content;
        padding: 3px 8px;
        line-height: 1;
        cursor: pointer;
      }
      .workspace-list-row-pill2 {
        border: 0.5px solid #002C5F;
        border-radius: 5px;
        width: 21px;
        height: 21px;
        border-radius:50%;
        color: #002C5F;
        padding: 0 6px;
        display: flex;
        justify-content: center;
        align-items:center;
        cursor: pointer;

      }
    }
  }
  .actionable-row {
    cursor: pointer;
    div, p, span {
      cursor: pointer;
    }
  }
}

.reminder-transition-on {
  transition: 0.2s transform ease;
  transform: scaleY(1);
  transform-origin: top;
}
.reminder-transition-off {
  transition: 0.2s transform ease;
  transform: scaleY(0);
  transform-origin: top;
}

.reminder-container {
  width: 200px;
  height: 112px;
  border-radius: 5px;
  background: white;
  border: 1px solid #bfbfbf;
  position: absolute;
  right: 36px;
  top: 27px;
  z-index: 3;
  padding: 8px;

  .reminder-save-btn {
    background: #d9d9d9;
    border-radius: 5px;
    max-width: 36px;
    height: 20px;
    font-size: 10px;
    padding: 0;
    min-width: 32px;
    margin-left: auto;
    margin-top: 2px;
    color: #3C3C3C;
  }

  .reminder-svg {
    right: 13px;
    bottom: 33px;
    position: absolute;
  }

}

.save-png-container {
  width: 30px;
  height: 30px;
  //border-radius: 15px;
  //background: #fff;
}
