.material_button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 3px;
    padding: 0 8px;
    border-radius: 4px;
    //color: #fff;
    //background: linear-gradient(90deg, rgba(0,44,95,0.75) 1.67%, rgba(46,137,148,0.75) 99.99%, rgba(46,137,148,0) 100%);
    line-height: 1.15;
    font-size: 11px;
    height: 26px;
    word-spacing: 0px;
    letter-spacing: .0892857143em;
    text-decoration: none;
    //text-transform: uppercase;
    min-width: 64px;
    border: none;
    text-align: center;
    box-shadow: 0px 3px 1px -2px #65757c, 0px 2px 2px 0px #6b828d, 0px 1px 5px 0px #39525c;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    :hover {
        background: rgb(98, 0, 238);
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(73 92 138 / 14%), 0px 1px 10px 0px rgb(73 92 138 / 12%);
    }

}
