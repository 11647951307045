/*
	By: Yahsvi
	On: 10 Feb 2021
	Modification: Dynamic scroll arrows for Table.
*/
.hoverArrow {
	display: none;
	background: #9e978e;
	height: 100px;
	width: 50px;
	display: inline-block;
	//position: absolute;
	//top: 65px;
  opacity: 0.3;
	position: fixed;
}

.table-scroll {
  text-align: left;
  position: relative;
  border-collapse: collapse;
	//background: #F6F8FD;
	background: #FFF;
	width: auto;
	overflow: auto;
	table {
		//overflow: hidden;
	  position: relative;
	}
	th, td {
	  padding: 10px;
		border: 0;
		//min-width: 80px;
		//max-width: 80px;
	}
	.table-sort {
		opacity: 0;
		cursor: pointer;
	}
	th:hover {
		.table-sort {
			opacity: 1;
		}
	}
	tbody {
		tr {
			//height: 63px;
			//min-height: 63px;
			border-bottom: 1px #eef2f4 solid;
			&:hover {
				//background: #eef2f4;
				background: #f0f8ff;
				//box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
			}
			&:last-child {
				border-bottom: 4px #eef2f4 solid;
			}
		}
		td {
			vertical-align: bottom;
			span {
				margin: 0 5px;
				//position: relative;
		    //display: block;
		    //margin: 16px 16px 16px;
		    //color: #535c69;
		    //vertical-align: bottom;
		    //font-size: 13px;
			}
			p {
				//color: #65676a !important;
				color: #65676acc !important;
				//vertical-align: top;
		    font-size: 1vw;
				line-height: 1.2;
			}
			button {
				border: 1px solid rgba(71,87,120,0.3);
			}
		}

	}
	thead {
		tr {
		border-bottom: 4px #eef2f4 solid;
		background-color: #fff;
		}
		th {
			position: sticky;
			top: 0; /* Don't forget this, required for the stickiness */
    	//min-height: 48px;
			//min-width: 80px;
			//width: 80px;
			//max-width: 80px;
    	//height: 48px;
			//background: #F6F8FD;
			background: #FFF;
			font-weight: 100;
			font-size: 11px;
			color: #7c7e80;
			//padding: 15px 16px 11px;
			&:first-child {
				//padding: 0px;
			}
			&:nth-child(2) {
				//padding: 0px;
			}
		}
		.table-title {
			z-index: 7;
			display: inline-block;
			overflow: hidden;
			padding-right: 5px;
			max-width: 100%;
			//color: #535c69;
			color: #65676a;
			text-align: left;
			text-transform: uppercase;
			-ms-text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			white-space: nowrap;
			letter-spacing: .5px;
			font-weight: 500;
			font-size: 11px;
			line-height: 12px;
		}
		.resize-button {
			position: absolute;
			top: 15px;
			right: -6px;
			bottom: 10px;
			z-index: 10;
			display: block;
			background: #eef2f4;
			width: 8px;
			//border: 6px solid #eef2f4;
			opacity: 0;
			cursor: col-resize;
			-webkit-transition: opacity 200ms;
			-o-transition: opacity 200ms;
			transition: opacity 200ms;
			&::after {
				position: absolute;
				top: 100%;
				right: 4px;
				z-index: 1;
				display: inline-block;
				width: 6px;
				background: #eef2f4;
				content: '';
				opacity: 0;
				-webkit-transition: opacity 200ms;
				-o-transition: opacity 200ms;
				transition: opacity 200ms;
			}
			&::before {
				position: absolute;
		    top: 50%;
		    right: 4px;
		    display: inline-block;
		    margin-top: -13px;
		    width: 6px;
		    height: 26px;
		    background: rgba(0,0,0,.1);
		    content: '';
		    font-size: 13px;
			}
		}
	}
	.table-icon {
		path {
			color: #808080;
			fill: #808080;
			&:hover {
				color: #535c69;
				fill: #535c69;
			}
		}
	}
	.add-button {
    padding-right: 20px;
    border-right: none !important;
    border-radius: 3px;
    max-width: 100%;
    position: relative;
    height: 25px;
    font-size: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    border: 1px solid #64b2d1;
    background-color: #64b2d1;
    cursor: pointer;
    transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
    font-weight: normal;
	}
}

.actions-block {
	tbody {
		tr {
		border-bottom: 4px #eef2f4 solid;
		background-color: #fff;
		height: 42px;
		}
		th {
			background: #F6F8FD;
			border: 0px;
			padding: 0px 10px;
			width: 7%;
			&:nth-child(2) {
				width: 8%;
			}
			&:nth-child(3) {
				width: 12%;
			}
			&:nth-child(4) {
				width: 35%;
			}
		}
		.table-title {
			z-index: 7;
			display: inline-block;
			overflow: hidden;
			//padding-right: 21px;
			max-width: 100%;
			color: #535c69;
			text-align: left;
			text-transform: uppercase;
			letter-spacing: .5px;
			font-weight: 600;
			font-size: 11px;
			line-height: 12px;
		}
	}
}


.main-header {
	margin-top: -10px;
	min-height: 55px;
	box-sizing: border-box;
	position: relative;
	display: block;
	width: 100%;
	background-color: #5292ac;
	.main-header-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0 18px 0 13px;
		min-height: 55px;
		box-sizing: border-box;
		.header-title {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-flex: 0;
			-ms-flex: 0 1 auto;
			flex: 0 1 auto;
			margin: 0 45px 0 0;
			padding: 15px 0 15px 0;
			max-width: 100%;
			overflow: hidden;
			color: #fff;
	    margin: 0 15px 0 0 !important;
			.header-title-name {
				display: inline-block;
				padding: 0 5px 0 0;
				min-width: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				box-sizing: border-box;
				position: relative;
				//color: #202124;
				color: #fff;
			}
			.header-title-star {
				background-size: 20px auto;
				width: 20px;
				min-width: 20px;
				max-width: 20px;
				display: inline-block;
		    height: 19px;
		    background-repeat: no-repeat;
		    cursor: pointer;
			}
			.deactive {
				background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2017%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23AFB5BB%22%20d%3D%22M13.915%2016.225l-.936-5.461%203.968-3.868-5.484-.797-2.452-4.97L6.558%206.1l-5.484.797%203.969%203.868-.937%205.461%204.905-2.578%204.904%202.578z%22/%3E%3C/svg%3E");
			}
			.active {
				background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2017%22%3E%3Cpath%20fill%3D%22%23AFB5BB%22%20fill-rule%3D%22evenodd%22%20d%3D%22M9.01%2014.211l-5.03%202.645a.3.3%200%200%201-.436-.316l.961-5.602-4.07-3.967a.3.3%200%200%201%20.167-.512l5.624-.817L8.742.545a.3.3%200%200%201%20.538%200l2.515%205.097%205.625.817a.3.3%200%200%201%20.166.512l-4.07%203.967.96%205.602a.3.3%200%200%201-.435.316l-5.03-2.645z%22/%3E%3C/svg%3E");
			}
		}
		.header-filter-search-flexible {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			overflow: hidden;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.header-filter-search {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: nowrap;
				flex-wrap: nowrap;
				position: relative;
				height: 39px;
				border: 1px solid #fff;
				border-radius: 10px;
				//background: #fff;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				outline: 0;
				z-index: 1;
				overflow: hidden;
				max-width: 100%;
    		min-width: 200px;
		    margin: auto 19px auto 0;
		    width: 100%;
		    //max-width: 813px;
		    //min-width: 300px;
				float: left;
				.search-input {
					position: relative;
					z-index: 1;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					margin: 4px 1px;
					padding: 0 50px 0 15px;
					width: 100%;
					height: 30px;
					outline: 0;
					border: 0;
					border-radius: 2px;
					-webkit-transition: background .3s;
					-o-transition: background .3s;
					transition: background .3s;
					color: #fff;
					&::placeholder {
						color: #202124;
					}
				}
				.inner-search {
					position: relative;
					-webkit-box-flex: 0;
					-ms-flex-positive: 0;
					flex-grow: 0;
					-ms-flex-preferred-size: min-content;
					flex-basis: min-content;
					margin: 3px 4px 4px 4px;
					display: inline-block;
			    float: left;
			    padding: 0 30px 0 13px;
					z-index: 3;
			    overflow: visible;
					//background: #bcedfc;
			    background: #fff;
					height: 31px;
					white-space: nowrap;
					outline: 0;
					border-radius: 8px;
					//&:last-child {
						&::after {
							cursor: pointer;
							position: absolute;
							top: -3px;
							right: -17px;
							display: inline-block;
							width: 14px;
							height: 36px;
							//background: #fff;
							color: #202124;
							content: '';
							text-align: center;
						}
					//}
					.text {
						display: inline-block;
						max-width: 250px;
						color: rgba(108,109,138,0.8);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.cross {
						cursor: pointer;
						height: 30px;
						width: 25px;
						position: absolute;
				    right: 0;
				    top: 0;
						background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				.search-cross {
					position: absolute;
					top: 0;
					right: 3px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					padding: 0 5px;
					height: 39px;
					width: 60px;
					box-sizing: border-box;
				 .search-icon {
		 			 cursor: pointer;
					 position: absolute;
					 z-index: 1;
					 width: 25px;
					 height: 39px;
					 background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%3E%3Cpath%20fill%3D%22%23535C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M1.33%205.5c0-2.304%201.866-4.17%204.17-4.17S9.67%203.195%209.67%205.5c0%202.305-1.865%204.17-4.17%204.17-2.305%200-4.17-1.865-4.17-4.17m12.528%206.944l-3.816-3.817-.014-.008C10.64%207.73%2011%206.66%2011%205.5%2011%202.462%208.537%200%205.5%200S0%202.462%200%205.5%202.463%2011%205.5%2011c1.158%200%202.232-.36%203.118-.972.004.004.005.01.01.014l3.816%203.817c.372.37.982.37%201.354%200l.06-.063c.372-.372.372-.982%200-1.354%22/%3E%3C/svg%3E");
					 background-position: center 12px;
					 background-repeat: no-repeat;
					 opacity: .3;
					 right: 30px;
					 transition: all 150ms;
				 }
				 .cross-icon {
		 			 cursor: pointer;
					 position: relative;
					 left: 15px;
				   width: 20px;
				   background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2211%22%20viewBox%3D%220%200%2012%2011%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7.587%205.503l4.4-4.398-1.132-1.13-4.4%204.397L2.06-.026l-1.13%201.13%204.397%204.4L.927%209.9l1.13%201.132%204.4-4.398%204.398%204.398%201.13-1.13-4.398-4.4z%22/%3E%3C/svg%3E");
				   background-repeat: no-repeat;
				   background-position: center 14px;
				   -webkit-transition: right .3s,opacity .3s;
				   -o-transition: right .3s,opacity .3s;
				   transition: right .3s,opacity .3s;
				   opacity: .3;
				 }
			  }
			}
		}
		.header-list-type-toggle {
			margin-left: auto;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			margin-right: 10px;
			height: 25px;
			border: 1px solid #fff;
			//border: 1px solid #d3d3d3;
			border-radius: 8px;

			div {
				//width: 75px;
				width: 52px;
				//padding: 10px 7px;
				padding: 3px 7px;
				text-align: center;
				height: 100%;
				//font-size: 13px;
				font-size: 12px;
				border-radius: 0 6px 6px 0;
				cursor: pointer;

				&:hover {
					background: #7bdaf780;
				}

				&:not(:last-child) {
					border-right: 1px solid #d3d3d3;
					border-radius: 6px 0 0 6px;
				}

				&.active-list-type {
					background-color: #64b2d1;
					border: 1px solid #64b2d1;
					color: #fff;
					font-weight: 600;

					&:hover {
						background: #3eddff;
						border: 1px solid #3eddff;
					}
				}
			}

			img {
				width: 28px;
				cursor: pointer;

				&.active-list-type {
					border: 1px solid turquoise;
					padding: 2px;
				}
			}
		}
		.header-right-buttons {
			margin-left: auto;
			display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
			.settings-button {
				//cursor: pointer;
				//background: transparent;
				//opacity: 1.5;
				//border: 1px solid #64b2d1;
				//height: 29px;
				//padding: 0;
		    //min-width: 0;
		    width: 39px;
		    //font-size: 0;
				border-radius: 6px;
				line-height: calc(39px - 2px);
				vertical-align: middle;
				background: #64b2d1;
				box-sizing: border-box;
				margin: 0;
				height: 39px;
				outline: none;
				border: 1px solid #64b2d1;
				border-color: rgba(255, 255, 255, 0.4);
				background-color: #64b2d1;
				cursor: pointer;
				transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
				svg {
					path {
					fill: #ffffff;
					}
				}
				//&::after {
				//left: 50%;
				//top: 50%;
				//transform: none;
				//margin: -12px 0 0 -12px;
				//position: absolute;
		    //top: 50%;
		    //left: 12px;
		    //display: block;
		    //transform: translateY(-50%);
		    //width: 24px;
		    //height: 24px;
		    //background: no-repeat center;
		    //background: url("/assets/images/Setting.svg");
		    //content: '';
		    //transition: 160ms color linear,160ms opacity linear,160ms background-color linear;
				//}
				&:hover {
					background: #3eddff;
					//opacity: 0.5;
					border: 1px solid #3eddff;
				}
				&:active {
					background: transparent;
					opacity: 0.4;
				}
			}
			.header-right-right-buttons {
				margin: 0 0 0 12px;
				position: relative;
		    display: -webkit-inline-box;
		    display: -ms-inline-flexbox;
		    display: inline-flex;
		    -webkit-box-align: stretch;
		    -ms-flex-align: stretch;
		    align-items: stretch;
		    //padding-right: 30px;
		    border-radius: 3px;
				line-height: calc(39px - 2px);
    		vertical-align: middle;
				.add-button {
					padding-right: 20px;
					border-right: none !important;
					border-radius: 3px;
					max-width: 70%;
					position: relative;
			    display: -webkit-inline-box;
			    display: -ms-inline-flexbox;
			    display: inline-flex;
			    -ms-flex-pack: center;
			    -webkit-box-pack: center;
			    justify-content: center;
			    -ms-flex-align: center;
			    -webkit-box-align: center;
			    align-items: center;
			    padding: 0 20px;
			    color: #fff;
			    vertical-align: middle;
			    text-align: center;
			    text-decoration: none;
			    text-transform: uppercase;
			    white-space: nowrap;
			    font-size: 12px/37px;
			    -webkit-user-select: none;
			    -moz-user-select: none;
			    -ms-user-select: none;
			    user-select: none;
					box-sizing: border-box;
			    margin: 0;
			    //height: 39px;
					height: 25px;
			    outline: none;
			    border: 1px solid #868d95;
			    border-color: #64b2d1;
			    background-color: #64b2d1;
			    cursor: pointer;
			    transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
					font-size: 32px;
					font-weight: normal;
					&:hover {
						background: #3eddff;
					}
				}

				.extra {
					position: absolute;
					top: 7px;
					bottom: 6px;
    			right: 30px;
					width: 1px;
					background-color: #98ddfb;
					content: '';
					opacity: 1;
				}
				.dropDown-button {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 30px;
					border-left: none !important;
					border-radius: 0 2px 2px 0;
					box-sizing: border-box;
			    margin: 0;
			    height: 39px;
			    outline: none;
			    border: 1px solid #868d95;
			    border-color: #64b2d1;
			    background-color: #64b2d1;
			    cursor: pointer;
			    transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
					&:hover {
						background: #3eddff;
					}
					&::after {
						content: '';
						margin-top: -1px;
						right: 10px;
						top: 50%;
						position: absolute;
						border: 4px solid transparent;
						width: 8px;
						height: 8px;
						opacity: 0.7;
						box-sizing: border-box;
						border-top-color: #fff !important;
					}
				}
			}
		}
	}
}

.search-popup-main-body {
	position: absolute;
	top: 62px;
	z-index: 1012;
	padding: 0px;
	display: flex !important;
  animation-duration: 200ms;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  animation-name: popupShowAnimation;
	background-color: #fff;
  box-shadow: 0 7px 21px rgba(83,92,105,.12), 0 -1px 6px 0 rgba(83,92,105,.06);
	justify-content: stretch;
	flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-font-smoothing: antialiased;

	.search-popup-outer {
		padding: 0px;
		-webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
		display: block;

		.search-popup-inner {
			overflow: hidden;
			height: 100%;
			color: #535c69;
			display: block;

			.search-popup-inner-padding {
				position: relative;
				margin: 0;
				padding-bottom: 100px;
				min-height: 100%;
				width: 780px;
				background: #fff;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;

				.search-popup-inner-filter-container {
					position: relative;
					float: left;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					padding: 0 20px 0 20px;
					width: 215px;
					height: 100%;
					border-right: 1px solid #e7eaec;
					background: #f8fafb;
					text-transform: uppercase;

					.filter-header {
						border-bottom: 1px solid #e7eaec;
						text-align: center;

						h5 {
							margin: 12px 0 10px;
							color: #a0a5ab;
							display: block;
					    margin-block-start: 1.67em;
					    margin-block-end: 1.67em;
					    margin-inline-start: 0px;
					    margin-inline-end: 0px;
						}
					}

					.filter-content {
						&::after {
							position: absolute;
							left: 0;
							width: 214px;
							height: 10000px;
							border-right: 1px solid #e7eaec;
							background: #f8fafb;
							content: '';
						}

						.filter-content-item {
							position: relative;
							z-index: 1;
							padding: 0 0 0 3px;
							max-width: 174px;
							height: 39px;
							border-bottom: 1px solid #e7eaec;
							cursor: pointer;
							background: #f8fafb;
					    -webkit-transform: translate3d(0px,0px,0px);
					    -ms-transform: translate3d(0px,0px,0px);
					    transform: translate3d(0px,0px,0px);

							.filter-item-container {
								display: block;
								max-height: 39px;

								.filter-item-text {
							    max-width: calc(172px - 40px);
									position: relative;
									display: inline-block;
									overflow: hidden;
									-o-text-overflow: ellipsis;
									text-overflow: ellipsis;
									white-space: nowrap;
									-webkit-transition: color .3s ease;
									-o-transition: color .3s ease;
									transition: color .3s ease;
									font-weight: bold;
								}
							}

							.filter-item-icons {
								position: absolute;
								right: 0;
								top: 0;
								width: 20px;
								height: 100%;
								-webkit-transition: opacity .3s ease;
								transition: opacity .3s ease;
								opacity: .3;
								cursor: pointer;
							}

							.filter-icon-drag {
								left: -17px;
								background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%3E%3Cg%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%224%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%228%22%20rx%3D%221%22/%3E%3C/g%3E%3C/svg%3E");
								background-position: center;
								background-repeat: no-repeat;
								cursor: move;
							}

							.filter-icon-pin {
								right: 20px;
								background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2211%22%20viewBox%3D%220%200%2012%2011%22%3E%3Cpath%20fill%3D%22%23535C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M11.466%202.964L8.73.23C8.535.03%208.25-.046%207.98.025c-.267.07-.476.28-.55.547-.07.267.004.553.2.75l.56.558L3.8%205.157l-.55-.55c-.303-.304-.794-.306-1.098-.004-.304.302-.306.793-.004%201.097l1.677%201.676-3.092%203.3c-.076.077-.076.2%200%20.277.076.076.2.076.276%200l3.3-3.102%201.674%201.675c.304.304.797.304%201.1%200%20.305-.304.305-.797%200-1.1l-.55-.55%203.274-4.39.565.563c.303.28.772.27%201.065-.02.293-.29.305-.76.028-1.064z%22/%3E%3C/svg%3E");
								background-position: center;
								background-repeat: no-repeat;
								opacity: .3;
							}

							.filter-icon-edit {
								right: 0;
								background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23525C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M.667%207.415L0%2010l2.444-.765c-.152-.417-.376-.797-.66-1.12-.318-.325-.7-.563-1.117-.7zm5.407-5.963l-4.78%205.213c.397.208.765.474%201.094.792.27.343.496.725.67%201.135l4.777-5.21c-.185-.447-.437-.857-.746-1.213-.296-.306-.64-.548-1.018-.715l.002-.003zM8.807.395c-.242-.26-.57-.403-.91-.395-.338.008-.66.165-.892.435l-.287.313c.382.177.732.428%201.033.74.304.348.55.75.732%201.187l.288-.314c.248-.252.39-.604.398-.974.006-.37-.125-.728-.364-.99h.003z%22/%3E%3C/svg%3E");
								background-position: center;
								background-repeat: no-repeat;
							}

							.filter-icon-delete {
								right: -17px;
								background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
								background-position: center;
								background-repeat: no-repeat;
							}

							.filter-item-settings {
								position: absolute;
								display: none;
								height: 38px;
								//border: 1px solid #e7eaec;
								//border-right: 0;
								//background: #fff;
								top: -1px;
						    right: -21px;
						    left: -17px;
						    z-index: -1;
							}

							.filter-item-settings-selected {
								display: block;
							}
						}

						.filter-selected-item {
							color: #0fa7d7;
							cursor: default;
						}
					}
				}

				.filter-create-container {
					position: relative;
					overflow: hidden;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					padding: 20px 25px 0 25px;
					min-height: 200px;
					height: 100%;
					background: #fff;
				}

				.field-container {
					padding-top: 20px;
					padding-bottom: 15px;
					margin-bottom: 0;

					&:hover {
						.field-drag-icon {
							opacity: .4;
						}
						.field-delete-icon {
							opacity: .4;
						}
					}
				}

				.field-title-position {
					position: relative;
					padding-top: 20px;
					padding-bottom: 15px;
					margin-bottom: 0;
					//margin: 0 0 17px;
					cursor: pointer;
					-webkit-transform: translate3d(0px,0px,0px);
					-ms-transform: translate3d(0px,0px,0px);
					transform: translate3d(0px,0px,0px);

					.field-title {
						position: absolute;
						top: -1px;
						left: 2px;
						color: #414346;;
						display: inline-block;
						width: 100%;
						opacity: .7;
						cursor: default;
						white-space: nowrap;
						overflow: hidden;
						-o-text-overflow: ellipsis;
						text-overflow: ellipsis;
					}

					.input-field {
						position: relative;
						padding: 0 10px;
						height: 38px;
						width: 100%;
						border: 1px solid rgba(83,92,105,.2);
						border-radius: 2px;
						background: #fff;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						box-sizing: border-box;
						-webkit-transition: all .3s ease-in-out;
						transition: all .3s ease-in-out;
						outline: 0;

						&::focus {
							border-color: #216bb6;
						}
					}

					.clear-input-field-container {
						top: 21px;
						height: 36px;
				    width: 30px;
				    position: absolute;
				    right: 1px;
				    background: #fff;
						.clear-input-icon {
							&::after {
								content: '';
								position: absolute;
								top: 9px;
								right: 0;
								bottom: 0;
								left: 0;
								display: inline-block;
								width: 18px;
								height: 18px;
								//background: url("/assets/images/main-ui-control.svg") 0 -276px no-repeat;
								-webkit-transition: opacity .3s;
								transition: opacity .3s;
								opacity: 1;
							}
						}
					}

					.field-select-div {
						display: table;
						padding-bottom: 0;
						padding: 3px 32px 3px 3px;
				    height: auto;
				    min-height: 38px;
				    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI1IDE2Ij4gIDxwb2x5Z29uIGZpbGw9IiM1MzVDNjkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIzLjU0NiA3LjQyNSAzLjU0NiAxLjYwNyAxLjYwNyAxLjYwNyAxLjYwNyA4LjM5NCAxLjYwNyA5LjM2NCA5LjM2NCA5LjM2NCA5LjM2NCA3LjQyNSIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDkuOSAxLjY1NykiLz48L3N2Zz4=");
				    background-repeat: no-repeat;
				    background-position: right center;
				    color: #535c69;
				    box-sizing: border-box;
				    -moz-appearance: none;
				    -webkit-appearance: none;
				    appearance: none;

					}

					.field-style {
						position: relative;
						width: 100%;
						border: 1px solid rgba(83,92,105,.2);
						border-radius: 2px;
						transition: all .3s ease-in-out;
						outline: 0;
					}

					//.field-delete-container {
					//	opacity: 1;
					//	-webkit-transition: opacity .3s;
					//	-o-transition: opacity .3s;
					//	transition: opacity .3s;

						.field-delete-icon {
							position: absolute;
							right: -20px;
							width: 20px;
							height: 100%;
							-webkit-transition: opacity .3s ease;
							transition: opacity .3s ease;
							background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
							background-position: center;
					    background-repeat: no-repeat;
					    cursor: move;
							top: 2px;
	    				//left: -22px;
							opacity: 0;
						}
					//}

					.field-drag-icon {
						position: absolute;
						right: 0;
						width: 20px;
						height: 100%;
						-webkit-transition: opacity .3s ease;
						transition: opacity .3s ease;
						background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%3E%3Cg%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%224%22%20rx%3D%221%22/%3E%3Crect%20width%3D%2212%22%20height%3D%222%22%20y%3D%228%22%20rx%3D%221%22/%3E%3C/g%3E%3C/svg%3E");
				    background-position: center;
				    background-repeat: no-repeat;
				    cursor: move;
						top: 2px;
    				left: -22px;
						opacity: 0;
					}
				}

				.popup-footer {
					position: absolute;
					bottom: 0;
					left: 0;
					max-width: 780px;
					width: 100%;
					height: 100px;

					.save-filter {
						position: absolute;
						bottom: 21px;
						left: 20px;
						width: 182px;
						height: 25px;

						.title {
							display: inline-block;
							overflow: hidden;
							width: 155px;
							color: #212121;
							vertical-align: middle;
							text-transform: uppercase;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
							white-space: nowrap;
							opacity: .6;
							cursor: pointer;
							-webkit-transition: opacity .3s ease;
							-o-transition: opacity .3s ease;
							transition: opacity .3s ease;

							&:hover {
								color: #212121;
								opacity: 1;
							}

							&::before {
								display: inline-block;
								margin: 0 7px 3px 0;
								width: 11px;
								height: 11px;
								background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23212121%22%20fill-rule%3D%22evenodd%22%20d%3D%22M6%204V0H4v4H0v2h4v4h2V6h4V4H6z%22/%3E%3C/svg%3E");
								background-position: center;
								background-repeat: no-repeat;
								content: '';
								vertical-align: middle;
							}
						}

						.gear {
							display: inline-block;
							float: right;
							width: 25px;
							height: 25px;
							background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%3Cpath%20fill%3D%22%23212121%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10.238%205.754v-1.27H8.885c-.085-.592-.34-1.1-.677-1.607l.76-.762-.845-.846-.846.76c-.423-.338-.93-.592-1.523-.676V0h-1.27v1.354c-.592.084-1.1.338-1.607.677l-.762-.76-.846.845.76.762c-.338.508-.592%201.015-.676%201.608H0v1.27h1.354c.084.59.338%201.1.677%201.522l-.76.846.93.93.762-.76c.423.338%201.015.592%201.607.676v1.353h1.268V8.97c.593-.085%201.1-.34%201.608-.678l.762.762.93-.93-.93-.847c.338-.423.507-1.015.677-1.608h1.353v.084zm-7.446-.592c0-1.27%201.016-2.37%202.37-2.37%201.27%200%202.37%201.016%202.37%202.37%200%201.27-1.017%202.37-2.37%202.37-1.354-.086-2.37-1.1-2.37-2.37z%22/%3E%3C/svg%3E");
							background-position: center;
							background-repeat: no-repeat;
							vertical-align: middle;
							opacity: .6;
							cursor: pointer;
							-webkit-transition: opacity .3s ease;
							-o-transition: opacity .3s ease;
							transition: opacity .3s ease;

							&:hover {
								color: #212121;
								opacity: 1;
							}
						}

						.reset-filter {
							position: absolute;
							top: -1px;
							left: 0;
							width: 160px;
							pointer-events: auto;
							color: #80868e;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
							-webkit-transition: opacity .3s ease;
							-o-transition: opacity .3s ease;
							transition: opacity .3s ease;
							overflow: hidden;
							white-space: nowrap;
							opacity: 0;
						}
					}

					.buttons-container {
						position: absolute;
						right: 23px;
						bottom: 15px;
						width: 516px;
						border-top: 1px solid #f2f3f4;
						text-align: center;

						.buttons-inner {
							position: relative;
							margin: 15px 0 0 0;

							.search-button{
								padding-right: 20px;
								border-radius: 2px 0 0 2px;
								max-width: 100%;
								position: relative;
								display: -webkit-inline-box;
								display: -ms-inline-flexbox;
								display: inline-flex;
								-ms-flex-pack: center;
								-webkit-box-pack: center;
								justify-content: center;
								-ms-flex-align: center;
								-webkit-box-align: center;
								align-items: center;
								padding: 0 12px;
								color: #fff;
								vertical-align: middle;
								text-align: center;
								text-decoration: none;
								text-transform: uppercase;
								white-space: nowrap;
								font-size: 12px/37px;
								-webkit-user-select: none;
								-moz-user-select: none;
								-ms-user-select: none;
								user-select: none;
								box-sizing: border-box;
								margin: 0;
								height: 39px;
								outline: none;
								border: 1px solid #868d95;
								border-color: #64b2d1;
								background-color: #64b2d1;
								cursor: pointer;
								transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;

								//&::before {
									//background: url('/assets/images/search.svg');
									//padding-left: 44px;
								//}

								&:hover {
									background: #3eddff;
								}

								img {
									padding-left: 0px;
									padding-right: 6px;
								}

							}
							.reset-button{
								padding-right: 20px;
								border-radius: 2px 0 0 2px;
								max-width: 100%;
								position: relative;
								display: -webkit-inline-box;
								display: -ms-inline-flexbox;
								display: inline-flex;
								-ms-flex-pack: center;
								-webkit-box-pack: center;
								justify-content: center;
								-ms-flex-align: center;
								-webkit-box-align: center;
								align-items: center;
								padding: 0 20px;
								vertical-align: middle;
								text-align: center;
								text-decoration: none;
								text-transform: uppercase;
								white-space: nowrap;
								font-size: 12px/37px;
								-webkit-user-select: none;
								-moz-user-select: none;
								-ms-user-select: none;
								user-select: none;
								box-sizing: border-box;
								margin: 0;
								height: 39px;
								outline: none;
								border: 1px solid #868d95;
								border-color: c6cdd3;
								background-color: transparent;
								cursor: pointer;
								transition: 160ms background-color linear, 160ms color linear, 160ms opacity linear, 160ms border-color linear;
								margin-left: 12px;

								&:hover {
									background: #cfd4d8;
									border-color: #c6cdd3;
									color: #535b69;
								}

								&:active {
									background: #dde2e5;
									border-color: #9fa4ab;
									color: #535b69;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width:1023px)  {

.main-header {
	min-height: 45px;
	margin-top: -10px;
	.main-header-container {
		//padding: 0 18px 0 13px;
		min-height: 45px;
		.header-title {
			margin: 0 45px 0 0;
			padding: 15px 0 15px 0;

	    margin: 0 15px 0 0 !important;
			.header-title-name {
				padding: 0 5px 0 0;
				width:100%!important;
			}
			.header-title-star {
				background-size: 15px auto;
				width: 15px;
				min-width: 15px;
				max-width: 15px;
		    height: 14px;
			}
		}
		.header-filter-search-flexible {
			.header-filter-search {
				height: 39px;
				border-radius: 10px;
    		min-width: 150px;
		    margin: auto 15px auto 0;
		    width: 80%;
				float: left;
				.search-input {
					margin: 4px 1px;
					padding: 0 20px 0 8px;
					width: 100%
					height: 30px;
				}
				.inner-search {
					position: relative;
					margin: 3px 4px 4px 4px;
			    float: left;
			    padding: 0 20px 0 13px;
					height: 31px;
					//&:last-child {
						&::after {
							top: -3px;
							right: -17px;
							width: 14px;
							height: 36px;
						}
					//}
					.text {
						max-width: 250px;

						margin-top:5px
					}
					.cross {
						width: 25px;
					}
				}
				.search-cross {
					top: 0;
					right: 3px;
					padding: 0 5px;
					height: 39px;
					width: 60px;
				 .search-icon {
					 width: 25px;
					 height: 39px;
					 right: 30px;
				 }
				 .cross-icon {
					 left: 15px;
				   width: 20px;
				 }
			  }
			}
		}
		}
	}
}



@media (max-width:650px)  {
	.table-scroll {

		td {
			min-width: 100%;
			padding:0 20px
		}
	}

.modified-table th {
     display: block;
	 width:100%
	 text-align:center

  }

  .modified-table td {
     display: block;
		 text-align: right;
		 width:100%
		 min-width: 30px;
		 position: relative;
		margin-bottom: 0.5rem;
		 p {
		 	font-weight: bold;
			font-size: 14px !important;
			justify-content: flex-end;

			a {
				font-size: 0.7em !important;
			}
		 }
  }


  .modified-table thead {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
  }
   .modified-table td::before {
    content: attr(data-label);
    text-transform: uppercase;
	position: absolute;
    left: 5%;
  }

  .modified-table td:last-child {
    border-bottom: 0;
		margin-bottom: 20px;
  }

	.modified-table td:first-child {
		img{
			margin-right:5%
		}
  }

	.main-header {
		min-height: 30px;
		margin-top: -10px;
		.main-header-container {
			//padding: 0 18px 0 13px;
			min-height: 40px;
			.header-title {
				margin: 0 20px 0 0;
				padding: 8px 0 8px 0;
				//font: 16px/20px "OpenSans-Light",Helvetica,Arial,sans-serif;

		    margin: 0 5px 0 0 !important;
				.header-title-name {
					padding: 0 5px 0 0;
					//font: 12px/12px "OpenSans-Light",Helvetica,Arial,sans-serif;
					width:100%!important;
				}
				.header-title-star {
					background-size: 15px auto;
					width: 15px;
					min-width: 15px;
					max-width: 15px;
					display: inline-block;
			    height: 14px;
				}
			}
			.header-filter-search-flexible {
				.header-filter-search {
					height: 39px;
					border-radius: 10px;
					//font: 8px/15px "Helvetica Neue",Arial,Helvetica,sans-serif;
	    		min-width: 150px;
			    margin: auto 15px auto 0;
			    width: 80%;
					float: left;
					.search-input {
						margin: 4px 1px;
						padding: 0 20px 0 8px;
						width: 100%
						height: 30px;
						//font: 10px/20px "Helvetica Neue",Arial,Helvetica,sans-serif;
					}
					.inner-search {
						position: relative;
						-webkit-box-flex: 0;
						-ms-flex-positive: 0;
						flex-grow: 0;
						-ms-flex-preferred-size: min-content;
						flex-basis: min-content;
						margin: 3px 4px 4px 4px;
						display: inline-block;
				    float: left;
				    padding: 0 20px 0 13px;
						z-index: 3;
				    overflow: visible;
				    background: #bcedfc;
						height: 31px;
						white-space: nowrap;
						outline: 0;
						border-radius: 6px;
						//&:last-child {
							&::after {
								cursor: pointer;
								position: absolute;
								top: -3px;
								right: -17px;
								display: inline-block;
								width: 14px;
								height: 36px;
								content: '';
								text-align: center;
								//font: 10px/26px 'OpenSans-Semibold',"Helvetica Neue",Arial,Helvetica,sans-serif;
							}
						//}
						.text {
							display: inline-block;
							max-width: 250px;
							color: rgba(108,109,138,0.8);
							white-space: nowrap;
							overflow: hidden;
							//font: 9px/20px "Helvetica Neue",Arial,Helvetica,sans-serif;
							margin-top:5px
						}
						.cross {
							cursor: pointer;
							width: 25px;
							position: absolute;
					    right: 0;
					    top: 0;
							background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2210%22%20height%3D%2210%22%20viewBox%3D%220%200%2010%2010%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M5.065%203.682L2.377.994%201.01%202.362%203.696%205.05.99%207.757l1.368%201.37%202.708-2.71%202.7%202.703%201.37-1.368-2.702-2.7%202.682-2.684L7.748%201%205.065%203.682z%22/%3E%3C/svg%3E");
							background-repeat: no-repeat;
							background-position: center;
						}
					}
					.search-cross {
						position: absolute;
						top: 0;
						right: 3px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						padding: 0 5px;
						height: 39px;
						width: 60px;
						box-sizing: border-box;
					 .search-icon {
			 			 cursor: pointer;
						 position: absolute;
						 z-index: 1;
						 width: 25px;
						 height: 39px;
						 background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2215%22%20height%3D%2215%22%20viewBox%3D%220%200%2015%2015%22%3E%3Cpath%20fill%3D%22%23535C68%22%20fill-rule%3D%22evenodd%22%20d%3D%22M1.33%205.5c0-2.304%201.866-4.17%204.17-4.17S9.67%203.195%209.67%205.5c0%202.305-1.865%204.17-4.17%204.17-2.305%200-4.17-1.865-4.17-4.17m12.528%206.944l-3.816-3.817-.014-.008C10.64%207.73%2011%206.66%2011%205.5%2011%202.462%208.537%200%205.5%200S0%202.462%200%205.5%202.463%2011%205.5%2011c1.158%200%202.232-.36%203.118-.972.004.004.005.01.01.014l3.816%203.817c.372.37.982.37%201.354%200l.06-.063c.372-.372.372-.982%200-1.354%22/%3E%3C/svg%3E");
						 background-position: center 12px;
						 background-repeat: no-repeat;
						 opacity: .3;
						 right: 30px;
						 transition: all 150ms;
					 }
					 .cross-icon {
			 			 cursor: pointer;
						 position: relative;
						 left: 15px;
					   width: 20px;
					   background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212%22%20height%3D%2211%22%20viewBox%3D%220%200%2012%2011%22%3E%3Cpath%20fill%3D%22%23535C69%22%20fill-rule%3D%22evenodd%22%20d%3D%22M7.587%205.503l4.4-4.398-1.132-1.13-4.4%204.397L2.06-.026l-1.13%201.13%204.397%204.4L.927%209.9l1.13%201.132%204.4-4.398%204.398%204.398%201.13-1.13-4.398-4.4z%22/%3E%3C/svg%3E");
					   background-repeat: no-repeat;
					   background-position: center 14px;
					   -webkit-transition: right .3s,opacity .3s;
					   -o-transition: right .3s,opacity .3s;
					   transition: right .3s,opacity .3s;
					   opacity: .3;
					 }
				  }
				}
			}
			.header-list-type-toggle {
				margin-left: auto;
				display: -webkit-box;
				display: -ms-flexbox;
				//display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				margin-right: 10px;
				height: 25px;
				border: 1px solid #d3d3d3;
				border-radius: 8px;

				div {
					//width: 75px;
					width: 52px;
					//padding: 10px 7px;
					padding: 3px 7px;
					text-align: center;
					height: 100%;
					//font-size: 13px;
					font-size: 12px;
					border-radius: 0 6px 6px 0;
					cursor: pointer;

					&:hover {
			      background: #7bdaf780;
			    }

					&:not(:last-child) {
						border-right: 1px solid #d3d3d3;
						border-radius: 6px 0 0 6px;
					}

					&.active-list-type {
						background-color: #64b2d1;
						border: 1px solid #64b2d1;
						color: #fff;
						font-weight: 600;

						&:hover {
							background: #3eddff;
							border: 1px solid #3eddff;
						}
					}
				}

				img {
					width: 28px;
					cursor: pointer;

					&.active-list-type {
						border: 1px solid turquoise;
						padding: 2px;
					}
				}
			}
			.header-right-buttons {

				.settings-button {
					//border: 1px solid #64b2d1;
					//height: 33px;
			    //min-width: 0;
			    width: 33px;
			    //font-size: 0;
					line-height: calc(39px - 20px);
					margin: 0;
					height: 29px;

					//&::after {
					//left: 50%;
					//top: 50%;
					//margin: -12px 0 0 -12px;
					//}

				}
				.header-right-right-buttons {
					margin: 0 0 0 12px;
			    //padding-right: 30px;
					line-height: calc(39px - 2px);

					.add-button {
						padding-right: 8px;
						//height: 29px;
						height: 25px;
						max-width: 80%;

				    padding: 0 10px;

				    font-size: 10px/27px;

					}

					}
				}
			}
		}
	}


	@media (max-width:778px) {
		.header-list-type-toggle {
			display: none !important;
		}
	}


.important-notification {
  height: 16px;
  display: inline-block !important;
  vertical-align: middle;
  margin: 0 5px !important;
}


.stage-label {
	font-size: 11px !important;
	padding: 2px 0px !important;
}
