.search-field-container {
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.stage-tab-default-V3 {
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  padding: 6px 5px;
  border: 1px solid #dfdfdf;
  justify-content: space-around;
  min-width: 145px;
}
.stage-tab-default-V3-active {
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  padding: 6px 5px;
  justify-content: space-around;
  min-width: 145px;
  border: 1px solid #002c5f;
  background: rgba(145,199,231,0.071) !important;
  color: #002c5f;
}
.stage-tab-active-V3 {
  background: #f2f2f2;
}
.stage-tabs-animation {
  opacity: 0;
  transform: translateX(-20px); /* Initial translateX to start slightly off-screen */
  animation: fadeInAndSlide 0.8s cubic-bezier(0.52, -0.11, 0.47, 1.2) forwards;
}
@keyframes fadeInAndSlide {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.pill-new, .pill-sticky, .pill-sticky-active {
  border: 1px solid #002c5f !important;
  background: rgba(145,199,231,0.071) !important;
  color: #002c5f !important;
}

.pill-sticky, .pill-sticky-active {
  position: sticky;
  right: 0;
  margin-left: 40px;
  z-index: 1;
  background: white !important;
}
.pill-sticky-active {
  background: #002c5f !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.filter-sticky-pill:hover {
  background-color: #eee!important;
  border-radius: var(--filter-pill-border-radius);
}
.filter-new-pill:hover {
  background-color: #eee!important;
  border-radius: var(--filter-pill-border-radius);
}
.stage-tabs-animation:hover {
  background-color: #eee!important;
  border-radius: var(--stage-tabs-border-radius);
}
