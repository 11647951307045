.case-detail-accordion-container {
	background: #fafbfc;
	//border: 1px solid #eddbdb;
	//border-left: 5px solid #f9d901;
	border-radius: 8px;
	//padding-left: 8px !important;
}

.show-triggered-task-button {
  color: #87909e;
  font-weight: 400;
  background: transparent;
  transition: all 0.2s linear;

  &:hover {
   background: #bcc0c74d;
  }
}

.enquiry-type-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
	position: absolute;
	top: 20px;
  right: 35px;
}

.task-floating-button, .view-deal-floating-button, .view-timeline-floating-button {
	position: absolute;
	//box-shadow: 0px 2px 5px #666;
	cursor: pointer;
	width: max-content;
	height: 22px;
	padding: 4px;
	//background: #408b8b;
	border-radius: 3px;
	//color: #fff;
	font-size: 11px;
}

.task-floating-button {
	bottom: 20px;
	right: 65px;
	z-index: 2;
}
.view-deal-floating-button {
	bottom: 0px;
	right: 110px;
	z-index: 4;
}
.view-timeline-floating-button {
	bottom: 0px;
	right: 20px;
	z-index: 4;
}

// {
//	cursor: pointer;
//	width: max-content;
//	background: #408b8b;
//	color: #fff;
//	padding: 4px;
//	border-radius: 4px;
//}



.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}
.checkbox-toggle {
  position: relative !important;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
	left: 0 !important;
}
.button-cover,
.knobs,
.layer {
  position: absolute;
  //top: 0;
	top: -1px;
  right: 0;
  bottom: 0;
  left: 0;
}
.button-toggle {
  position: relative;
  top: 68%;
  width: 120px;
  height: 29.5px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}
.button-toggle.b2 {
  border-radius: 2px;
	border: 0.5px solid #d3d3d3;
}
.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 10 */
#button-10 .knobs:before,
#button-10 .knobs:after,
#button-10 .knobs span {
  position: absolute;
  top: 4px;
  width: 40px;
  height: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 6.5px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-10 .knobs:before {
  content: "";
  left: 4px;
  height: 21.5px;
  background-color: #03a9f4;
}

#button-10 .knobs:after {
  content: "FOLLOW UP";
  right: 4px;
  color: #4e4e4e;
	/*
		Modification: Updated width from 64 to 66px due to font-change
		By: Devang
		Date: 12/04/2023
	*/
	width: 66px;
}

#button-10 .knobs span {
  display: inline-block;
  left: 4px;
  color: #fff;
  z-index: 1;
}

#button-10 .checkbox-toggle:checked + .knobs span {
  color: #4e4e4e;
}

#button-10 .checkbox-toggle:checked + .knobs:before {
	/*
		Modification: Updated left from 51 to 49px and width from 62px to 64px due to font-change
		By: Devang
		Date: 12/04/2023
	*/
	left: 49px;
	height: 21.5px;
	background-color: #f44336;
	width: 64px;
}

#button-10 .checkbox-toggle:checked + .knobs:after {
  color: #fff;
}

#button-10 .checkbox-toggle:checked ~ .layer {
  background-color: #fcebeb;
}

/* Button 2 */
#button-2 .knobs:before,
#button-2 .knobs:after,
#button-2 .knobs span {
  position: absolute;
  top: 4px;
  width: 40px;
  height: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 6.5px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

#button-2 .knobs:before {
  content: "";
  left: 4px;
  height: 21.5px;
  background-color: #03a9f4;
}

#button-2 .knobs:after {
  content: "Actions";
  right: 4px;
  color: #4e4e4e;
	width: 64px;
}

#button-2 .knobs span {
  display: inline-block;
  left: 4px;
  color: #fff;
  z-index: 1;
}

#button-2 .checkbox-toggle:checked + .knobs span {
  color: #4e4e4e;
}

#button-2 .checkbox-toggle:checked + .knobs:before {
	left: 51px;
	height: 21.5px;
	background-color: #f44336;
	width: 62px
}

#button-2 .checkbox-toggle:checked + .knobs:after {
  color: #fff;
}

#button-2 .checkbox-toggle:checked ~ .layer {
  background-color: #fcebeb;
}


.case-stages-container:after {
  content: attr(data-label);
  position: absolute;
  top: -7.5px;
  left: 24px;
  background: attr(data-color);
  color: #696d76;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}

/*modified by Arun Singh
   modifield on 16/08/2023
   modification: Add left 0px for mobile view
*/
.case-stages-container-mobile-view:after {
  content: attr(data-label);
  position: absolute;
  top: 33px;
  left: 0px;
  background: attr(data-color);
  color: #696d76;
  font-size: 10px;
  padding-right: 5px;
  padding-left: 2px;
}

.quick-action-category {
	background: #f1f1f1;
	color: #2a2e34;

	&:hover {
		background: #fff;
	}
}
.disabled-quick-action-category {
	color: #adb3bd;
}
.active-quick-action-category {
	background: #808080 !important;
	color: #fff;
}

/*
  modified : Masum Raja
  modified : 30/04/2024
  modification : Add classes for the stamp of incomplete and complete.
*/
.case-completion-container {
  left:23%;
  top: 46px;
	z-index: 1;
	border-radius: 50%;
	position: absolute;
}
