.leftInfoPanel-tab {
  width: 65px;
  text-align: center;
  position: relative;
  //padding-top: 10px;
  height: 100vh;
  //box-shadow: rgb(189 191 193 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px;
  //box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px;
  //box-shadow: #d4d8db 6px 2px 16px 0px, #c7c7c2 -6px -2px 16px 0px;
  // Modification: Replaced color with theme color and commented the previous color
  // BY: Devang
  // Date: 22/02/2023
  //background:#8aceeb
  background: linear-gradient(180deg, #79C6C1 0%, #2E8994 0.01%, #002C5F 87.5%);

  .leftInfoPanel-tab-container {
    width: 55px;
    padding: 10px 7px 7px 7px;
    border-bottom: 1px solid rgba(0,0,0,0.051);
  }

  // Modification: added linear-gradient background to the leftInfoPanel-tab
  // BY: Vipin
  // Date: 28/03/2023

  .leftInfoPanel-logout {
    position: absolute;
    bottom: 17px;
    left: 14px;
  }
  .leftInfoPanel-menu-icon {
    //top: 45px !important;
    position: relative;
    padding: 15px 0px 8px 0px;
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //width: 42px;
    //margin-left: 12px;
    height: 65px;

  }
  .leftInfoPanel-menu-icon-active {
    //top: 50px !important;
    position: relative;
    padding: 15px 0px 8px 0px;
    //border-bottom: 1px solid rgba(0,0,0,0.051);
    cursor: pointer;
    //border-left: 4px solid #fff;
    //border-radius: 22px;
    //height: 30px;
    //margin-bottom: 2px;
    //margin-left: 12px;
    //position: relative;
    //width: 42px;
    height: 65px;
    background-color: #ffffffb8 !important;

    // Modification: Replaced color with theme color and commented the previous color on active tab
    // BY: Devang
    // Date: 22/02/2023
    .menu-name {
      color: var(--theme-default-colour);
    }
  }
  .menu-name {
    // Modification: Replaced color with white color and commented the previous color
    // BY: Devang
    // Date: 22/02/2023
    //color: black;
    color: white;
    font-size: 0.65rem;
    //margin-top: -5px;
    margin-bottom: 3px;
  }

}
.leftInfoPanel-cards, .leftInfoPanel-cards-hover {
  display: flex;
  position: relative;
  //box-shadow: 0px 1px 3px 1px #e5e5e5;
  border-radius: 5px;
  margin-top: 10px;
  flex-direction: column;
  //background: #fff;
  padding: 5px;
  span {
    font-weight:400 !important;
  }
}

.leftInfoPanel-cards-hover {
  cursor: pointer;
  &:hover {
    box-shadow: -2px 7px 3px 1px #e5e5e5;
  }
}
.counter-span {
  position: absolute;
  right: 0;
  top: 0;
}
.menu-count {
  min-width: 8px;
  text-align: center;
  background-color: #b3261e;
  background-clip: padding-box;
  border: 1px solid #b3261e;
  color: #fff;
  font-size: .6875rem;
  margin: 0;
  text-shadow: none;
  top: 2px;
  right: 2px;
  font-size: .75rem;
  letter-spacing: normal;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 8px;
  padding: 1px 4px;
  position: relative;
  z-index: 1;
}

.notify-span {
  //display: inline-block;
  z-index: 1;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  position: absolute;
  background-color: #c11e1efa;
  top: 6px;
  right: 14px;
}

.notify-span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  border-radius: 50%;
  //z-index: -1;
  animation: ripple 1.5s ease-out infinite;
  animation-delay: 0.2s;
  top: 0px;
  right: 0px;
}



@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
