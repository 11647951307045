@import "minicss"
@import "orgChart"
@import "materializeCSS"

:root {
	--tenant-color: #2E8994;
	--tenant-color-opacity1: #62A6AF;
	--tenant-color-opacity2: #CBE1E4;
	--tenant-color-secondary: #002c5f;
	--tenant-color-tertiary: #79c6c1;
	--text-white: #fff;
	--roboto-font: 'Roboto';
	--poppins-font: 'Poppins';
	--plyr-video-control-color: var(--text-white);
	--button-primary: #62A6AF;
	--button-primary-hover: var(--tenant-color);
	--button-disabled-primary: #dfdfdf;
	--button-disabled-primary-hover: #06141fa3;
	--button-secondary: #fff;
	--button-secondary-hover: var(--tenant-color);
	--text-input-border: #06141f;
	--text-disabled: #808080;
	--text-primary: grey;
	--text-secondary: #4b4d51;
	--text-grey: grey;
	//--text-input: #4949ed;
	--text-input: #131165;
	--page-header-text-color: #06141f;
	--page-sub-header-text-color: #708090;
	--modal-header-text-color: #4b4d51;
	--field-header-text-color: #4b4d51;
	--field-value-text-color: #4b4d51;
	--selected-bacground: #ceecea;
	//--card-primary-color: #8ecae6;
	--card-primary-color: #444;
	--card-primary-header: #fff;
	--card-primary-count: #fff;
	--card-secondary-color: #e2e2e2;
	--card-secondary-header: #06141f;
	--card-secondary-count: #8ecae6;
	--text-green: #008000;
	--mdc-theme-primary: var(--button-primary);

	//Modification: Change the form wizard field colour from blue to something more visible
	//By: Devang
	//Date: 29/05/2023
	//--input-focus: #cee2f14f;
	--input-focus: #cee2f1b3;

	--case-theme: #ddf0f3;
	--theme-default-colour: #06141f;
	--theme-default-colour-opacity: #06141f;

	//Modification: Three dots text-fill-color
	//By: Arun
	//Date: 07/08/2023

  --three-dots-text-fill-color: #242424;

}

@font-face {
  font-family: "Roboto";
  src: url('/assets/static/fonts/Roboto-Light.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

::-webkit-scrollbar {
		width: 7px;
		height: 7px;
		background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar:hover {
		width: 12px;
		height: 12px;
		background-color: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		//box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
		box-shadow: inset 0 0 0 20px #808080;
		border-radius: 20px;
}

::-webkit-scrollbar-thumb:active {
		//box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
		box-shadow: inset 0 0 0 20px #3c3c3c;
		border-radius: 20px;
}

{/*
	Modified by: Arun Singh
	Modified on: 12/12/2023
	COMP_BG_534(2):2.The hand pointer is not showing up dates and dropdowns for clickable area (This issue persists through many areas of the app)
*/}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
	box-sizing: border-box;
}

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FFF;
	font-family: var(--poppins-font), sans-serif;
	font-weight: 400;
	color: var(--text-primary);
	//overflow-y: auto;
	//overflow-x: hidden;
	overflow: hidden;
	letter-spacing: 0;
	line-height: 1.5;
}

/*
		modified by Vipin
		modified at 03/04/2023
		modification : added Poppins font-family to html and body
*/

body, button, input, optgroup, select, textarea {
	font-family:  var(--poppins-font), sans-serif;
}
.textarea-height {
  width: auto;
  height: 65px !important;
	border: 1px solid #ed553b8f;
}

label, legend {
  display: block;
  align-items: baseline;
  font-size: var(--field-header-text-size);
	color: var(--field-header-text-color);
  font-weight: 500;
}

form label {
	font-size: var(--field-header-text-size);
	color: var(--field-header-text-color);
}

input[role=combobox],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='date'],
input[type='month'],
input[type='month' i],
input[type='time'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='file'],
textarea,
select {
	background-color: transparent;
	//border: 0.1rem solid #d1d1d1;
	//border-radius: 0.4rem;
	border: none;
	border-bottom: 2px solid #9e9e9e;
	box-shadow: none;
	box-sizing: inherit;
	padding: 0 0.3rem;
	width: 100%;
	letter-spacing: 0;
	margin-bottom: 3px !important;
	//color: var(--text-grey);
	//font-weight: normal;
	//font-size: 1.4rem;
	font-weight: 300;
	color: var(--text-input) !important;
	transition: all .3s;
	&::placeholder {
		color: var(--text-grey);
	}
	&::focus {
    border-bottom: 2px solid var(--text-input-border);
	}
	&::hover {
    cursor: text;
	}
	&::selected {
    background-color: var(--button-primary);
    color: var(--text-white);;
	}
}

// Modification: Added border input style class
// By: Manohar
// Date: 31/05/2023

.borderInput {
	input[role=combobox],
	input[type='email'],
	input[type='number'],
	input[type='password'],
	input[type='search'],
	input[type='date'],
	input[type='month'],
	input[type='month' i],
	input[type='time'],
	input[type='tel'],
	input[type='text'],
	input[type='url'],
	input[type='file'],
	textarea,
	select {
		background-color: transparent;
		border: 0.1rem solid #d1d1d1;
		border-radius: 0.4rem;
		//border: none;
		//border-bottom: 2px solid #9e9e9e;
		box-shadow: none;
		box-sizing: inherit;
		padding: 0 0.3rem;
		width: 100%;
		letter-spacing: 0;
		margin-bottom: 3px !important;
		//color: var(--text-grey);
		//font-weight: normal;
		//font-size: 1.4rem;
		font-weight: 200;
		color: var(--text-input) !important;
		transition: all .3s;
		&::placeholder {
			color: var(--text-grey);
		}
		&::focus {
			border: 2px solid ;
		}
		&::hover {
			cursor: text;
		}
		&::selected {
			background-color: var(--button-primary);
			color: var(--text-white);;
		}
	}
	input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
	    border: 2px solid var(--text-input-border);
	    //box-shadow: 0 1px 0 0 var(--text-input-border);
	}
}


.follow-up select {
	background-color: transparent;
	//border: 0.1rem solid #d1d1d1;
	//border-radius: 0.4rem;
	border: none;
	border-bottom: 2px solid #ed553b8f;
	box-shadow: none;
	box-sizing: inherit;
	padding: 0 0.3rem;
	width: 100%;
	letter-spacing: 0;
	margin-bottom: 3px !important;
	//color: var(--text-grey);
	//font-weight: normal;
	//font-size: 1.4rem;
	font-weight: 200;
	color: var(--text-grey) !important;
	transition: all .3s;
	&::placeholder {
		color: var(--text-grey);
	}
	&::focus {
    border-bottom: 2px solid var(--text-input-border);
	}
	&::hover {
    cursor: text;
	}
	&::selected {
    background-color: var(--button-primary);
    color: green;
	}
}

p {
	margin: 0;
}

#app {
	height: 100%;
	background-color:	#FFF;
	width:	100%;
	min-height:	100vh;
	overflow-x: hidden;
}

.mainBodyContainer {
	width: 100%;
	margin: 0px auto;
	padding: 0 0 0 0;
	background: var(--theme-default-colour);
	position: relative;
}

button {
	min-width: 75px;
	border-radius: 5px;
	padding: 6px;
	height: fit-content;
	cursor: pointer;
	font-weight: 500;
	border: none;
}

/* [Button Styling]
--------------------------------------------------------------------------------
*/
.primary-button {
	background-color: var(--button-primary);
	color: var(--text-white);
	//background-color: var(--text-white);
	//color: var(--button-primary);
	//border: 1px solid var(--button-primary);
	//background-color: #eff1f2;
  //background-color: #eff1f2;
  //color: #000;
	border: none;

	svg {
		//fill: var(--button-primary);
		fill: var(--button-primary);
		path {
			//fill: var(--button-primary);
			fill: var(--button-primary);
		}
	}
	&:hover {
		background-color: var(--button-primary-hover);
		color: var(--text-white);
		svg {
			fill: var(--text-white);
			path {
				fill: var(--text-white);
			}
		}
	}
}

.primary-button-2 {
  background-color: var(--text-white);;
  color: #000;
	border: 1px solid #526f95;
	border-radius: 5px !important;
	min-width: 48px;
	height: 24px;
	font-size: 12px;
	padding: 4px;
	padding-top: 2px;

	&:hover {
		color: var(--text-white);;
    background-color: #526F95;
	}
}

.primary-gradient-button {
	background: linear-gradient(90deg, var(--tenant-color-secondary) 1.67%, var(--tenant-color) 99.99%, var(--text-white) 100%);
	border-radius: 12px;
	color: var(--text-white);
	padding: 0px 4px 0px 4px;
	font-size: 12px;
	height: 24px;
	min-width: 80px;
}

.primary-button:disabled,
.primary-button[disabled],
.secondary-button:disabled,
.secondary-button[disabled]{
	border: 1px solid var(--button-disabled-primary);
	background-color: var(--button-disabled-primary);
	color: var(--text-disabled);
	cursor: not-allowed;
}

/*
		modified by Manohar
		modified at 28/05/2024
		Bug: T215- BG_FN - Transition from Disabled to Highlighted State on 'Reject' Button Hover
		modification : Add css for secondary-button : disabled
*/

.secondary-button:disabled,
.secondary-button[disabled]{
	border: 1px solid var(--button-disabled-primary);
	background-color: var(--button-disabled-primary);
	color: var(--text-disabled);
	cursor: not-allowed;
	&:hover {
		background-color: var(--button-disabled-primary);
		color: var(--text-disabled);
	}
}

.secondary-button {
	background-color: var(--button-secondary);
	color: var(--button-secondary-hover);
	border: 1px solid var(--button-secondary-hover);
	&:hover {
		background-color: var(--button-secondary-hover);
		color: var(--text-white);
	}
}

.new-button {
	font-size: 14px !important;
  padding-left: 16px;
  padding-right: 16px;
  height: 32px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 8px;
	background-color: #eff1f2;
  color: #000;
	border: none;
	svg {
		fill: var(--button-primary);
		path {
			fill: var(--button-primary);
		}
	}
	&:hover {
		background-color: var(--button-primary-hover);
		svg {
			fill: #eff1f2;
			path {
				fill: #eff1f2;
			}
		}
	}
}

/*
		modified by Masum Raja
		modified at 14/06/2023
		modification : Add button loading spinner
*/

.primary-border-button {
	position: relative !important;
	border: 1px solid var(--button-primary);
	background-color: var(--text-white);
	color: var(--button-primary);

	svg {
		fill: var(--button-primary);
		path {
			fill: var(--button-primary);
		}
	}
	&:not(.buttonLoader):hover {
		border-color: var(--button-primary);
		background-color: var(--button-primary);
		color: var(--text-white);

		svg {
			fill: var(--text-white);
			path {
				fill: var(--text-white);
			}
		}
	}
	&.buttonLoader {
		color: var (--button-primary) !important;
		background-color: var(--button-primary);

		svg {
			fill: var(--text-white) !important;
			path {
				fill: var(--text-white) !important;
			}
		}
	}

	&.buttonLoader:before {
		content: '';
		position: absolute !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 2px solid white;
		border-top-color: var(--button-primary);
		border-radius: 50%;
		width: 16px;
		height: 16px;
		animation: spin 1s linear infinite;
	}
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.selectedbackground {background: var(--selected-bacground);}

.uploaded-image {
	display: inline-block;
	//height: 100px;
	border-radius: 3px;
	vertical-align: top;
	position: relative;
	&:not(:first-child) {
		margin-left: 10px;
	}
	img {
		width: 100% !important;
		height: 100%;
	}
	input[type="file"] {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 1;
		cursor: pointer;
		&::-webkit-file-upload-button {
			cursor: pointer;
		}
		&::-moz-file-upload-button {
			cursor: pointer;
		}
	}
	p {
		text-align: center;
		//margin-top: -1px;
		font-size: 22px;
		color: #808080;
		position: absolute;
    left: 50%;
		top: 50%;
    transform: translate(-50%, -50%);
	}
	.delete-file-icon {
		position: absolute;
		top: -3px;
		background: rgba(0,0,0,0.6);
		color: var(--text-white);;
		border-radius: 50%;
		font-size: 12px;
		line-height: 1;
		padding: 0 2px 1px 3px;
		right: -4px;
		font-weight: 600;
		cursor: pointer;
		display: none;
		transform: none;
    left: auto;
	}
	&:hover .delete-file-icon {
		display: block;
	}
		//Modified by: Arun Singh
		//Modified on: 20/12/2023
		//COMP_BG_730: The cross icon is not visible by default for uploaded files

	.delete-file-icon-for-mobile {
			position: absolute;
			top: -3px;
			background: rgba(0,0,0,0.6);
			color: var(--text-white);;
			border-radius: 50%;
			font-size: 12px;
			line-height: 1;
			padding: 0 2px 1px 3px;
			right: -4px;
			font-weight: 600;
			cursor: pointer;
			transform: none;
			left: auto;
	}
}
.star-mandatory-entry {
	color:red !important;
	font-size:medium
	height:15px;
}

/*
		modified by Vihang
		modified at 13/05/2022
		modification : mobile bottom Navigation css
*/

/*
		modified by Manohar
		modified at 28/07/2023
		modification : mobile bottom Navigation hide transition css
*/

.mobile-bottom-nav-hide-transition {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	transform: translate3d(0, 100%, 0) !important;
	-webkit-transition: transform 0.2s ease-out;
	-o-transition: transform 0.2s ease-out;
	-moz-transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	display: flex;

	height: 50px;
	//border-top: 1px solid lightgrey;
	box-shadow: 0 -2px 2px -2px lightgrey;
	background-color: #fff;
}

/*
		modified by Arun Singh
		modified at 09/10/2023
		modification : Add height,	text-align & justify-content CSS for mobile bottom navbar
*/

.mobile-bottom-nav{
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	z-index:1000;
  user-select: none;
	//give nav it's own compsite layer
	will-change:transform;
	transform: translateZ(0);

	display:flex;

	height:55px;
	text-align: center;
	//border-top: 1px solid lightgrey;
	box-shadow: 0 -2px 2px -2px lightgrey;
	background-color:#fff;
	justify-content: space-between;

	&__item{
		flex-grow:1;
		text-align:center;
		font-size:12px;

		display:flex;
		flex-direction:column;
		justify-content:center;
	}
	&__item--active{
		//dev
		color:red;
	}
	&__item-content{
		display:flex;
		flex-direction:column;
	}
}
.chosen-value,
.value-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.chosen-value {
  font-family: 'Ek Mukta';
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 4px;
  height: 4rem;
  font-size: 1.1rem;
  padding: 1rem;
  background-color: #FAFCFD;
  border: 3px solid transparent;
  transition: .3s ease-in-out;

  &::-webkit-input-placeholder {
    color: #333;
  }

  &:hover {
    background-color: #FF908B;
    cursor: pointer;

    &::-webkit-input-placeholder {
      color: #333;
    }
  }

  &:focus,
  &.open {
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.2);
    outline: 0;
    background-color: #FF908B;
    color: #000;

    &::-webkit-input-placeholder {
      color: #000;
    }
  }
}

.value-list {
  list-style: none;
  margin-top: 2rem;
  box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.2);
  overflow: auto;
  //max-height: 0;
  transition: .3s ease-in-out;
	height: 100px;
	z-index: 999999;

  &.open {
   max-height: 320px;
   overflow: auto;
  }

  li {
    position: relative;
    height: 1rem;
    background-color: #FAFCFD;
    padding: 1rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color .3s;
    opacity: 1;

    &:hover {
      background-color: #FF908B;
    }

    &.closed {
      max-height: 0;
      overflow: hidden;
      padding: 0;
      opacity: 0;
    }
  }
}

.listCard.is-loading {
  .msgContainer {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

@keyframes ldio-huzgndzb6dc {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}
.ldio-huzgndzb6dc div {
  position: absolute;
  animation: ldio-huzgndzb6dc 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #e15b64;
  transform-origin: 80px 82px;
}
.loadingio-spinner-eclipse-55z59xbhxwt {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: 43%;
  left: 25%;
}
.ldio-huzgndzb6dc {
  width: 50%;
  height: 50%;
  position: relative;
  transform: translateZ(0) scale(0.5);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-huzgndzb6dc div { box-sizing: content-box; }
.input-like-header {
	font-size: 1.4rem;
	font-weight: 200;
	color: var(--text-input);
}
.list-card-value {
	font-size: 1.4rem;
	font-weight: 200;
	color: var(--text-input);
}
.listcard-modal-label {
	color: var(--button-primary);
	border-bottom: 1px solid;
}
.tooltip {
		background: #408b8b;
    bottom: 200%;
    color: var(--text-white);;
    display: block;
    /* margin-bottom: 15px; */
    opacity: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    width: max-content;
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    transition: all 0.25s ease-out;
    -ms-box-shadow: 2px 2px 6px rgba(0,0,0,0.28);
    -o-box-shadow: 2px 2px 6px rgba(0,0,0,0.28);
    box-shadow: 0px 1px 4px -2px #001c02;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tooltip:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}

/* CSS Triangles - see Trevor's post */
.tooltip:after {
		border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #408b8b 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
}

.wrapper:hover .tooltip {
opacity: 1;
pointer-events: auto;
-webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
   -o-transform: translateY(0px);
    transform: translateY(0px);
}
/*
  Modified by Arun
  Modification on 15/sept/2023
  Modification Add classe for left tooltip
*/
.tooltip-left {
		background: #408b8b;
		font-weight: 400;
		font-size: 12px;
		/* bottom: 200%; */
		color: var(--text-white);
		display: block;
		/* margin-bottom: 15px; */
		opacity: 0;
		padding: 10px;
		pointer-events: none;
		position: absolute;
		width: max-content;
		-ms-transform: translateY(10px);
		/* transform: translateY(10px); */
		/* transition: all 0.25s ease-out; */
		-ms-box-shadow: 2px 2px 6px rgba(0,0,0,0.28);
		-o-box-shadow: 2px 2px 6px rgba(0,0,0,0.28);
		box-shadow: 0px 1px 4px -2px #001c02;
		left: -71px;
		top: -7px;
		right: 0;
}

/* This bridges the gap so you can mouse into the tooltip-left without it disappearing */
.tooltip-left:before {
		bottom: 0px;
		content: " ";
		display: block;
		height: 20px;
		left: 0px;
		position: absolute;
		width: 100%;
}

.tooltip-left:after {
		border-left: solid transparent 10px;
		border-bottom: solid #408b8b 10px;
		bottom: 16px;
		content: " ";
		height: 0;
		right: -4px;
		position: absolute;
		width: 0;
		transform: rotateZ(-45deg);
}

.wrapper:hover .tooltip-left {
		opacity: 1;
		pointer-events: auto;
		-webkit-transform: translateY(0px);
		  -moz-transform: translateY(0px);
		  -ms-transform: translateY(0px);
		   -o-transform: translateY(0px);
		    transform: translateY(0px);
}

.formLabel {
	text-transform: uppercase;
	font-weight: 400;
}

.tds-tab-o-container {
	display:flex;
}
.tds-tab-list {
	margin: 0;
    width: 100%;
    background-color: #f4f4f4;
	transition:background-color .3s ease,box-shadow .3s ease,color .3s ease;
	border-radius:100px;
	position: relative;
    display: flex;
    padding: 5px;
    z-index: 1;
    overflow-x: auto;
    border-radius: 100px;
}

.tds-tab-active {
	color: #000;
    background-color: var(--text-white);;
    border-radius: 100px;
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 #0000001a;
	transition:background-color .3s ease,box-shadow .3s ease,color .3s ease;
}

.goal-horizontal-scroll-container{
	width: 99.5%;
	height: 80px;
	overflow: hidden;
	margin: 0;
	position: relative;

	p {
		color: #e0e0e0;
	}

	.dotted-date-tracker{
		border: 1px dashed rgba(0, 0, 0, 0.2);
		width: 100%;
		position: absolute;
		bottom: 0;
		margin: 0 0 40px 0;
		height: 0px;
	}

	.date-pointer{
		position: absolute;
		width: 100%;
		bottom: 0;
		margin: 0 0 24px 0;
	}

	.base-width{
		width: 30px;
		text-align: center;
		text-align:center;
		justify-content: center;
		z-index:99;

		p {
			color: #000000;
			white-space: nowrap;
			font-size: 10px;
			visibility: hidden
		}

		.inner-date{
			color: #000000;
			white-space: nowrap;
			font-size: 10px;
		}
	}

	.date-list-tracker{
		position: absolute;

		width: 100%;
		bottom: 0;
		margin: 0 0 2px 0;
		float: left;
		direction: ltr;

		.start-end-date{
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			color: #000000;
		}

		.base-date{
			min-width: 40px;
			max-width: 40px;
		}
	}

	.triangle{
		/* Rectangle 544 */
	position: absolute;
	width: 17px;
	height: 20px;
	left: 417px;
	top: 269px;

	/* Dark Highlight */
	background: #F7D560;
	box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
	}
}

input:checked {
  + .tab-label {
    //background: darken(#fff, 10%);
		background: darken(#f0f8ff, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    //max-height: 100vh;
		max-height: 200vh;
    padding: 1em;
  }
	~ .tab-content-mobile {
    max-height: 200vh;
  }
}
.accordian-row {
	display: flex;
	.accordian {
		flex:1;
			&:last-child {
				margin-left: 1em;
			}
	}
}
.accordian-row.lead {
	overflow:	auto;
	padding:	5px;
}
.basic-table {
	thead th {
		padding: 0px 10px;
		width: 150px;
		background: #fff;
		font-weight: normal;
		color: #6f7782;
		font-size: 13px;
		height: 37px;
		text-align: left;
    border-right: 1px solid #cbd4db;
    border-top: 1px solid #cbd4db;
    border-left: transparent;
    border-bottom: transparent;
		&:hover {
			background-color: #e2e2e2;
			color: #000;
		}
	}
	tbody td {
		padding: 2px 10px;
		width: 150px;
		color: #000;
		font-size: 13px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 25px;
		vertical-align: middle;
		background: #fff;
		text-align: left;
    border-right: 1px solid #cbd4db;
    border-top: 1px solid #cbd4db;
    border-left: transparent;
    border-bottom: transparent;
		border: 1px solid rgb(203, 212, 219) !important;
		&:hover {
			background-color: #e2e2e2;
		}
	}
}

.tabs-tracker {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
}
.tab-tracker {
  width: 100%;
	//color: var(--text-white);;
	color: #f0f8ff;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    //background: #fff;
		background: #f0f8ff;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      //background: darken(#fff, 10%);
			background: darken(#f0f8ff, 10%);
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    //color: var(--text-white);;
    //background: white;
		color: #f0f8ff;
    background: #f0f8ff;
    transition: all .35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #fff;
    cursor: pointer;
    &:hover {
      //background: darken(#fff, 10%);
			background: darken(#f0f8ff, 10%);
    }
  }
	&-input {
	  position: absolute;
	  opacity: 0;
	  z-index: -1;
	}
	label {
		text-align: left !important;
		padding-left: 10px !important;
		color: #787878 !important;
	}
}

button.accordion {
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

button.accordion:after {
  font-family: FontAwesome;
  content: "\f150";
  font-family: "fontawesome";
  font-size: 18px;
  float: right;
}

button.accordion.is-open:after {
  content: "\f151";
}

button.accordion:hover,
button.accordion.is-open {
  background-color: #ddd;
}

.accordion-content {
  background-color: white;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.day-plan-workspace-table-card {
	width: 100vw;
	padding: 8px;
	table {
		width: auto;
		border-radius: 4px;
		background-color: var(--text-white);;
		tr, th, td {
			border: none;
			background: none;
		}
		th, td {
			padding: 4px 8px !important;
			height: inherit;
			vertical-align: bottom;
		}
		tr {
			border-bottom: 0.5px solid #c6c6c6;
			&:first-child {
					height: 4vh !important;
			}
		}
		tr.actionable-row {
			&:hover {
				background: #f1f1f1;
			}
		}
		tr.done-status {
			&:not(:first-child) {
				height: 3vh !important;
				&:hover {
					background-color: #d2dcd3;
					transition: all 0.2s ease-in-out;
				}
			}
			.status {
				background-color: #d2dcd3;
			}
		}
		tr.pending-status {
			font-weight: 500;
			&:not(:first-child) {
				height: 3vh !important;
				&:hover {
					background-color: #91bfbc;
					transition: all 0.2s ease-in-out;
				}
			}
			.status {
				background-color: #91bfbc;
			}
		}
		tr.not-triggered-status {
			pointer-events: none;
		  -webkit-touch-callout: none; /* iOS Safari */
		    -webkit-user-select: none; /* Safari */
		     -khtml-user-select: none; /* Konqueror HTML */
		       -moz-user-select: none; /* Old versions of Firefox */
		        -ms-user-select: none; /* Internet Explorer/Edge */
		            user-select: none; /* Non-prefixed version, currently
		                                  supported by Chrome, Edge, Opera and Firefox */
			opacity: 0.8;
		  filter: blur(1px);
		  pointer-events: none;
			background: #e1e1e1ec7;
		}
		tr.not-triggered-status td:before {
	    //content: " ";
	    position: absolute;
	    top: 50%;
	    left: 0;
	    border-bottom: 1px solid #111;
	    width: 100%;
		}
		tr.conclusion {
			background: #cacaca;
			font-weight: 600;
		}
		th {
			font-size: 13px !important;
			font-weight: 400;
			color: #000;
		}
		td {
			position: relative;
			font-size: 13px !important;
			font-weight: 400;
			color: #292d34;
		}
	}
	button {
		border-radius: 3px;
		padding: 2px 8px;
		height: auto;
		cursor: pointer;
		border: 0.5px solid #c6c6c6;
		color: #808080;
		min-width: auto;
		background-color: var(--text-white);;
		font-size: 12px;
		transition: all 0.2s ease-in-out;
	}
	button:hover {
		color: var(--text-white);;
		background-color: #808080;
	}
	select {
		margin: 0 !important;
		border-radius: 3px;
		cursor: pointer;
		font-size: 12px;
    height: auto;
    border: 1px solid #c6c6c6 !important;
		color: #808080 !important;
    padding: 2px;
		background-color: var(--text-white);;
		font-weight: 500;
	}
}

.empty-wrapper {
	.blur-box {
		opacity: 0.6;
		filter: blur(5px);
	  pointer-events: none;
	}
}

.stage-container {
    width:100%;
    display: flex;
    height:10px;
    border-radius:2px;
    margin-bottom:3px;
    border:1px solid rgba(0,0,0,.1);

    .stage {
        width:25%;
        height:100%;
        position: relative;
        cursor: pointer;

        &:not(:last-child) {
            border-right:1px solid rgba(0,0,0,0.1);
        }

        .inner-hover {
            border-top: 3px solid rgba(111,113,115,.39);
            border-bottom: 3px solid rgba(111,113,115,.39);
            display: none;
            height: 13px;
        left: 0px;
        top: -3px;
        right: 0;
        position: absolute;
        width: 100%;
        }

        &:hover .inner-hover {
            display: block;
        }
    }
}

.fold {
  position: relative;
  background: #fff;
}
/* folding */
.fold::before {
  content:"";
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 0 25px 25px 0;
  border-color: #ddd #ddf0f3;
  transition: all ease 0.5s;
}
.fold:hover::before {
  border-width: 0 40px 40px 0;
  border-color: #e2e2e2 #ddf0f3;
}

.disabled-div {
	pointer-events: none;
	opacity: 0.5;
}

.not-available {
	color: #b0b0b0;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.quick-actions-card:hover{
  box-shadow: 0 3px 5px #00000040, 0 2px 2px #00000038
}
#map {
 flex-basis: 0;
 flex-grow: 4;
 height: 100%;
}
.uploadFile-container {
	width: 100%;
	border: 1px solid #fff;
	border-right: none;
	display: flex;
	position: relative;
	.content-type-tag {
		position: absolute;
		top: -21px;
		left: 35px;
		font-size: 10px;
		color: var(--text-white);;
		font-weight: 600;
		background: #808080;
		border-radius: 4px 4px 0 0;
		padding: 3px 9px;
		text-align: center;
		width: 20%;
	}
	.questionnaire-container {
		width: 100%;
		padding: 25px;
		z-index: 10;
		.question-container {
			p {
				display: inline-block;
				&:first-child {
					font-weight: 600;
					font-size: 12px;
					padding-left: 10px;
				}
				&:nth-child(2) {
					float: right;
					padding-right: 10px;
					span {
						display: inline-block;
						margin: 0 5px;
						font-size: 10px;
					}
					img {
						width: 12px;
						margin: 0 5px;
					}
				}
			}
			input {
				border-radius: 4px;
			}
		}
		.answer-container {
			margin-top: 30px;
			height: calc(100% - 80px);
    	overflow: auto;
			p {
				&:first-child {
					font-weight: 600;
					font-size: 12px;
					padding-left: 10px;
					margin-bottom: 5px;
				}
				&:nth-child(2) {
					font-size: 9px;
					padding-left: 10px;
					&.right-block {
						font-size: 9px;
						padding-left: 0;
						margin-bottom: 15px;
					}
				}
				&:nth-child(3) {
					font-size: 9px;
					display: inline-block;
					margin-right: 15px;
					img {
						width: 13px;
					}
					span {
						display: inline-block;
						line-height: 1.3;
					}
				}
				&:nth-child(4) {
					font-size: 9px;
					display: inline-block;
					img {
						width: 13px;
					}
					span {
						display: inline-block;
						line-height: 1.3;
					}
				}
			}
			button {
				float: right;
				height: 19px;
				font-size: 7px;
				border-radius: 20px;
				background: transparent;
				border: 1px solid #000;
				margin-top: 10px;
			}
			.answers-list {
				margin-top: 15px;
				.answer-row {
					margin-bottom: 10px;
				}

				p {
					display: inline-block;
				}
				input {
					margin-left: 10px;
					border-radius: 4px;
				}
			}
		}
	}
}
.loader-container{
	padding:1rem;
	height:44vh;
	background: rgba(249, 249, 249, 0.7);
	border: 1px solid #CCCCCC;
	box-sizing: border-box;
	border-radius:5px;
	box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(5px);
	margin:5px;
	z-index:1000;
	position:absolute;
	top:147px;
	left:10px;
	width:94%;
	.loader-content{
		border:4px dashed rgba(0, 115, 186, 0.5);
		border-radius:5px;
		height:100%;
		justify-content: start;
		align-items: center;
		position:relative;
		.toggle-cross {
			position:absolute;
			right:20px;
			top:20px;
			cursor:pointer;
		}
	}
	.loader-img{
		width:124px;
		border-radius:50%;
		margin-left:4rem;
	}
	.loader-instructions{
		margin-left:6rem;
		color:#3483E0;
		.heading{
			font-family: Raleway;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: left;

		}
		.subheading{
			font-weight: 300;
			font-size: 14px;
			line-height: 18px;
		}
	}

	.performance-info-card {
	background: #027e8e;
	color: var(--text-white);;
	}

	.dot {
	border-radius: 50%;
	display: inline-block;
	}
}
.profile-card {
  max-width: 500px;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
}
.profile-card figure {
  margin: 0;
}
.profile-card figure img {
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(21, 21, 21, 0.15);
}
.profile-card header p {
  margin: 0;
  padding: 0;
  line-height: 1;
	font-size: 13px;
}
.profile-card header h1 small {
  display: block;
  clear: both;
  font-size: 18px;
  opacity: 0.6;
}
.container-timeline {
   width: 100%;
	 height: 260px;
   margin-top: 16px;
   position: relative;
   overflow: auto;
}

.container-timeline:before {
   content: '';
   position: absolute;
   top: 0;
   left: 50%;
   margin-left: -1px;
   width: 2px;
   height: 100%;
   background: #CCD1D9;
   z-index: 1
}

.timeline-block {
   width: -webkit-calc(50% + 8px);
   width: -moz-calc(50% + 8px);
   width: calc(50% + 8px);
   display: -webkit-box;
   display: -webkit-flex;
   display: -moz-box;
   display: flex;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   -moz-box-pack: justify;
   justify-content: space-between;
   clear: both;
}

.timeline-block-right {
   float: right;
}

.timeline-block-left {
   float: left;
   direction: rtl
}

.marker {
	width: 20px;
	height: 20px;
	border: 2px solid #F5F7FA;
	background: #b8b8b8;
	margin-top: 10px;
	z-index: 9999;
	text-align:center;
	position: relative;
	left: 9px;
}

.marker.active{
  width: 20px;
  height: 20px;
  background:#5bd040;
  left: 9px;
}
.timeline-content {
   width: 95%;
   padding: 0 15px;
   color: #666
}
i.fa.fa-check{
  display:none;
}
i.fa.fa-check.active {
 display:block !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 5px;
    margin: auto;
    height: 50%;
  color:#fff;
}

.timeline-content h3 {
   margin-top: 8px;
   margin-bottom: 5px;
   font-size: 20px;
   font-weight: 500
}

.timeline-content span {
   font-size: 15px;
   color: #a4a4a4;
}

.timeline-content p {
   line-height: 1.5em;
   word-spacing: 1px;
   color: #808080;
}
.container-timeline:before {
  left: 19px;
  width: 2px;
}
.timeline-block {
  width: 100%;
  margin-bottom: 10px;
}
.timeline-block-right {
  float: none;
}

.timeline-block-left {
  float: none;
  direction: ltr;
}

.tds-tab-o-container {
	display:flex;
}
.tds-tab-list {
	margin: 0;
    width: 100%;
    background-color: #f4f4f4;
	transition:background-color .3s ease,box-shadow .3s ease,color .3s ease;
	border-radius:100px;
	position: relative;
    display: flex;
    padding: 5px;
    z-index: 1;
    overflow-x: auto;
    border-radius: 100px;
}

.tds-tab-active {
	color: #000;
    background-color: var(--text-white);;
    border-radius: 100px;
    box-shadow: inset 0 0 0 0 transparent, 0 1px 8px 0 #0000001a;
	transition:background-color .3s ease,box-shadow .3s ease,color .3s ease;
}

.goal-horizontal-scroll-container{
	width: 99.5%;
	height: 80px;
	overflow: hidden;
	margin: 0;
	position: relative;

	p {
		color: #e0e0e0;
	}

	.dotted-date-tracker{
		border: 1px dashed rgba(0, 0, 0, 0.2);
		width: 100%;
		position: absolute;
		bottom: 0;
		margin: 0 0 40px 0;
		height: 0px;
	}

	.date-pointer{
		position: absolute;
		width: 100%;
		bottom: 0;
		margin: 0 0 24px 0;
	}

	.base-width{
		width: 30px;
		text-align: center;
		text-align:center;
		justify-content: center;
		z-index:99;

		p {
			color: #000000;
			white-space: nowrap;
			font-size: 10px;
			visibility: hidden
		}

		.inner-date{
			color: #000000;
			white-space: nowrap;
			font-size: 10px;
		}
	}

	.date-list-tracker{
		position: absolute;

		width: 100%;
		bottom: 0;
		margin: 0 0 2px 0;
		float: left;
		direction: ltr;

		.start-end-date{
			font-weight: normal;
			font-size: 10px;
			line-height: 14px;
			color: #000000;
		}

		.base-date{
			min-width: 40px;
			max-width: 40px;
		}
	}

	.triangle{
		/* Rectangle 544 */
	position: absolute;
	width: 17px;
	height: 20px;
	left: 417px;
	top: 269px;

	/* Dark Highlight */
	background: #F7D560;
	box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
	}
}

input:checked {
  + .tab-label {
    //background: darken(#fff, 10%);
		background: darken(#f0f8ff, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    //max-height: 100vh;
		max-height: 200vh;
    padding: 1em;
  }
	~ .tab-content-mobile {
    max-height: 200vh;
  }
}
.accordian-row {
	display: flex;
	.accordian {
		flex:1;
			&:last-child {
				margin-left: 1em;
			}
	}
}
.accordian-row.lead {
	overflow:	auto;
	padding:	5px;
}
.basic-table {
	thead th {
		padding: 0px 10px;
		width: 150px;
		background: #fff;
		font-weight: normal;
		color: #6f7782;
		font-size: 13px;
		height: 37px;
		text-align: left;
    border-right: 1px solid #cbd4db;
    border-top: 1px solid #cbd4db;
    border-left: transparent;
    border-bottom: transparent;
		&:hover {
			background-color: #e2e2e2;
			color: #000;
		}
	}
	tbody td {
		padding: 2px 10px;
		width: 150px;
		color: #000;
		font-size: 13px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 25px;
		vertical-align: middle;
		background: #fff;
		text-align: left;
    border-right: 1px solid #cbd4db;
    border-top: 1px solid #cbd4db;
    border-left: transparent;
    border-bottom: transparent;
		border: 1px solid rgb(203, 212, 219) !important;
		&:hover {
			background-color: #e2e2e2;
		}
	}
}

.tabs-tracker {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
}
.tab-tracker {
  width: 100%;
	//color: var(--text-white);;
	color: #f0f8ff;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    //background: #fff;
		background: #f0f8ff;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      //background: darken(#fff, 10%);
			background: darken(#f0f8ff, 10%);
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    //color: var(--text-white);;
    //background: white;
		color: #f0f8ff;
    background: #f0f8ff;
    transition: all .35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #fff;
    cursor: pointer;
    &:hover {
      //background: darken(#fff, 10%);
			background: darken(#f0f8ff, 10%);
    }
  }
	&-input {
	  position: absolute;
	  opacity: 0;
	  z-index: -1;
	}
	label {
		text-align: left !important;
		padding-left: 10px !important;
		color: #787878 !important;
	}
}

button.accordion {
  width: 100%;
  background-color: whitesmoke;
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 20px;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s linear;
}

button.accordion:after {
  font-family: FontAwesome;
  content: "\f150";
  font-family: "fontawesome";
  font-size: 18px;
  float: right;
}

button.accordion.is-open:after {
  content: "\f151";
}

button.accordion:hover,
button.accordion.is-open {
  background-color: #ddd;
}

.accordion-content {
  background-color: white;
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.showLeft {
  background-color: white !important;
  border: 1px solid white !important;
  text-shadow: white !important;
  color: black !important;
  padding: 10px;
}
.icons li {
  background: none repeat scroll 0 0 black;
  height: 7px;
  width: 7px;
  line-height: 0;
  list-style: none outside none;
  margin-right: 15px;
  margin-top: 3px;
  vertical-align: top;
  border-radius: 50%;
  pointer-events: none;
}
.btn-left {
  left: 0.4em;
}
.btn-right {
  right: 0.4em;
}
.btn-left,
.btn-right {
  position: absolute;
  top: 0.24em;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}
.dropdown {
  position: absolute;
  display: inline-block;
  right: 0.4em;
}
.dropdown-content-menu {
  display: none;
  position: relative;
  margin-top: 60px;
  background-color: #f9f9f9;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

// By: Komal Wable
// Date: 11 Oct 2022
// Modification: Add class for panel width

.panelActive-width {
	//width: calc( 100vw - (65px + 360px)) !important
	width: calc( 100vw - 355px);
}

.panelActive-width-left {
	width: 250px;
}

.full-width {
	width: calc( 100vw - (105px)) !important
}
.full-height {
  height: calc(100vh - 50px) !important
}
.modal-header {
	background-color: #eff0f3;
	width: 100%;
	position: relative;
	border-radius: 12px 12px 0 0;
	height: 48px;
	min-height: 48px;
	align-items: center;
	border-bottom: 1px solid #dcdfe4;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 13px 20px;
	.text {
		background: #fff;
		padding: 5px;
		border-radius: 5px;
		font-size: 12px;
		color: #72828d;
	}
}
.modal-close {
	float: right;
	font-size: 26px;
	margin-top: -48px;
}

.modal-close:hover,
.modal-close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}
/* [Media Queries]
--------------------------------------------------------------------------------
*/
@media (min-width:320px)  {
	/* smartphones, iPhone, portrait 480x320 phones */

}
//Modified by: Arun Singh
//Modified on: 20/12/2023
//Modification: Add media for responsive.

@media (min-width:320px) and (max-width:425px)  {
  /* smartphones, iPhone, portrait 480x320 phones */
	.uploaded-image {
		.delete-file-icon {
			display: block;
		}
	}

	.day-plan-workspace-table-card {
	  table {
			th, td {
				font-size: 10px !important;
				font-weight: 400;
				color: #000;
				vertical-align: middle;
			}
		}
	}
}
@media (min-width:481px)  {
	/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

}
@media (min-width:641px)  {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

}
@media (min-width:768px)  {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */

}
@media (min-width:961px)  {
	/* tablet, landscape iPad, lo-res laptops ands desktops */

}
@media (max-width:1024px) {
	/* big landscape tablets, laptops, and desktops */
}
@media screen and (min-width:1023px) and (max-width:1279px) {
	.panelActive-width {
		width: calc( 100vw - 285px);
	}

	.panelActive-width-left {
		width: 200px;
	}
}
@media (min-width:1280px) {
	/* hi-res laptops and desktops */

}
/*
		modified by Arun Singh
		modified at 28/07/2023
		modification : Add CSS to transition-show and transition-hide
*/
.workspace-header-transition-show {
	transition: all 0.2s ease-in;
}
.workspace-header-transition-hide {
	height: 0px;
	margin: 0 !important;
	transition: all 0.2s ease-out;
}
/*
		modified by Arun Singh
		modified at 28/07/2023
		modification : Add CSS to fixed info header at top
*/
.mobile-top-info-header-sticky {
  position:relative;
	z-index:999;
	position:sticky;
	top:0;
}
/*
	Bug: COMP_BG_565 (The label "Report List" should be bold)
	Modification: Added a global class for "Report List" text particularly, because reports column had no scope for css as it is an object.
		Increased the font-size and font-weight for table head
	Date: 14th September, 2023
	Modified By : Akansha Malviya
*/
.table-scroll thead th {
	font-weight: 600;
	font-size:15px
}

/*
		modified by Arun Singh
		modified at 07/09/2023
		modification : Add box shadow css on hover
*/
.hover-bg-shadow:hover {
  box-shadow: 0 3px 0px 0px #65757c, 0 0px 0px 0 #6b828d, 0 0px 0px 0 #39525c;
}

.header-tab-default {
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  padding: 9px 5px;
  color: #000 !important;
  min-width: 130px;
  border: 1px solid #b7b2b2;
  justify-content: space-around;
}
.header-tab-active {
  background: #f2f2f2;
}
