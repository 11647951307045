.collapse-tab-left {
  position: absolute;
  width: 25px;
  cursor: pointer;
  top: 5px;
  left: 212px;
  z-index: 999;
  background-color: var(--theme-default-colour) !important;
  border: 1px solid #eff1f2;
  height: 25px;
  border-radius: 4px 0px 0px 4px;
  svg {
    fill: var(--text-grey);
    path {
      fill: var(--text-grey);
    }
  }

  &:hover {
    background-color: var(--theme-default-colour) !important;
    svg {
      fill: #fff !important;
      path {
        fill: #fff !important;
      }
    }
  }
}
.collapse-tab-right {
  position: absolute;
  width: 25px;
  cursor: pointer;
  top: 5px;
  left: 0;
  z-index: 999;
  background-color: #cfd2cf !important;
  height: 25px;
  border-radius: 0px 4px 4px 0px;
  svg {
    fill: var(--text-white);
    path {
      fill: var(--text-white);
    }
  }

  &:hover {
    background-color: #22618c1a !important;
    svg {
      fill: #2872a4 !important;
      path {
        fill: #2872a4 !important;
      }
    }
  }
}

@media (min-width:1023px) and (max-width:1279px)  {
  .collapse-tab-left {
    left: 168px;
  }
}
