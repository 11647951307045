.sub-card-component {
  height: 50%;
  align-content: center;
  padding: 0 16px 0 0;
  background: #fcebeb;
  //color: #fff;
  border-radius: 4px;
}

/*
  Modification: Add scc class for mobile Responsiveness
  By: Arun Singh
  Date: 10/08/2023
*/
.sub-card-component-mobile-view {
  height: 50%;
  align-content: center;
  background: #fcebeb;
  border-radius: 4px;
}
