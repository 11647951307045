.mobile-bottom-sheet {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
}
.mobile-bottom-sheet.show {
  opacity: 1;
  pointer-events: auto;
}
.mobile-bottom-sheet .mobile-sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: #000;
}
.mobile-bottom-sheet .mobile-sheet-content {
  width: 100%;
  position: absolute;
  bottom:0;
  background: #fff;
  max-height: 100vh;
  height: 50vh;
  padding: 25px 10px;
  //transform: translateY(100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.03);
  transition: 0.3s ease-out;
}
.mobile-bottom-sheet.show .mobile-sheet-content{
  transform: translateY(0%);
}
.mobile-bottom-sheet.dragging .mobile-sheet-content {
  transition: none;
}
.mobile-bottom-sheet.fullscreen .mobile-sheet-content {
  border-radius: 0;
  overflow-y: hidden;
  transform: translateY(0%);
}
.mobile-bottom-sheet .mobile-sheet-header {
  display: flex;
  justify-content: center;
}
.mobile-sheet-header .drag-icon {
  cursor: grab;
  user-select: none;
  padding: 15px;
  margin-top: -15px;
}
.mobile-sheet-header .drag-icon span {
  height: 4px;
  width: 40px;
  display: block;
  background: #C7D0E1;
  border-radius: 50px;
}
.mobile-bottom-sheet .body {
  height: 100%;
  overflow-y: auto;
  padding: 10px 0 40px;
  scrollbar-width: none;
}
.mobile-bottom-sheet .body::-webkit-scrollbar {
  width: 0;
}
.body-content {
  height: 60px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
