.search-filter {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  outline: 0;
  border: 0;
  background-color: #fff;
  font-weight:500;
  font-size:14px;
}

.dropdown-filter-container-area {
  z-index:7;
  width: 809px;
  position:absolute;
  box-sizing:border-box;
  bacground-color: #fff !important;
  border-radius: 5px;
  margin-top: 5px;
}

.dropdown-right-filter-container {
  max-height:350px;
  position:relative;
}

.search-button {
  background-color:#3bc8f5;
  height:100%;
  color:white;
}
.multi-select-input {
  position: relative !important;
  left: 0 !important;
  opacity: unset !important;
  width:17px;
  height:17px;
}
.multi-select-container {
  z-index:7;
  position:absolute;
  background-color:#fff;
  width: 399px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.multiple-checked-options {
  color: white;
  width: 117px;
  height: 26px;
  display: flex;
  background-color:#3bc8f5;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding:4px
}
.repeat-multiple-select-value {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3px;
}
.fixed-filter-bottom {
  position: absolute;
  bottom: 0;
  width: 399px;
  margin-bottom: 0px;
  background-color: white;
}
.rmdp-arrow-container {
  min-width: 0px !important;
}
.rmdp-arrow-container:hover {
  background-color: var(--tenant-color-secondary) !important;;
  border-radius: 50% !important;
  min-width: 0px !important;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  height: 9px !important;
  width: 9px !important;
}
.rmdp-arrow {
  border: solid var(--tenant-color-secondary) !important;;
  border-width: 0 2px 2px 0 !important;
  height: 9px !important;
  width: 9px !important;
}
.rmdp-week-day {
  color: var(--tenant-color-secondary) !important;;
}
.b-date {
  height: 100% !important;
}
.b-deselect {
  min-width: 0px !important;
  background-color: var(--tenant-color-secondary) !important;;
}
.rmdp-panel-body > li{
  background-color: var(--tenant-color-secondary) !important;;
}
.rmdp-range {
  background-color: var(--tenant-color-secondary) !important;;
}
.rmdp-today > span {
  background-color: var(--tenant-color-secondary) !important;
}
.rmdp-ep-arrow {
  display:none !important;
}
