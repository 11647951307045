.rightPanel-tab {
  width: 50px;
  //border-left: 1px solid #e2e2e2;
  background: linear-gradient(180deg, #79C6C1 0%, #2E8994 0.01%, #002C5F 87.5%);
  height: 100vh;
}

// Modification: added linear-gradient background to the leftInfoPanel-tab
// BY: Vipin
// Date: 28/03/2023
