.single-label-data-element, .double-label-data-element, .triple-label-data-element {
  .card-data-badge {
    background: #e6e6e6;
    width: fit-content;
    border-radius: 4px;
    padding: 0 8px;
  }

  .error-validation {
    border-bottom: 2px solid red !important;
  }
  .error-message {
    color: red;
    font-size: 11px;
  }
}

.double-label-data-element, .triple-label-data-element {
  padding: 5px 0;
  min-height: 25px;
}

.edit-element-focus:focus-within {
  background: #bcd2d2;
}
