.workspace-container-new{
  padding: 0 !important;

  .workspace-outer-new {
    border-radius: 0;
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-top:32px;

    .workspace-rowItem-width{
      max-width : 40% !important;

    }

    .workspace-row-new{
      margin-bottom: 16px !important;

      .workspace-rowItem-width{
        max-width : 21% !important;

      }

    }

    .workspace-cards-new {
      background-color: #fff !important;
      margin-left:-32px;
      margin-right:-32px !important;
      border-radius: 0;
      max-width: 106%;
      flex-basis: 106%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-top: 32px !important;

      .workspace-subcard-new{
        background-color: transparent !important;
        margin-top: -10px;
      }

      .workspace-cards-new-header {
        color: var(--theme-default-colour) !important;
        padding-top: 0px;
        padding-left:8px;
        font-size: 24px !important;
        font-weight: 500;
      }
      .workspace-cards-new-sub {
        margin-top: 36px;
        padding-left: 8px;
        color: gray;
      }
    }

    .workspace-cards-new2 {
      background-color: #e5dcd3 !important;
      margin-left:-32px;
      margin-right:-32px !important;
      border-radius: 0;
      max-width: 106%;
      flex-basis: 106%;
      margin-top: 0;
      margin-bottom:0;
      padding-top: 32px !important;

      .workspace-subcard-new2{
        margin-top: -10px;
      }

      .workspace-cards-new2-header{
        color: var(--theme-default-colour) !important;
        padding-top: 0px !important;
        padding-left: 8px;
        font-size: 24px !important;
        font-weight: 500;
      }

      .workspace-cards-new2-sub{
        margin-top: 36px;
        padding-left: 8px;
        color: gray;
      }

    }

    }


}
