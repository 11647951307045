.wrap {
  position: absolute;
  top:0;right:0;bottom:0;left:0;
  max-width: 1200px;
  min-width: 300px;
  padding: 10px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}




.svg-wrap {
  width: 100%;
  margin: auto;
}

#svg {
  width: 100%;
  height: auto;
}

.is-loading {
  opacity: 0;
}

// fix fouc in safari and mobile browsers when path segment are of length 0
#box1,
#box2,
#box3,
#box4,
#arrow2,
#arrow3,
#arrowhead2,
#arrowhead3 {
    opacity: 0;
}
