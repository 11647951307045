.listCard-nohover {
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 1px 2px rgb(0 0 0 / 6%);
  min-height: 50px;
  background:fff;
  border-radius: 4px;
  border-bottom: 1px solid rgba(0,0,0,0.051)
}

.listCard {
  //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  box-shadow: 0 1px 3px rgb(0 0 0 / 0%), 0 1px 2px rgb(0 0 0 / 6%);
  min-height: 50px;
  background:fff;
  border-bottom: 1px solid rgba(0,0,0,0.051)
}

.listCardActive {
	//-webkit-box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
	-moz-box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
	//box-shadow: 5px 5px 5px 0px rgba(186,171,186,1);
  //border: 1px solid #3892f9fc !important;
  //background: #cfd2cf;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  // Modification: Added border color for selected card
  // BY: Devang
  // Date: 22/02/2023
  border-color: var(--theme-default-colour) !important;
}

.listCardActiveList {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-left: 2px solid #696d76;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.listCard:hover {
  box-shadow: 0 3px 5px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
}

.listCard.is-loading {
  .msgContainer {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

// modified by Vihang
// modified on 12/05/2022
// modification : list view height correction

.list-card-scrollable-container {
  height: calc(100vh - 149px);
  overflow: hidden auto;
}

.list-card-scrollable-container-extra {
  height: calc(100vh - 125px);
  overflow: hidden auto;
}

.list-card-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 0;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.list-card-second-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 15px;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.list-card-third-ribbon {
  width: 15px;
  writing-mode: vertical-lr;
  right: 30px;
  top: 0;
  transform: rotate(180deg);
  color: var(--text-white);
}

.noTasksList {
    margin: 0 auto;
    height: inherit;
    display: flex;
    width: fit-content;
    align-items: center;
}

/*
        modified by Vihang
        modified on 17/05/2022
        modification : added ellipse for list description
  */


.listCardDescription {
    text-overflow: ellipsis;
    width: 147px;
    overflow: hidden;
    white-space: nowrap;
}

/*
        modified by Vipin
        modified on 10/12/2022
        modification : added listcard-th classes for list card header columns, and listcard1-container for list card cells.
*/

.listcard-thead {
  border: none;
  //border-bottom: 2px solid #e1e1e1;
  background: #fff;
  z-index: 1;
  padding-top:0px;
  font-size: 12px;
  position: sticky;
  top:0;
  background-color: white;
  border-bottom: 2px solid #e1e1e1;
  z-index:6;

  .select-task-all {
     position: absolute;
     top: -9px;
     bottom: 0px;
     left: -30px;
     right: 0;
     display:none;

  }
  .select-task-all-active {
     position: absolute;
     top: -9px;
     bottom: 0px;
     left: -30px;
     right: 0;
     display:block;
  }
  .select-all-active{
  display:none;
  }

  .listcard-th:first-child,th:first-child, th:nth-child(2) {
    position: sticky;
    left:0;
    }
    th:nth-child(2){
      //left:36px;
    }
    th:first-child:hover{
      .select-task-all {
        display:block;
      }
      /*
        Bug: T202 - BG_UI - Hovering Over '#' Sign Results in Blank Display
        Modification: Commented select-all display-none
        By: Devang
        Date: 24/05/2024
      */
      /*.select-all {
     display:none;
      }*/
    }


  .col-head-active {
    background-color: #e8eaed !important;
    border-radius: 3px;

  .hover-icon-1 {
    left:-18px;
    bottom:0;
    opacity:1 !important;
    }

  .hover-icon-2{
    right:-18px;
    bottom:0;
    opacity:1 !important;
    }
  }

  .col-head-active-table {
    background-color: #e8eaed !important;
    border-radius: 3px;

  .hover-icon-1 {
    /*
      Bug: T243 | BG_UI - Sort icon overlapped on column name
      Modification: Handled the positioning view of sort icon | Updated Left handleXScrollTable right
      By: Devang
      Date: 24/05/2024
    */
    //left:124px;
    right: -18px;
    bottom:0;
    opacity:1 !important;
    }

  .hover-icon-2{
    left:142px;
    bottom:0;
    opacity:1 !important;
    }
  }

  .stickyTableHead {
   position: sticky !important;
   left: 0;
   z-index: 7;
   background-color: #fff !important;
  }


  .listcard-th:hover {
    background-color: #e8eaed;
    border-radius: 3px;
    gap:1px;


    .hover-icon-1 {
      left:-18px;
      bottom:0;
      opacity:1 !important;
      }

    .hover-icon-2{
      right:-18px;
      bottom:0;
      opacity:1 !important;
      }
    }

    th:hover {
      background-color: #e8eaed;
      border-radius: 3px;
      gap:1px;


      .hover-icon-1 {
        /*
          Bug: T243 | BG_UI - Sort icon overlapped on column name
          Modification: Handled the positioning view of sort icon | Updated Left handleXScrollTable right
          By: Devang
          Date: 24/05/2024
        */
        //left:124px;
        right: -18px;
        bottom:0;
        opacity:1 !important;
        }

      .hover-icon-2{
        left:142px;
        bottom:0;
        opacity:1 !important;
        }
      }

  .listcard-th,span,div,th {
    font-weight:500 !important;
    //color: #a0a7b3 !important;
    color: #646464 !important;
    text-transform: uppercase;
    padding-top: 4px;
    padding-bottom: 2px;
    transition: 0.2s background ease;
    background-color: transparent;
    font-size: 11px;
    border: none;
    text-align : center;
    cursor : pointer;
    flex-shrink: 0;
    line-height:1;
  }
    th {
      border : 1px solid #e1e1e1;
      border-right : 0;
      text-align:left;

      [type="checkbox"] + label::before{
        top: 3px;
        left: 8px;
        width: 13px;
        height: 13px;

      }

      [type="checkbox"]:checked + label::before {
        width: 9px;
        height: 16px;
        left:4px;
        }
        top:-1px;

    }
    th:last-child {
     border-right : 1px solid #e1e1e1;
    }
    listcard-th {
  }

    //Modification: Added style to give styl to checkboxes in header-columns also
    //By: Devang
    //Date: 17/02/2023
    .listcard-tr {
      [type="checkbox"] + label::before{
        top: 3px;
        left: 8px;
        width: 13px;
        height: 13px;
      }

      [type="checkbox"]:checked + label::before {
        width: 9px;
        height: 16px;
        left:4px;
        top:-1px;
      }
    }
}
thead{
//top:10px !important;
border: none !important;
}
/*
      modified by Vipin
      modified on 22/12/2022
      modification : Added stickyTableCell for background change on horizontal scroll.
*/

.listcard1-container {
  font-size: 13px;

  .expand-task{
     left:180px;
     width: 20px;
     height: 20px;
     background-color: #e8eaed;
     display:none;
     align-items: center;
     justify-content: center;
     opacity: 0.5;

     &:hover {
     opacity: 1;
     }
  }
  //Modification: Added class for delegating task
  //By: Devang
  //Date: 14/02/2023
  .delegate-task {
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    //background-color: #e8eaed;
    display:none;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
  .always-open-delegate-task.delegate-task {
    opacity: 1;
    display: flex;
  }
  .listcard-td:first-child,td:first-child,td:nth-child(2) {
    text-align: left;
    position: sticky !important;
    left:0;
  }
  td:first-child{
    padding-left: 0 !important;
  }
  td:nth-child(2){
    left:36px;
  }

  .listcard-tr,tr {
      /*
        Modification: Added important on background color on hover due to sticky new add-column
        By: Devang
        Date: 04/04/2023
      */
      &:hover {
        background-color: #f7f8f9 !important;
      td {
        background-color: #f7f8f9 !important;
      }
      .expand-task {
        display:flex;
      }
      //Modification: Added class for delegating task
      //By: Devang
      //Date: 14/02/2023
      .delegate-task {
        display:flex;
      }
      .select-task {
        display:block;
       }
      .select-task-no {
        display:none;
      }
    }

  .listcard-td {
      height: 38px !important;
    }
  .listcard-td,td {
       font-weight: 400 !important;
       color: #3b3b3b;
       text-align: left;
       padding:0 !important;
       background-color: transparent;
       flex-shrink: 0 !important;
       border: none;
       //overflow: hidden;
       text-overflow: ellipsis;
       white-space: nowrap;

       .unSelectable {
         -webkit-user-select: none; /* Safari */
         -moz-user-select: none; /* Firefox */
         -ms-user-select: none; /* IE10+/Edge */
          user-select: none; /* Standard */
          padding: 8px;
       }
       span {
        //overflow: hidden;
         text-overflow: ellipsis;
         cursor: pointer;
         white-space: nowrap;

       }
   }

   td {
     border-left: 1px solid #e1e1e1;
     border-bottom: 1px solid #e1e1e1;
     //display:flex;
     align-items: center;
     padding-left: 8px !important;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
     cursor : pointer;
     span {
       overflow: hidden;
       white-space: nowrap;
       text-overflow: ellipsis;
       cursor: pointer;
     }
     .select-task {
        position: absolute;
        top: -9px;
        bottom: 0px;
        left: 2px;
        right: 0;
        display:none;
     }
     .select-task-no-active{
     display: none;
     }
     .select-task-active{
     position: absolute;
     top: -9px;
     bottom: 0px;
     left: 2px;
     right: 0;
     display:block;

     }
   }

   td:last-child {
     border-right: 1px solid #e1e1e1 !important;
   }

   [type="checkbox"] + label::before{
     top: 3px;
     left: 8px;
     width: 13px;
     height: 13px;

   }

   [type="checkbox"]:checked + label::before {
     width: 9px;
     height: 16px;
     left:4px;
     top:-1px;
   }

  }

  .stickyTableCell {
     position: sticky !important;
     left: 0;
       background-color: #fff !important;
     z-index: 5;
  }

  .stickyTableIcons {
    opacity:0.2;
  }

  span,div {
     font-weight: 400 !important;
     color: #3b3b3b;
     //line-height: 1.2 !important;
  }
  .listcard1-case {
    font-weight: 500 !important;
  }
  .listcard1-case-table {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #3b3b3b !important;
    text-transform:capitalize;
    font-size: 13px !important;
  }

}

.taskview-icons {
  .newWorkSpaceCard {
    min-height: 34px !important;
    min-width: 34px !important;
    height: 34px !important;
    width: 34px !important;
    margin: 2px 2px !important;
  }

}


.tasklist1-input-label::after{
  border: 1px solid #64696d !important;
  background-color: #2a2e34 !important;
  outline: 2px solid #fff;
  outline-offset: -3px;
}

.addColumnsTable{
  width: 43px;
  height: 24px;
  right: 0px;
  top: 0;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  padding: 6px;
  /* border-radius: 2px; */
  /* left: 000; */
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-left : 1px solid #e1e1e1;

  [type="checkbox"] + label::before{
  top: 3px;
  left: 0;
  width: 0px;
  height: 0px;
  border: 0px solid #5a5a5a;
  font-size: 12px !important;
  }

  [type="checkbox"]:checked + label::before {
  top: 0px;
  left: -5px;
  width: 0px;
  height: 0px;
  border-right: 0px solid #5a5a5a !important;
  border-bottom: 0px solid #5a5a5a !important;
  font-size: 11px !important;
  }


   input[type=checkbox]{
     height: 0;
     width: 0;
     visibility: hidden;
   }

   label {
     cursor: pointer;
     text-indent: -9999px;
     width: 38px;
     height: 18px !important;
     background: grey;
     display: block;
     border-radius: 40px;
     position: relative;
   }

   label:after {
   content: '' !important;
    position: absolute !important;
    left: 3px !important;
    width: 14px !important;
    height: 14px !important;
    background: #fff;
    border-radius: 16px !important;
    transition: 0.2s !important;
    transform:scale(1) !important;
   }

  input:checked + label {
   background-color: #bada55;
  }

  input:checked + label:after {
   left: calc(100% - 5px);
   transform: translateX(135%) !important;

  }

  label:active:after {
   width: 20px;
  }



  .addColumn-container{
    width: 18px;
    height: 18px;
    border-radius: 18px;
    padding-top: 3px;
    border: 1px solid gray;
  }
}

.shareTaskIcon:hover{

  svg {
    fill:#000 !important;
    transform: scale(1.1);
  }
  path {
    fill:#000 !important;
  }
}

.shareTaskIcon {
  cursor:pointer;
}

/*
        modified by Vihang
        modified on 04/05/2022
        modification : responsive changes for listcard component
  */
@media (min-width:405px) and (max-width:425px)  {
  .listCardDescription {
    width: 235px;
  }
  /*
          modified by Vihang
          modified on 13/05/2022
          modification : listcard component height corrections for mobile view
    */
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}

/*
        modified by Vihang
        modified on 18/05/2022
        modification : list description width adjusted for 375 and 325px mobile sizes and height corrections of list cards
  */
@media (min-width:375px) and (max-width:405px)  {
  .listCardDescription {
    width: 190px;
  }
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}
@media (min-width:320px) and (max-width:374px)  {
  .listCardDescription {
    width: 135px;
  }
  .list-card-scrollable-container {
    height: calc(100vh - 157px);
    overflow: hidden auto;
  }
}

@media (min-width:1023px) and (max-width:1279px)  {
  .listCardDescription {
    width: 128px;
  }
}
