/* -------------------- Table Container ----------------- */

.containers{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1.5px solid #fff;
  margin: 10px;
  /*
    By: Yashvi
    On: 30th May 2024
    Bug: Timeline is not scroable
    Added height and overflow
  */
  height: calc(100vh - 160px);
  overflow: auto;
}

/* -------------------- Table Heading ----------------------- */
.heading_style {
  font-family: "Raleway", sans-serif;
  padding: 12px 2px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight:600;
  color: #868686;
  background-color: rgb(255, 255, 255);
  font-size: 1.5rem;
  margin: 10px 5px;
  border-radius: 10px;
  cursor:default;

}

/* Class applied to whole Table on loading Modal  */
.blur{
  opacity: 0.4;
}

/* ----------------- Table Style ------------------- */
.stageTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border-style: hidden;
  width: 80%;
  top:30px;
  left:30px;
  position:absolute;
}

/* ----------------- Table Headers and Table Columns------------- */
.stageTable td, .stageTable th {
  border: 1px solid #8c8c8c;
  padding: 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #fff;
  font-weight: 200;
  cursor: pointer;
}


/* ------------------ Table Styles for each Row according to Task Type ----------------------- */

.enquiry,.booking,.retail,.delivery{
  border-radius: 4px;
}

/*.stageTable:nth-child(4),.stageTable:nth-child(2),.stageTable:nth-child(3) {background-color: #87b0f3;}
.stageTable tr:nth-child(7),.stageTable tr:nth-child(5),.stageTable tr:nth-child(6) {background-color: #87b0f3;;}
.stageTable tr:nth-child(8),.stageTable tr:nth-child(9) {background-color: #87b0f3;;}
.stageTable tr:nth-child(10),.stageTable tr:nth-child(11) {background-color: #87b0f3;;}*/

/* .stageTable tr:nth-child(2) td,tr:nth-child(3) td,tr:nth-child(4) td {border-radius:4px}
.stageTable tr:nth-child(5) td,tr:nth-child(6) td,tr:nth-child(7) td {border-radius:4px}
.stageTable tr:nth-child(8) td,tr:nth-child(9) td {border-radius:4px}
.stageTable tr:nth-child(10) td,tr:nth-child(11) td {border-radius:4px} */


.stageTable th {
  //opacity: 0.5;
  padding-left: 8px;
  text-align: left;
  border-top: 0;
  border-bottom: 0;
  font-weight: 600;
}

.stageTable tr:last-child td {
  border: 0;
  font-size: 10px;
  padding-left: 0;
  text-align: start;
  color: #000;
  font-weight: 300;
}

.stageTable tr td {
  border-top: 0;
  border-bottom: 0;
}

/* ---------------------- ToolTip -Tippy Prop Component ---------------- */
.tooltip{
  font-weight: 400;
  font-size: 12px;
  padding: 10px 20px;
 }
 .tooltip *{
  margin: 0;
  padding: 0;
 }

/* --------------------- Modal - ShowTaskDetail Component  ------------ */
.modals{
  position: absolute;
  top: 30%;
  left: 20%;
  right: 20%;
  z-index: 1;
}

/* ---------Button for Task Modal------------- */
.stageButtons {
  padding: 5px 8px;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #868686;
  background-color: #fff;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
  margin-top: 4px;

}

/*----- Container for Task Modal ----------- */
.taskCards{
  margin-top: 0.4rem;
  padding: 40px 20px;
  width: 100%;
  display: grid;
  border: 2px solid #868686;
  background: #fff;
  border-radius: 10px;
}

/*---- Task Modal ---------*/
.taskCard {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,200px);
  justify-content: center;
  justify-self: center;
  border-radius: 10px;
  padding: 0.4rem;
  font-family:"Raleway", sans-serif;
}

.taskCard *{
  justify-self: center;
  align-self: center;
  text-transform: uppercase;
}

/* ---------Task Modal Information ------*/
.taskCard_category {
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  color: #868686;
}

/* --------Image For Task Modal------------- */
.taskCard img{
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 10px;
}
