.call-info-card {
    width: 358px;;
    .call-card-type-1 {
      .call-info-card-icon, .call-info-card-square-point, .call-info-card-div-footer, .process-underline {
        background-color: #2E8994;
      }
      .call-info-card-circle{
        background-color: blue;
      }
    }
    .call-card-type-2 {
      .call-info-card-icon, .call-info-card-square-point, .call-info-card-div-footer, .process-underline {
        background-color: #002C5F;
      }
      .call-info-card-circle{
        background-color: #e82046;
      }
    }
    .call-card-type-3 {
      .call-info-card-icon, .call-info-card-square-point, .call-info-card-div-footer, .process-underline {
        background-color: #79C6C1;
      }
      .call-info-card-circle{
        background-color: #02ad68;
      }
    }
}

.call-info-card-div {
    font-size:18px;
    background-color:white;
    width:100%;
    height:90%;
    margin:10px;
    border-radius:8px;
    box-shadow:6px 6px 10px rgba(0, 0, 0, 0.25);
}

.call-info-card-div-header {
    margin-left:20px;
    margin-top:20px;
    padding: 10px;
    display:flex;
}

.call-info-card-body {
    width: 100%;
    display:flex;
}

.call-info-card-div-footer {
    bottom:16px;
    display:flex;
    height:60px;
    width:100%;
    margin-top: 10px;
    border-radius:0 0 8px 8px;
}

.call-info-card-icon {
    width:32px;
    height:32px;
    border-radius:50px;
}

.call-info-card-square-point {
    height:12px;
    width:12px;
    margin-bottom:20px
}

//.call-info-card-body-left{}

.call-info-card-body-right {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 15px;
}

.card-percentage {
    font-size:50px;
    font-weight:800
    text-align: center;
    width:140px;
}

.card-process-name {
    width:140px;
    text-align:center
}

.process-underline {
    width:140px;
    height:4px;
}

.card-footer-section {
    width:calc(100%/3);
    text-align:center
}

.card-footer-point {
    width:30px;
    height:30px;
    border-radius:15px;
    margin-left:45%;
    background-color:white;
    margin-top:-15px;
    display:flex;
    align-items:center;
    justify-content:center;
}

.card-footer-point div {
    width:15px;
    height:15px;
    border-radius:10px;
}

.footer-section-text {
    margin-top:10%
}

.footer-section-divider {
    width:1px;
    height:100%;
    background-color:white;
}

.call-info-card-heading {
    margin-top:10px;
    margin-left:10px;
}

.callingClose {
  position: absolute;
  bottom: 10px;
  right: 8px;
}

@media (max-width:1530px)  {
    .call-info-card {
        //width: 50%;
        min-width: 350px;
        max-width: 370px;
    }

    .call-info-card-div {
        font-size:15px;
        background-color:white;
        width:90%;
        height:90%;
        margin:10px;
        border-radius:20px;
        box-shadow:6px 6px 10px rgba(0, 0, 0, 0.25);
    }

    .call-info-card-div-header {
        margin-left: 10px;
        margin-top: 15px;
    }

    .call-info-card-div-footer {
        display:flex;
        height:50px;
        margin-top: 8px;
        border-radius:0 0 8px 8px;
    }


    .call-info-card-icon {
        width:32px;
        height:32px;
        border-radius:50px;
    }

    .call-info-card-square-point {
        height:8px;
        width:8px;
        margin-bottom:15px
    }

    .card-percentage {
        font-size:35px;
        font-weight:800
        text-align: center;
        width:115px;
    }

    .card-process-name {
        width:115px;
        text-align:center
    }

    .process-underline {
        width:115px;
        height:4px;
    }

    .card-footer-section {
        width:calc(100%/3);
        text-align:center
    }

    .card-footer-point {
        width:20px;
        height:20px;
        border-radius:15px;
        margin-left:45%;
        background-color:white;
        margin-top:-10px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .card-footer-point div {
        width:10px;
        height:10px;
        border-radius:10px;
    }

    .footer-section-text {
        margin-top:10%
    }

    .footer-section-divider {
        width:1px;
        height:100%;
        background-color:white;
    }

    .call-info-card-heading {
        margin-top:10px;
        margin-left:10px;
        width: 70%;
    }
}
