@charset "UTF-8";

@font-face {
  font-family: "fontastic-icons";
    src:url("/assets/static/fonts/fontastic-icons.eot");
    src:url("/assets/static/fonts/fontastic-icons.eot?#iefix") format("embedded-opentype"),
    url("/assets/static/fonts/fontastic-icons.woff") format("woff"),
    url("/assets/static/fonts/fontastic-icons.ttf") format("truetype"),
    url("/assets/static/fonts/fontastic-icons.svg#fontastic-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "fontastic-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: "\61";
}
.icon-bars:before {
  content: "\62";
}
.icon-sign-out:before {
  content: "\63";
}
.icon-cogs:before {
  content: "\64";
}
.icon-wrench:before {
  content: "\65";
}
.icon-question-circle:before {
  content: "\66";
}
.icon-comment:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-bell:before {
  content: "\69";
}
.icon-sitemap:before {
  content: "\6a";
}
.icon-user:before {
  content: "\6b";
}
.icon-files-o:before {
  content: "\6c";
}
.icon-exchange:before {
  content: "\6d";
}
.icon-upload:before {
  content: "\6e";
}
.icon-download:before {
  content: "\6f";
}
.icon-th-large:before {
  content: "\70";
}
.icon-users:before {
  content: "\71";
}
.icon-bar-chart:before {
  content: "\72";
}
.icon-cog:before {
  content: "\73";
}
.icon-lock:before {
  content: "\74";
}
.icon-milestone:before {
  content: "\72";
}
.icon-flow-branch:before {
  content: "\75";
}
.icon-puzzle-piece:before {
  content: "\76";
}
.icon-users-1:before {
  content: "\77";
}
.icon-building:before {
  content: "\78";
}
.icon-file-text:before {
  content: "\79";
}
.icon-vcard:before {
  content: "\7a";
}
.icon-line-chart:before {
  content: "\41";
}
.icon-settings:before {
  content: "\42";
}
.icon-cube:before {
  content: "\43";
}
.icon-bar-chart:before {
  content: "\44";
}
