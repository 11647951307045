.uploadInputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.uploadInputFile + label {
  font-weight: normal;
  display: inline-block;
  padding:5px
}
.uploadInputFile + label figure {
  border-radius: 50%;
  background-color: var(--button-primary);
  display: flex;
  padding: 5px;
  margin: 0 auto;
  align-items:center;
  justify-content:center;
  height:40px;
  width:40px;
}
.uploadInputFile + label span {
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-align: center;
}
.uploadInputFile + label figure:hover {
  background-color: var(--button-primary-hover);
}
.uploadInputFile + label {
	cursor: pointer; /* "hand" cursor */
}
.uploadInputFile + label * {
	pointer-events: none;
}
