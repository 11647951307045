.semi-info-detail-card {
  min-height: 50px;
  background: #fff;
  border: 1px solid #ededed;

  &:hover {
    box-shadow: 0 3px 5px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  }
}

.semi-info-detail-card-no-hov-effect {
  box-shadow: none !important;
  cursor: auto !important;
}

.semi-info-detail-card-no-hover {
  min-height: 50px;
  background: #fff;
  border: 1px solid #ededed;
}

.semiInfoDetailCard-icon-container {
  display:flex;
  position:absolute;
  bottom:10px;
  right:10px;
  gap:8px;

  .semiInfoDetailCard-icon  {
    width: 45px;
    height: 50px;
    background: #EEEEEE;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
}

.semiInfoDetailCard-icon-container-sm {
  display:flex;
  position:absolute;
  bottom:10px;
  right:8px;
  gap:8px;

  .semiInfoDetailCard-icon  {
    width: 32px;
    height: 32px;
    background: #EEEEEE;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: flex-start !important;
  }
  p {
    display: none;
  }
}

.semiInfo-userDetails-container {
  border-left: 1px solid #e1e1e1;
  width: 150px;
}
