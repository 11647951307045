
// Form

form label {
  //font-size: 1rem !important;
}


textarea
  //min-height: 6.5rem
  resize: none

label,
legend
  display: block
  align-items: baseline
  font-size: 1.2rem
  font-weight: 700

fieldset
  border-width: 0
  padding: 0

input[type='checkbox'],
input[type='radio']
  display: inline

.label-inline
  // font-weight: normal
  // display: inline-block
  input
    margin-right: .5rem

input[role=combobox]
  margin-bottom: 5px !important;

input[role=combobox] + div
  height:150px;
  overflow-y:scroll;

  > div
    padding: 5px;

.select {
  width: 100%;
}

select {
  display: block !important;
  color: #555;
  //text-shadow: 0 0 0 #000;
  line-height: 2.5;
}

.select select {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  padding-right: 2.5em;
  position: relative;
  background: color-initial;
  border: none;
}

.select::after {
  border: 2px solid color-logo-blue;
  border-top-width: 2px;
  border-right-width: 2px;
  border-top-style: solid;
  border-right-style: solid;
  border-top-color: rgb(33, 150, 243);
  border-right-color: rgb(33, 150, 243);
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: .5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: .5em;
  margin-top: -.375em;
  right: 1.125em;
  top: 50%;
  //z-index: 4;
}

.search-box {
  position: relative;
}

.search-box .icon-search {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 18px;
  color: color-logo-blue;
}

.active-option {
  color: color-logo-blue;
}

.required-field {
  color: #ff0000;
}

.font-normal {
  font-weight: normal;
}

.mobile-search {
  position: relative;
  border-radius: 6px;
  border: none !important;
  background: #f6f6f6 !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  &.comment-contrast {
    background-color: #808080 !important;
    color: #000;
    &::placeholder {
      color: #000;
    }
  }
}

.mobile-form {
  .input-outer-class {
    padding: 10px 0;
    border-bottom: 0.1px solid #d1d1d1;
    span {
      color: #2c2d96;
      margin-right: 5px;
      font-size: 13px;
      &.contrast {
        color: #fff;
      }
    }
    input, textarea {
      width: auto;
      border-right: none;
      border-top: none;
      border-bottom: none;
      height: 25px;
      &.contrast {
        color: #fff;
      }
    }
  }
}

input[type="date"], input[type="time"], input[type="week"] {
  width:100%;
  height:33px;
}

input[type="file"] {
  padding: 0.4rem 0.5rem !important;
}

.errorMsg{
	font-size:0.8rem;
	font-weight:600;
	display:none;
	color:red;
	margin-top:0.3rem
}
