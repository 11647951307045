.dynamic-card-shadow:hover {
  //box-shadow: 0 3px 5px rgb(0 0 0 / 25%), 0 2px 2px rgb(0 0 0 / 22%);

}

.caseDetail-btn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 24px;
  border-radius: 5px;
  padding: 4px;
  font-size: 12px;
  cursor:pointer;

}

.caseDetail-btn-sm {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 24px;
  border-radius: 5px;
  padding: 2px;
  font-size: 9px;
  cursor:pointer;
  padding-top: 6px;

}

.caseDetail-header1-label {
  color:#00A3FF;
  font-weight: 400;
  font-size: 12px;

}
.caseDetail-header1-data {
  color: #002C5F !important;
  font-weight: 400;
  font-size: 15px;
}

.process-card-transition-off {
  transition: 0.2s all ease-in;
  height: 40px;

  .process-card-detail {
    transform: scaleY(0);
    transition: 0.2s transform ease-in;
    transform-origin: top;
  }

  .process-card-rotate {
    transform: rotate(270deg);
    transition: 0.2s transform linear;
    margin-bottom: -6px;
  }

  .process-progress-stages {
    width: 240px;
    justify-content: space-between;
    top:60px;
    display:none !important;
  }

  .process-progress-line {
    width: 54px;
    height: 0px;
    border-bottom: 1px solid #545454;
    margin-top: 5px;
    display:none !important;
  }

  .process-progress-disk {
    width: 12px;
    height: 12px;
    border-radius:50%;
    border: 1px solid #545454;
    display:none !important;
  }

  .process-showline {
    display:none;
  }

}

.process-card-transition-on {
  transition: 0.2s all ease-in;
  height: auto;

  .process-card-detail {
    transform: scaleY(1);
    transition: 0.2s transform ease-in;
    transform-origin: top;
  }

  .process-card-rotate {
    transform: rotate(360deg);
    transition: 0.2s transform linear;
    margin-bottom: -6px;
  }

  .process-progress-disk {
    width: 12px;
    height: 12px;
    border-radius:50%;
    border: 1px solid #545454;
  }

  .process-progress-line {
    width: 54px;
    height: 0px;
    border-bottom: 1px solid #545454;
    margin-top: 5px;
  }

  .process-progress-stages {
    width: 240px;
    justify-content: space-between;
    top:16px;
    left:48px;
    display:flex;

  }

}


.process-border-green {
  border-color:#1ED200 !important;
}

.process-bg-green {
  background:#1ED200 !important;
}

.process-btn-active {
  height: 22px;
  padding: 3px 6px;
  background: #5ED700;
  border-radius: 5px;
  color: #fff;
  right:0;
}

.process-btn-complete {
  height: 22px;
  padding: 2px 6px;
  background: white
  border-radius: 5px;
  color: #5ED700;
  border: 1px solid #5ED700;
  right:0;
}

.process-btn-active-sm {
  height: 22px;
  padding: 3px 2px;
  background: #5ED700;
  border-radius: 5px;
  color: #fff;
  right:0;
  font-size: 10px;
  min-width: 40px;
}

.process-btn-complete-sm {
  height: 22px;
  padding: 2px 2px;
  background: white
  border-radius: 5px;
  color: #5ED700;
  border: 1px solid #5ED700;
  right:0;
  font-size: 10px;
  min-width: 40px;
}

.overflow-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/*
  Modification: Add class for case
  By: Komal Wable
  Date: 01/03/2023
*/
.case-items-accordion {
  input[type="checkbox"]+label {
    padding : 0px 0px 0px 34px !important;
  }
}
